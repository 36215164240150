<template>
  <div>
    <v-row class="article-section">
      <v-col cols="12" class="article-col">
        <img v-if="article.imagem" :src="`https://blogs.abare.cloud/assets/${article.imagem.filename_disk}`"
          class="article-img" />
        <div class="font-resize-container">
          Tamanho da fonte:
          <v-btn class="magnify-btn" @click="resizeFont('smaller')">
            <svg-icon type="mdi" :path="mdiMagnifyMinusOutline"></svg-icon>
          </v-btn>
          <v-btn class="magnify-btn" @click="resizeFont('bigger')">
            <svg-icon type="mdi" :path="mdiMagnifyPlusOutline"></svg-icon>
          </v-btn>
        </div>
        <h1 class="article-title">{{ article.titulo }}</h1>
        <span class="text-separator"></span>
        <p class="article-text" v-html="article.texto"></p>
      </v-col>
      <div class="comments-section">
        <v-col cols="12" class="comment-col d-flex" v-for="(comment, i) in article.comentarios" :key="i" elevation="10">
          <v-avatar class="comment-avatar" color="#5190bf" variant="tonal">{{
            comment.nome.substring(0, 1).toUpperCase()
          }}</v-avatar>
          <div class="d-flex flex-column">
            <h4 class="comment-name">{{ comment.nome }}</h4>
            <p class="comment-msg">{{ comment.mensagem }}</p>
            <p class="comment-date">
              {{ comment.date_created }}
            </p>
          </div>
        </v-col>
      </div>
    </v-row>
    <ArticleComment />
  </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiMagnifyMinusOutline, mdiMagnifyPlusOutline } from '@mdi/js';
import ArticleComment from "../components/ArticleComment.vue";

export default {
  metaInfo() {
    return {
      title: this.pageTitle || this.article.titulo,
      meta: [
        {
          name: 'description',
          content: this.articleDescription, // Your dynamically generated description
        },
        {
          name: "keywords",
          content: this.keywords || this.article.titulo,
        }
      ],
    };
  },
  components: {
    SvgIcon, ArticleComment
  },
  data: () => ({
    mdiMagnifyMinusOutline,
    mdiMagnifyPlusOutline,
    article: [],
    articleDescription: null,
    pageTitle: null,
    keywords: null
  }),
  methods: {
    async getArticle() {
      const { data } = await axios.get(
        `https://blogs.abare.cloud/items/pro_clinica_artigos/${this.$route.params.id}?fields=*,imagem.filename_disk,comentarios.*`
      );
      this.article = data.data;
      this.articleDescription = this.article.meta ? this.article.meta.meta : '';
      this.pageTitle = this.article.meta ? this.article.meta.title : null;
      this.keywords = this.article.meta ? this.article.meta.keywords : null;
      console.log(this.articleDescription)
      for (let i = 0; i < this.article.comentarios.length; i++) {
        this.article.comentarios[i].date_created = dayjs(
          this.article.comentarios[i].date_created
        ).format("DD-MM-YYYY HH:mm:ss");
      }
    },

    resizeFont(size) {
      const articleText = document.querySelector(".article-text");
      let currentFontSize = parseFloat(
        window.getComputedStyle(articleText).fontSize
      );
      switch (size) {
        case "smaller":
          currentFontSize -= 1;
          articleText.style.fontSize = currentFontSize + "px";
          break;
        case "bigger":
          currentFontSize += 1;
          articleText.style.fontSize = currentFontSize + "px";
          break;
        default:
          currentFontSize -= 0;
          break;
      }
    },
  },
  computed: {
    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 3;
        case "xl":
          return 4;
        default:
          return 0;
      }
    },
  },

  mounted() {
    this.getArticle();
  },
};
</script>

<style>
.article-section {
  padding: 0 9%;
  background-color: #fafafa;
  background-position: center;
  background-size: cover;
  margin-top: 1em;
  padding-top: 6em !important;
  padding-bottom: 6em !important;
  min-height: 100vh;
}

.article-col {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.article-title {
  color: #5190bf;
  font-family: "Poppins", sans-serif, Sans-serif;
  font-size: clamp(24px, 2vw, 36px);
  font-weight: 500;
  line-height: 1em;
  padding-bottom: 1em;
  text-align: center;
  border-bottom: 3px solid #5190bf;
  border-bottom-style: dotted;
}

.article-img {
  max-width: 100%;
}

.article-text {
  color: #504f4f;
  padding-top: 3em;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
  padding-bottom: 2em;
  margin-bottom: 2em;
  border-bottom: 3px solid #5190bf;
  border-bottom-style: dotted;
  max-width: 100%;
}

.article-text p {
  margin-bottom: 0;
}

.article-text img {
  max-width: 100%;
}

.comments-section {
  width: 80%;
  margin: auto;
}

.comment-col {
  padding: 1em 2% !important;
  background-color: #ffffff88;
  margin-bottom: 1em;
  border-radius: 5px;
}

a {
  color: #1b66a0 !important;
  text-decoration: underline;
}

@media (min-width: 1800px) {
  .article-section {
    padding: 0 14%;
  }

  .comments-section {
    width: 60% !important;
  }

  .comment-col {
    padding: 1em 17% !important;
  }
}

.comment-avatar {
  margin-right: 10px;
}

.comment-name {
  font-family: poppins, sans-serif, sans-serif;
  font-weight: 500;
  font-size: clamp(18px, 1.4vw, 24px);
}

.comment-msg {
  font-family: Roboto, sans-serif;
  font-size: clamp(16px, 1.2vw, 22px);
  color: #424242;
  margin-bottom: 1em;
}

.comment-date {
  font-size: clamp(14px, 1vw, 18px);
  color: #999;
}

.font-resize-container {
  margin: 2em 0;
  width: 100%;
  text-align: right;
}

.magnify-btn {
  border-radius: 0 !important;
  height: 16px !important;
  width: 16px !important;
  margin: 6px;
  box-shadow: unset;
  border: 1px solid #2b292963;
}
</style>
