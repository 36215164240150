<template>
	<v-container fluid>

		<head>
			<meta name="description" :content="$route.meta.description" />
			<h1>{{ $route.meta.title }}</h1>
			<p>{{ $route.meta.description }}</p>
		</head>
		<v-row class="justify-center align-center bgc1 heightMob padMob" style="padding: 0 0px">
			<v-col cols="12" md="6" class="align-center justify-center d-flex">
				<h1 class="title1Mob alinhadoM padLft" style="
						color: #fff;
						font-family: Montserrat, sans-serif;
						font-size: 50px;
						letter-spacing: 2px;
						line-height: 1.2;
						padding-left: 60px;
						padding-top: 70px;
					">
					ESPECIALIDADES MÉDICAS
				</h1>
			</v-col>
			<v-col cols="12" md="6"></v-col>
		</v-row>

		<v-row style="margin: 80px 0px">
			<v-col cols="12" md="6" class="align-center justify-center d-flex">
				<ImgDots class="wdImage" style="background-size: contain; max-width: 350px" name="interativo" :dots="listDots"
					:items="items" :src="require('../assets/corpo.webp')" @click="
						indexDialog = items.findIndex((x) => x.number == item.number);
					dialog2 = true;
					">
				</ImgDots>
			</v-col>
			<v-col cols="12" md="6" class="justify-center d-flex flex-column">
				<h1 style="
						font-family: poppins, sans-serif;
						color: #3b3b3b;
						line-height: 1;
						font-weight: 500;
						font-size: 35px;
						margin-bottom: 40px;
					">
					São mais de <br />
					<span style="font-weight: 800; color: #5190bf">20 ESPECIALIDADES MÉDICAS</span>
					com agendamento rápido
				</h1>
				<v-row>
					<v-col cols="12" md="6" class="d-flex flex-column align-content-start" style="width: 500px">
						<div class="">
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Cardiologista
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Clinico Geral
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Dermatologista
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Endocrinologista
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								" v-if="end.idName !== 'sorocaba'">
								• Fonoaudiólogo
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Gastroenterologista
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Geriatra
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Ginecologista
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Neurologista
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Nutricionista
							</p>
						</div>
					</v-col>
					<v-col cols="12" md="6" class="d-flex flex-column align-content-start">
						<div class="">
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								" v-if="end.idName !== 'sorocaba'">
								• Obstetra
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Oftalmologista
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Ortopedista
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Otorrino
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Pediatra
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Psicólogo
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Psiquiatra
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Proctologista
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Urologista
							</p>
							<p @click="abrirDialogWhats" style="
									color: #0446c9;
									font-family: poppins, sans-serif;
									font-size: 22px;
									margin-bottom: 0px;
									cursor: pointer;
								">
								• Vascular
							</p>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<v-row style="margin-bottom: 80px">
			<v-col cols="12" md="12" class="align-center justify-center d-flex" style="margin-bottom: 80px">
				<div class="align-center justify-center d-flex" style="
						background-color: #0965ab;
						padding: 80px 50px;
						max-width: 900px;
						border-radius: 30px;
					">
					<h1 class="title1Mob alinhadoM" style="
							color: #fff;
							font-family: Montserrat, sans-serif;
							font-size: 50px;
							letter-spacing: 2px;
							line-height: 1.2;
							text-align: center;
							font-weight: 800;
						">
						COMBOS DE CHECK-UP <br />
						<span style="font-size: 30px; font-weight: 500">
							Cuidado completo com sua saúde</span>
					</h1>
				</div>
			</v-col>
			<v-col cols="12" md="4" class="align-center justify-center d-flex">
				<v-img @click="abrirDialogWhats()" contain style="max-width: 300px; cursor: pointer"
					src="../assets/medicinaNovo/maternidade.webp"></v-img>
			</v-col>
			<v-col cols="12" md="4" class="align-center justify-center d-flex">
				<v-img @click="abrirDialogWhats()" contain style="max-width: 300px; cursor: pointer"
					src="../assets/medicinaNovo/nutri.webp"></v-img>
			</v-col>
			<v-col cols="12" md="4" class="align-center justify-center d-flex">
				<v-img @click="abrirDialogWhats()" contain style="max-width: 300px; cursor: pointer"
					src="../assets/medicinaNovo/kids.webp"></v-img>
			</v-col>
		</v-row>

		<v-dialog v-model="dialog2">
			<v-card class="align-center justify-center flexCard d-flex">
				<svg-icon type="mdi" :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
				<v-col cols="12" md="8" class="">
					<h1 class="title1Mob" style="
							color: #4490a3;
							font-family: poppins, sans-serif;
							font-size: 45px;
							letter-spacing: 2px;
							line-height: 1.2;
							word-break: none;
						">
						{{ items[indexDialog].titulo }}
					</h1>
					<p style="
							margin-bottom: 0px !important;
							font-family: poppins, sans-serif;
							color: #000;
							font-size: 18px;
						" v-html="items[indexDialog].texto"></p>
					<v-btn class="botaoMobile titleMobImpla" @click="() => {
						this.$root.$emit('show-whats');
					}
						" style="
							border: 2px solid #4490a3;
							color: #4490a3;
							background-color: transparent;
							font-family: poppins, sans-serif;
							font-weight: 400;
							font-size: 20px;
							margin-top: 30px;
							box-shadow: none;
							border-radius: 50px;
						">Faça um agendamento!</v-btn>
				</v-col>
				<v-col cols="12" md="4" class="align-center justify-center d-flex">
					<img :src="items[indexDialog].imagem" width="250" />
				</v-col>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import ImgDots from '../components/ImgDots.vue';
import { mdiClose } from '@mdi/js';
import SvgIcon from '@jamescoyle/vue-icon';

export default {
	metaInfo: {
		title: 'Medicina',
		meta: [
			{
				name: 'Medicina',
				content:
					'São mais de 20 especialidades médicas com agendamento rápido- Agende agora sua Consulta',
			},
			// outras meta tags
		],
	},
	components: {
		SvgIcon,
		ImgDots,
	},
	name: 'medicina',
	data: () => ({
		mdiClose,
		indexDialog: 0,
		loading: false,
		selectedItem: null,

		dialog2: false,
		items2: ['São José dos Campos', 'Sorocaba'],
		listDots2: [
			{
				x: 5.41476,
				y: 86.5511,
			},
			{
				x: 23.173,
				y: 84.3161,
			},
			{
				x: 7.66921,
				y: 81.9036,
			},
			{
				x: 32.66921,
				y: 83.9036,
			},
			{
				x: 55.5064,
				y: 83.0811,
			},
			{
				x: 66.7201,
				y: 83.6686,
			},
			{
				x: 90.95,
				y: 86.1,
			},
			{
				x: 84.9873,
				y: 79.436,
			},
			{
				x: 40.7,
				y: 76.3208,
			},
		],
		listDots: [
			//NEURO
			{
				x: 44.55168374816984,
				y: 22.00003338735818,
			},

			//DERMA
			{
				x: 16.68521229868228,
				y: 38.96110210696921,
			},

			//OUVIDO
			{
				x: 43.58418740849195,
				y: 32.33711507293354,
			},

			//CORAÇÃO//
			{
				x: 43.95461200585652,
				y: 0.00000000000944,
			},

			//GASTRO
			{
				x: 57.95461200585652,
				y: 6.64019448946516,
			},

			//UroGine
			{
				x: 43.95461200585652,
				y: 46.6677471636953,
			},

			//vasco
			{
				x: 70.00878477306003,
				y: 31.12641815235008,
			},

			//Ortopedista
			{
				x: 61.96910688140557,
				y: 70.3711507293355,
			},

			//olho
			{
				x: 38.612005856515374,
				y: 4.31604538087521,
			},
		],
		items: [
			{
				titulo: 'Cardiologista',
				number: '01',
				texto:
					'Cardiologia é a especialidade médica que se ocupa do diagnóstico e tratamento das doenças que acometem o coração e também do sistema circulatório. Avaliam pacientes com pressão alta, insuficiência cardíaca, colesterol alto e outras doenças relacionadas ao coração e à circulação sanguínea. Além disso, atua na prevenção indicando hábitos saudáveis para a saúde do coração.',
				imagem: require('../assets/cardiologia.webp'),
			},
			// {
			//     titulo: "Clínico Geral",
			//     number: "02",
			//     texto: "O Clínico Geral é o médico que trata de pacientes adultos, acompanhando e tratando diferentes doenças não cirúrgicas como hipertensão e diabetes. Realiza ações preventivas individuais, orientando medidas saudáveis e realizando exames preventivos (check- up).",
			//     imagem: require("../assets/clinica1.webp"),
			// },
			{
				titulo: 'Dermatologista',
				number: '03',
				texto:
					'Dermatologista é a(o) especialista médica(o) que se ocupa do diagnóstico e tratamento clínico-cirúrgico das enfermidades relacionados à pele e aos anexos cutâneos (unhas, pelos, mucosas, cabelos).',
				imagem: require('../assets/dermato.webp'),
			},
			{
				titulo: 'Gastroenterologista',
				number: '04',
				texto:
					'O Medico Gastroenterologista, faz o diagnóstico do funcionamento do aparelho digestivo. Importante ressaltar que para realizar a consulta gastro, o médico deve ser regularmente credenciado pela Sociedade Brasileira de Medicina, e ter experiência como residente na área específica.',
				imagem: require('../assets/Gastroent.webp'),
			},
			// {
			//     titulo: "Geriatra",
			//     number: "05",
			//     texto: "O médico geriatra é o especialista que abrange desde a promoção de um envelhecer saudável até o tratamento e a reabilitação do idoso.",
			//     imagem: require("../assets/geri.webp"),
			// },
			// {
			//     titulo: "Ginecologista",
			//     number: "06",
			//     texto: "Especialidade que cuida da saúde da mulher, lidando com o diagnóstico e tratamento de doenças do sistema reprodutor feminino (vagina, útero e ovários).",
			//     imagem: require("../assets/gineco.webp"),
			// },
			{
				titulo: 'Neurologista',
				number: '07',
				texto:
					'É a especialidade médica que se dedica ao diagnóstico e tratamento de doenças que afetam o sistema nervoso. ',
				imagem: require('../assets/neuro1.webp'),
			},
			{
				titulo: 'Otorrinolaringologista',
				number: '08',
				texto:
					'O Otorrinolaringologista, muitas vezes chamado de “otorrino”, é o médico que faz o diagnóstico e tratamento dos problemas de ouvidos, nariz e garganta. As principais doenças tratadas por esse especialista são: rinites, sinusites, otites, faringite.',
				imagem: require('../assets/otorrino.webp'),
			},
			// {
			//     titulo: "Pediatria",
			//     number: "09",
			//     texto: "Pediatria é a especialidade dedicada à assistência à criança e ao adolescente, nos seus diversos aspectos, sejam eles preventivos ou curativos. Crianças e adolescentes, se estiverem com doenças, devem dirigir-se ao seu pediatra.",
			//     imagem: require("../assets/pediatria.webp"),
			// },
			{
				titulo: 'Urologista e Ginecologista',
				number: '10',
				texto:
					'É a especialidade que realiza o diagnóstico e cirurgias de problemas relacionados ao trato urinário de homens e mulheres e do sistema reprodutor masculino (testículos, epidídimos, ducto deferente, vesículas seminais, próstata e pênis). <br><br> Já a ginecologia é a especialidade que cuida da saúde da mulher, lidando com o diagnóstico e tratamento de doenças do sistema reprodutor feminino (vagina, útero e ovários).',
				imagem: require('../assets/urogine.webp'),
			},
			{
				titulo: 'Vascular',
				number: '11',
				texto:
					'O Médico Cirurgião Vascular é responsável por cuidar dos vasos linfáticos, vasos sanguíneos (artéria e veias). Este profissional também é apto a realizar cirurgias quando necessário para tratar as enfermidade que atingem essas partes do corpo.',
				imagem: require('../assets/vascular.webp'),
			},
			{
				titulo: 'Ortopedista',
				number: '12',
				texto:
					'A especialidade de ortopedia e traumatologia ou melhor Medico Ortopedista é a área da medicina que trata as deformidades, lesões e traumas dos ossos, ligamentos, músculos e articulações, ou seja, todo aparelho locomotor.',
				imagem: require('../assets/ortopedista.webp'),
			},
			{
				titulo: 'Oftamologista',
				number: '13',
				texto:
					'A oftalmologia é uma especialidade da medicina que estuda e trata as doenças relacionadas ao olho, à refração e aos olhos e seus anexos. O médico oftalmologista realiza cirurgias, prescreve tratamentos e correções para os distúrbios de visão.',
				imagem: require('../assets/oftalmo.webp'),
			},
		],
	}),
	computed: {
		intBreakPoint() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 0;
				case 'sm':
					return 1;
				case 'md':
					return 2;
				case 'lg':
					return 3;
				case 'xl':
					return 4;
				default:
					return 0;
			}
		},
		end() {
			return this.$store.getters.unidade;
		},
	},
	methods: {
		abrirDialogWhats() {
			this.$root.$emit('show-whats');
		},

		goto(refName) {
			var element = this.$refs[refName];
			var top = element.offsetTop;
			window.scrollTo(0, top);
		},
		abrir(nome) {
			var card = this.$refs[nome];
			var abrir = card.offsetTop;
			window.scrollTo(0, abrir);
		},
	},
	mounted() {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	},
};
</script>

<style scoped>
@media (min-device-width: 360px) and (max-device-width: 640px) {
	.padMob {
		padding: 10px 10px !important;
	}

	.title1Mob {
		font-size: 30px !important;
	}

	.titleMobImpla {
		font-size: 22px !important;
	}

	.flexCard {
		flex-direction: column !important;
	}

	.wdImage {
		max-width: 300px !important;
	}

	.pdlfBtn {
		padding-left: 90px !important;
	}

	.botaoMobile {
		font-size: 20px !important;
		margin-left: 0px !important;
		padding: 10px !important;
	}

	.bgc1 {
		background-image: url('../assets/medicinaMobile.webp') !important;
		background-position: start !important;
		height: 50vh !important;
	}

	.title1Mob {
		font-size: 25px !important;
	}

	.padLft {
		padding-left: 0px !important;
	}

	.titleTitulo {
		font-size: 35px !important;
	}
}

.bgc1 {
	background-image: url('../assets/medicinaNovo/topo.webp');
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	background-position: center;
	height: 80vh;
}

.btn {
	position: absolute;
	top: 0;
	left: 0;
	padding: 0.5em;
	background-color: aqua;
}

.fundao {
	background-image: url('../assets/fundaoBranco.webp');
	background-size: contain;
	background-repeat: repeat;
}

.titleStyle {
	font-family: poppins, sans-serif;
	font-weight: 500;
	font-size: 45px;
	border: 3px solid #f7de85;
	border-radius: 50px;
	padding: 10px 20px;
}

.numbers {
	padding: 2px 5px;
	background-color: #f7de84;
	border-radius: 90px;
	min-width: 35px;
	text-align: center;
	font-family: poppins, sans-serif;
	font-weight: bold;
	color: #00502b;
	margin-bottom: 0px !important;
}

.noneDesktop {
	display: none !important;
}

.disableDesktop {
	display: none;
}

@media (min-device-width: 360px) and (max-device-width: 1024px) {
	.padMobile {
		padding: 40px 10px !important;
	}

	.sizeMobile {
		font-size: 23px !important;
	}

	.disableMobile {
		display: none;
	}

	.disableDesktop {
		display: initial !important;
	}

	.downloadButton {
		display: inherit !important;
		background-color: #d50243e8 !important;
		color: #fff !important;
		font-family: poppins, sans-serif !important;
		font-weight: 600 !important;
		padding: 25px 50px !important;
		border-radius: 40px !important;
		margin-top: 35px !important;
	}

	.noneDesktop {
		display: inherit !important;
	}

	.mobile {
		display: none !important;
	}

	.responsiveMobile {
		/* background-image: url("../assets/cardapioMob.webp"); */
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
}

.downloadButton {
	display: none;
}

.dot:hover {
	cursor: pointer;
}

.formatTour {
	background-color: #fff;
	border-radius: 0px 20px 20px 20px;
	padding: 20px;
	box-shadow: 0px 3px 6px 1px rgb(0 0 0 / 47%);
	border: 2px solid #7e0909;
}
</style>
