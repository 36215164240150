<template>
  <v-container fluid>
    <v-row style="height:4vh; background-color:#144D79">
      <v-col cols="12" md="8" v-if="intBreakPoint > 2"></v-col>
      <v-col cols="12" md="4" class="align-center justify-center d-flex align-self-start align-content-start"
        style="padding:0px">
        <a :href="end.whats" target="_blank" style="text-decoration: none; height:5px">
          <svg-icon type="mdi" :path="mdiWhatsapp" style="margin-left:5px; margin-bottom:0px; padding-bottom:0px; color:#fff; font-size:20px"></svg-icon>
        </a>
        <a :href="end.insta" target="_blank" style="text-decoration: none; height:5px">
          <svg-icon type="mdi" :path="mdiInstagram" style="margin-left:5px; margin-bottom:0px; padding-bottom:0px; color:#fff; font-size:20px"></svg-icon>
        </a>
        <a :href="end.face" target="_blank" style="text-decoration: none; height:5px">
          <svg-icon type="mdi" :path="mdiFacebook" style="margin-left:5px; margin-bottom:0px; padding-bottom:0px; color:#fff; font-size:20px"></svg-icon>
        </a>
      </v-col>
    </v-row>

    <v-row style="padding: 0px 60px !important; height: 100px;
    background: linear-gradient(177deg, rgba(27,108,171,1) 5%, rgba(21,75,117,1) 67%);">
      <v-col cols="12" md="12" class="align-center justify-center d-flex" style="padding-bottom:0px !important">
        <router-link to="/">
          <v-img alt="Logo Pró Clínica" class="shrink mobileSize sombra" contain :src="end.logoBranco" max-width="220"
            style="" />
        </router-link>
      </v-col>
    </v-row>

    <v-row class="fundoFluxo">
      <v-col cols="12" md="6" class="align-center justify-center d-flex flex-column">
        <h1 class="fontTitulo"
          style="color:#707070; font-weight:800; font-size:40px; line-height:1; font-family: Montserrat, sans-serif; margin-bottom:20px">
          PREÇO ACESSÍVEL <br> ATENDIMENTO DE <br>
          <span class="fontTitulo" style="color:#5190BF; font-size: 62px">QUALIDADE</span>
        </h1>
      </v-col>
      <v-col cols="12" md="6" class="align-center justify-center d-flex flex-column align-self-end"
        style="padding-bottom:0px">
        <img class="wdImgMb" contain style="max-width:800px" src="../assets/novalp/fototopo.webp" />
      </v-col>
    </v-row>

    <v-row class="tracante marginMobile"
      style="padding:50px 0px; border-radius: 50px 50px 0px 0px; margin:-50px 50px 0px 50px">
      <v-col cols="12" md="9" class="align-center justify-center d-flex flex-column" style="">
        <h1 style="color:#3B3B3B; font-weight:500; font-size:35px; line-height:1; font-family: Montserrat, sans-serif; ">
          Cuidado completo com a sua saúde por um <br>
          <span style="color:#5190bf; font-weight:800">preço que cabe no seu bolso</span>
        </h1>
      </v-col>
      <v-col cols="12" md="12" class="align-center justify-center d-flex flexmb">
        <v-card class="mgCard"
          style="max-height:300px; height:300px; max-width:220px; width:220px; background-color: #E0EDF6; border-radius:20px; padding:15px; margin:0px 10px">
          <v-img contain style="max-width:100px; margin-bottom:-80px; margin-top:-80px" src="../assets/20esp.webp"></v-img>
          <p style="color:#3B3B3B; font-family:Montserrat, sans-serif; font-size:15px;">
            São <span style="color:#0965AB">+ de 20</span> especialidades médicas
          </p>
          <p style="color:#3B3B3B; font-family:Montserrat, sans-serif; font-size:15px; margin-top:40px">
            A partir de: <br>
            <span style="font-weight:800; font-size:35px">R$70</span>
          </p>
        </v-card>

        <v-card class="mgCard"
          style="max-height:300px; height:300px; max-width:220px; width:220px; background-color: #E0EDF6; border-radius:20px; padding:15px">
          <v-img contain style="max-width:100px;  margin-bottom:-80px; margin-top:-80px"
            src="../assets/50exames.webp"></v-img>
          <p style="color:#3B3B3B; font-family:Montserrat, sans-serif; font-size:15px;">
            <span style="color:#0965AB">Mais de 50</span> Exames Laboratoriais e imagem
          </p>
          <!-- <p style="color:#3B3B3B; font-family:Montserrat, sans-serif; font-size:15px; margin-top:40px">
          A partir de: <br> 
          <span style="font-weight:800; font-size:35px">R$70</span>
          </p> -->
        </v-card>

        <v-card class="mgCard"
          style="max-height:300px; height:300px; max-width:220px; width:220px; background-color: #E0EDF6; border-radius:20px; padding:15px; margin:0px 10px">
          <v-img contain style="max-width:100px;margin-bottom:-80px; margin-top:-80px"
            src="../assets/dentiscard.webp"></v-img>
          <p style="color:#3B3B3B; font-family:Montserrat, sans-serif; font-size:15px; ">
            <span style="color:#0965AB">Dentista</span><br>
            <span style="color:#0965AB">+ 12</span> procedimentos
          </p>
          <p style="color:#3B3B3B; font-family:Montserrat, sans-serif; font-size:15px; font-weight:800; margin-top:40px">
            Avaliação <br>
            <span style="font-weight:800; font-size:35px">Grátis</span>
          </p>
        </v-card>

        <v-card class="mgCard"
          style="max-height:300px; height:300px; max-width:220px; width:220px; background-color: #E0EDF6; border-radius:20px; padding:15px">
          <v-img contain style="max-width:100px;margin-bottom:-80px; margin-top:-80px"
            src="../assets/checkup.webp"></v-img>
          <p style="color:#3B3B3B; font-family:Montserrat, sans-serif; font-size:15px;">
            <span style="color:#0965AB">Check-ups</span> <br>
            Avaliação médica de rotina + exames
          </p>
          <p style="color:#3B3B3B; font-family:Montserrat, sans-serif; font-size:15px;">
            A partir de: <br>
            <span style="font-weight:800; font-size:35px">R$80</span>
          </p>
        </v-card>

        <v-card class="bgCard mgCard"
          style="max-height:300px; height:300px; max-width:220px; width:220px; border-radius:20px; padding:15px; margin:0px 10px; z-index:9">
          <v-img contain style="max-width:100px; margin-bottom:-80px; margin-top:-80px"
            src="../assets/calendario.webp"></v-img>
          <p style="color:#fff; font-family:Montserrat, sans-serif; font-size:15px; font-weight:8000; margin-bottom:0px">
            Agendar Consulta
          </p>
          <v-btn class="" text @click="abrirDialogWhats()" style="color:#fff; font-family:Montserrat, sans-serif; font-size:15px; text-transform: none;
            padding: 0px 0px ">
            Clique aqui!<br>
          </v-btn>
        </v-card>
      </v-col>

    </v-row>

    <v-row style="padding:0px 60px" class="fundoFluxo">
      <v-col cols="12" md="6" class="align-center justify-center d-flex flex-column">
        <div>
          <h1 class="padTexto textoClinica" style="
              color: #5190BF;
              font-size: 45px;
              font-weight: 800;
              font-family: poppins, sans-serif;
              line-height: 1;
              margin-bottom:20px;
            ">
            CLÍNICA MÉDICA E ODONTOLÓGICA, <br><span style="color:#3B3B3B; font-weight:500"> completa pra você! </span>
          </h1>

          <p style="font-family: poppins, sans-serif; color:#3B3B3B; font-size:18px">· Preço acessível</p>
          <p style="font-family: poppins, sans-serif; color:#3B3B3B; font-size:18px">· Sem Mensalidade</p>
          <p style="font-family: poppins, sans-serif; color:#3B3B3B; font-size:18px">· Estrutura ampla e moderna</p>
          <p style="font-family: poppins, sans-serif; color:#3B3B3B; font-size:18px">· Médicos e Dentistas especialsitas</p>
          <p style="font-family: poppins, sans-serif; color:#3B3B3B; font-size:18px">· Tudo em um só lugar - Consultas e Exames</p>
          <p style="font-family: poppins, sans-serif; color:#3B3B3B; font-size:18px">· Pacotes Check-ups</p>
          <v-btn class="botaoSite botaoMobile" @click="abrirDialogWhats()" style="font-size:18px">
            QUERO AGENDAR <br v-if="intBreakPoint < 2"> MINHA CONSULTA
          </v-btn>
        </div>

      </v-col>
      <v-col cols="12" md="6" class="align-center justify-center d-flex flex-column ">
        <video autoplay muted controls contain style="max-height:550px;" :src="end.video" />
      </v-col>
    </v-row>

    <v-row style="margin:80px 0px">
      <v-col cols="12" md="12" class="align-center justify-center d-flex">
        <h1 style="color:#5190BF; font-weight:800; font-size:35px; line-height:1; font-family: Montserrat, sans-serif">
          NOSSAS ESPECIALIDADES
        </h1>
      </v-col>
      <v-col cols="12" md="12" class="align-center justify-center d-flex flexCard">
        <img class="marginImg" @click="(odontoPedi = true)" contain
          style="max-width:200px; margin-right: 10px; cursor:pointer" src="../assets/dentist/odontopediatria.webp" />

        <img class="marginImg" @click="(ortondo = true)" contain style="max-width:200px; cursor:pointer"
          src="../assets/dentist/ortodontista.webp" />

        <img class="marginImg" @click="(implanto = true)" contain style="max-width:200px; margin: 0px 10px; cursor:pointer"
          src="../assets/dentist/implanto.webp" />

        <img class="marginImg" @click="(endo = true)" contain style="max-width:200px; cursor:pointer"
          src="../assets/dentist/endodontista.webp" />

        <img class="marginImg" @click="(clinico = true)" contain
          style="max-width:200px; margin-left: 10px; cursor:pointer" src="../assets/dentist/clinico.webp" />

      </v-col>
      <v-col cols="12" md="12" class="align-center justify-center d-flex" style="margin-top:30px">
        <v-btn class="botaoSite botaoMobile" @click="abrirDialogWhats()" style="padding:30px 10px;">
          AGENDE AGORA <br v-if="intBreakPoint < 2"> SUA CONSULTA
        </v-btn>
      </v-col>
    </v-row>

    <v-row style="padding:0px 90px; margin-bottom:80px" class="fundoLocalizacao padMobile">
      <v-col cols="12" md="6" class="align-center justify-center d-flex flex-column" style="z-index:99">
        <v-carousel style="max-width:500px;" hide-delimiters>
          <v-carousel-item v-for="(item, i) in end.carrossel" :key="i">
            <v-img contain style="max-width:500px" :src="item"></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="12" md="6" class="align-center justify-center d-flex flex-column">
        <h1
          style="color:#5190BF; font-weight:800; font-size:35px; line-height:1; font-family: Montserrat, sans-serif; padding-bottom:30px">
          ESTAMOS LOCALIZADOS PERTO DE VOCÊ!
        </h1>
        <p style="font-family: poppins, sans-serif; color:#3B3B3B; font-size:20px">
          Na {{ end.texto }}</p>
      </v-col>
      <img v-if="intBreakPoint > 2" contain style="
        min-width:500px;
        position: absolute;
        bottom:10%;
        right:30%
        " src="../assets/novalp/pontilhado.webp" />
    </v-row>

    <v-row class="bgcFooter" style="padding: 50px 0px;">
      <v-col cols="12" md="4" class="align-center justify-center d-flex flex-column padLeftMbFooter"
        style="padding-left:50px">
        <div class="d-flex">
          <v-img contain style="max-width:220px; margin-right: 20px;" src="../assets/logoBranco1.webp"></v-img>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="align-center justify-center d-flex flex-column">
        <a :href="end.telefone">
          <p style="color:#fff; font-family: poppins, sans-serif; font-size:15px;  font-weight: 500;">
            <svg-icon size="30" type="mdi" :path="mdiPhone" style="color:#fff; padding-right:5px; margin-bottom: -10px"></svg-icon>
            (12) 3042-1832</p>
        </a>
        <v-row style="">
          <v-col cols="12" md="12" class="align-center justify-center d-flex pdBtFt" style="">
            <a :href="end.whats" target="_blank" style="text-decoration: none; height:5px">
              <div class="align-center justify-center d-flex"
                style="border:1px solid #fff; border-radius:50%; background-color: transparent; padding:10px; margin:10px">
                <svg-icon type="mdi" :path="mdiWhatsapp" style="margin-bottom:0px; padding-bottom:0px; color:#fff; font-size:20px"></svg-icon>
              </div>
            </a>
            <a :href="end.insta" target="_blank" style="text-decoration: none; height:5px">
              <div class="align-center justify-center d-flex"
                style="border:1px solid #fff; border-radius:50%; background-color: transparent; padding:10px; margin:10px">
                <svg-icon type="mdi" :path="mdiInstagram" style="margin-bottom:0px; padding-bottom:0px; color:#fff; font-size:20px"></svg-icon>
              </div>
            </a>
            <a :href="end.face" target="_blank" style="text-decoration: none; height:5px">
              <div class="align-center justify-center d-flex"
                style="border:1px solid #fff; border-radius:50%; background-color: transparent; padding:10px; margin:10px">
                <svg-icon type="mdi" :path="mdiFacebook" style="margin-bottom:0px; padding-bottom:0px; color:#fff; font-size:20px"></svg-icon>
              </div>
            </a>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4" class=" justify-center d-flex flex-column">
        <p style="color:#fff; font-family: poppins, sans-serif; font-size:12px; font-weight:800; margin-bottom:0px">Endereço:</p>
        <a :href="end.goggle" target="_blank" style="text-decoration: none">
          <p style="color:#fff; font-family: poppins, sans-serif; font-size:12px">{{ end.texto }}</p>
        </a>
      </v-col>
    </v-row>




    <v-dialog v-model="clinico">
      <v-card class="align-center justify-center d-flex flexCard">
        <button @click="clinico = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Clínico Geral
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            Especialidade em que o dentista realiza o diagnóstico e tratamento
            relacionados à saúde bucal. Este profissional atua desenvolvendo
            trabalhos de prevenção como limpeza e orientação a saúde bucal,
            além de restaurações.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>

        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/odonto.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ortondo">
      <v-card class="align-center justify-center d-flex flexCard">
        <button @click="ortondo = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Ortodontista
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            É a especialidade da Odontologia relacionada ao estudo, prevenção
            e tratamento dos problemas de crescimento, desenvolvimento e
            amadurecimento da face, dos arcos dentários e da oclusão, ou seja,
            disfunções dento-faciais. O tratamento é feito com aparelhos
            ortodônticos e ortopédicos que permitem alinhar e nivelar os
            dentes e promover crescimento ósseo para corrigir deficiências
            dento-faciais.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/ortondo.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="implanto">
      <v-card class="align-center justify-center d-flex flexCard">
        <button @click="implanto = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="titleMobImpla" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Implantodontista
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            O implante dentário é um procedimento realizado em regiões
            edentulas (ausência de dente), consiste na fixação de um pino de
            titânio na região para servir de apoio na colocação de uma prótese
            fixa.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/implant.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="periodo">
      <v-card class="align-center justify-center d-flex flexCard">
        <button @click="periodo = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Periodontista
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            Periodontia é a especialidade odontológica que trata dos tecidos
            do periodonto, ou seja, aqueles que dão sustentação para os
            dentes. Seus procedimentos objetivam prevenir, diagnosticar e
            tratar problemas e doenças que atingem a gengiva, o ligamento
            periodontal e o osso alveolar. O especialista dessa área é o
            periodontista.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/periodontista.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="endo">
      <v-card class="align-center justify-center d-flex flexCard">
        <button @click="endo = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Endodontista
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            As áreas de competência do endodontista são: tratamentos conservadores da vitalidade pulpar;
            tratamento cirúrgico nos tecidos e na cavidade pulpar; procedimentos endodônticos cirúrgicos;
            tratamento de dentes permanentes jovens com rizogênese incompleta;
            tratamento dos traumatismos dentários e recuperação da cor dental.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/periodontista.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="odontoPedi">
      <v-card class="align-center justify-center d-flex flexCard">
        <button @click="odontoPedi = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Odontopediatra
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            A principal função do odontopediatra é cuidar da saúde bucal do bebê, da criança e do adolescente.
            O odontopediatra não irá somente realizar possíveis tratamentos que venham a ser necessários,
            mas principalmente trabalhará na educação para uma boa saúde bucal.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/periodontista.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mdiWhatsapp, mdiFacebook, mdiInstagram, mdiClose, mdiPhone } from '@mdi/js';
import SvgIcon from '@jamescoyle/vue-icon';

export default {
  components: {
    SvgIcon
  },
  data: () => ({
    mdiPhone,
    mdiClose,
    mdiFacebook,
    mdiInstagram,
    mdiWhatsapp,
    clinico: false,
    odontoPedi: false,
    endo: false,
    ortondo: false,
    implanto: false,
    periodo: false,
    carrossada: [],
    contato: {
      nome: "",
      email: "",
      telefone: "",
      especialidade: "",
      unidade: "",
    },
    text: "Seu contato foi enviado! Obrigado por sua mensagem, responderemos em breve.",
    timeout: 2000,
    items2: ["São José dos Campos", "Sorocaba"],
  }),
  methods: {
    abrirDialogWhats() {
      this.$root.$emit("show-whats")
    },
  },

  computed: {
    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 3;
        case "xl":
          return 4;
        default:
          return 0;
      }
    },

    end() {
      return this.$store.getters.unidade;
    },
  },
};
</script>

<style scoped>
@media (min-device-width: 340px) and (max-device-width: 820px) {
  .mgRowVideo {
    padding: 20px 0px !important;
  }

  .pdBtFt {
    padding-bottom: 56px !important;
  }

  .padMobile {
    padding: 0px 0px !important;
  }

  .marginMobile {
    margin: 0px 0px 0px 0px !important
  }

  .fontTitulo {
    font-size: 30px !important;
  }

  .flexCard {
    flex-direction: column !important;
  }

  .padLeftMbFooter {
    padding-left: 10px !important
  }

  .mgRow {
    margin: -22px !important;
  }

  .marginImg {
    margin: 5px 0px !important;
  }

  .wdInfo {
    max-width: 354px !important
  }

  .pdlfBtn {
    padding-left: 90px !important;
  }

  .flexmb {
    flex-direction: column !important;
  }

  .justifyContentCenter {
    justify-content: center !important;
  }

  .ultimoCard {
    margin-right: 10px !important;
    margin-top: 5px !important;
  }

  .mgCard {
    margin: 5px 0px !important;
    /* margin-right:10px !important */
  }

  .botaoMobile {
    font-size: 20px !important;
    margin-left: 0px !important;
    padding: 30px !important;
  }

  .wdGifMobile {
    max-width: 300px !important;
  }

  .wdImgMb {
    max-width: 356px !important;
  }

  .heightMobile {
    height: 800px !important;
  }

  .pdTopCol {
    padding-top: 0px !important;
  }

  .mgLeftCard {
    margin-left: 0px !important;
  }

  .positionMobile {
    position: relative !important;
    right: 0 !important;
    top: initial !important;
    padding: 50px 30px !important;
    width: 360px !important;
  }

  .textoTitulo {
    font-size: 40px !important;
    padding-top: 60px !important;
  }

  .padTexto {
    padding-left: 10px !important;
  }

  .textoCorpo {
    font-size: 25px !important;
    padding: 30px 30px 0px 30px !important;
  }

  .flexMobile {
    height: 40vh !important;
    /* flex-direction: column !important; */
  }

  .especialidade {
    height: 100% !important;
  }

  .widthCard {
    max-width: auto !important;
  }

  .wdEsp {
    width: 85vw !important;
  }

  .textoClinica {
    font-size: 35px !important
  }

  .padCol {
    padding: 0px !important;
  }

  .padImg1 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .padImg2 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .wdImgMobile {
    min-width: 350px !important;
  }
}

.mask1 {
  -webkit-mask-image: url("../assets/mascra.webp");
  mask-image: url("../assets/mascra.webp");
  -webkit-mask-size: 100% !important;
  mask-size: 100% !important;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.bgcFooter {
  background: linear-gradient(177deg, rgba(22, 116, 183, 1) 5%, rgba(6, 55, 91, 1) 78%);
}

.bgEspeciali {
  background-image: url("../assets/corredor.webp") !important;
  height: auto;
  background-attachment: inherit !important;
  background-size: cover !important;
  background-position: center;
}

.bgVideo {
  background-image: url("../assets/fundovideo.webp") !important;
  height: auto;
  background-attachment: inherit !important;
  background-size: cover !important;
  background-position: center;
}

.botaoSite {
  background-color: #0965AB !important;
  color: #fff;
  border-radius: 30px;
  font-size: 27px;
  font-weight: 800;
  font-family: Montserrat, sans-serif;
  height: 50px
}


.bgFachadaSjc {
  background-image: url("../assets/fachada.webp") !important;
  height: 50vh;
  background-attachment: fixed !important;
  background-size: cover !important;
  background-position: center;
}

.bgFachadaSorocaba {
  background-image: url("../assets/fachadaSorocaba.webp");
  height: 50vh;
  background-attachment: fixed !important;
  background-size: cover !important;
  background-position: center;
}

.rowDoto {
  background-image: url("../assets/rowdotor.webp");
  height: auto;
  background-attachment: inherit !important;
  background-size: cover !important;
  background-position: center;
}

.fundoLocalizacao {
  background-image: url("../assets/novalp/fundo.webp");
  height: auto;
  background-attachment: inherit !important;
  background-size: cover !important;
  background-position: center;
}

.fundoFluxo {
  background-image: url("../assets/fundofluxo.webp");
  height: auto;
  background-attachment: inherit !important;
  background-size: cover !important;
  background-position: center;
}

.bgForm[data-v-5c26cd26] {
  background-image: url("../assets/agendamento.webp") !important;
  height: 100% !important;
  background-attachment: inherit !important;
  background-size: cover !important;
  opacity: 85% !important;
}

.bgForm {
  background-image: url("../assets/agendamento.webp") !important;
  height: 100% !important;
  background-attachment: inherit !important;
  background-size: cover !important;
  opacity: 0.85 !important;
}

/* .tracante{
    background-image: url("../assets/novalp/tracante.webp") !important;
    background-position: 73% 25%;
    background-size: 18% 18%;

  } */

.bgCard {
  background: linear-gradient(177deg, rgba(27, 108, 171, 1) 5%, rgba(21, 75, 117, 1) 67%);
}
</style>
  