<template>
  <v-container fluid>
    <v-row
      class="justify-center align-center bgc1 heightMob"
      style="padding: 0 0px"
    >
      <v-col
        cols="12"
        md="12"
        style="margin: 0px !important; padding: 0px !important"
      >
        <video-background
          class="padVideo heightMobile"
          :src="'https://abaremarketing.com/media/homeproclinica.mp4'"
          style="height: 100vh"
        >
          <v-row
            justify="center"
            align="center"
            style="height: 100%; padding: 0 30px; margin-bottom: 0"
            class="mobileVideo alturaMob"
          >
            <v-col cols="12" md="6" class="d-flex justify-center flex-column">
              <h1
                class="title1Mob"
                style="
                  color: #fff;
                  font-family: poppins, sans-serif;
                  font-size: 70px;
                  letter-spacing: 2px;
                  line-height: 1;
                "
              >
                preços acessíveis e <br />
                benefícios infinitos
              </h1>
              <h2
                class="title2Mob"
                style="
                  color: #fff;
                  font-family: poppins, sans-serif thin;
                  font-size: 40px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                "
              >
                Clínica Médica e Odontológica <br />
                popular em {{ end.cidade }}
              </h2>
            </v-col>
            <a
              :href="end.whats"
              class="align-center justify-center d-flex"
              target="_blank"
            >
              <v-btn
                @click="abrirObrigado()"
                v-if="intBreakPoint < 3"
                style="
                  padding: 20px;
                  background-color: #3eaf05;
                  color: #fff;
                  font-family: poppins, sans-serif;
                  font-size: 25px;
                  letter-spacing: 2px;
                  margin: 20px 0;
                  border-radius: 15px;
                "
              >
                AGENDE AGORA!
                <svg-icon
                  type="mdi"
                  :path="mdiWhatsapp"
                  color="white"
                  style="padding-left: 10px"
                ></svg-icon>
              </v-btn>
            </a>
            <v-col
              cols="12"
              md="6"
              class="align-center justify-center"
              style="
                padding: 0px !important;
                margin: 0;
                height: 100%;
                display: flex;
                align-items: center;
              "
            >
              <div class="d-flex flexMobile sizeMobile">
                <img src="../assets/doutorV.webp" width="350" />

                <!-- <div v-if="intBreakPoint > 2"
                                    style="background-color: #0684BC; max-height: 400px; padding: 20px; border-radius: 50px; border: 2px solid #fff"
                                    class="alinhadoM ">
                                    <h1
                                        style="color: #fff; font-family: poppins, sans-serif; font-size: 30px; letter-spacing: 2px; line-height: 1.2; margin-bottom: 15px;">
                                        agende já a sua consulta</h1>
                                    <form :id="end.form" :name="end.form" style="width: 350px;" class="formsMob">
                                        <v-text-field background-color="#fff" outlined v-model="contato.nome"
                                            placeholder="Nome" required></v-text-field>
                                        <v-text-field background-color="#fff" outlined v-model="contato.email"
                                            placeholder="E-mail" required></v-text-field>
                                        <v-text-field background-color="#fff" v-mask="'(##) # ####-####'" outlined
                                            v-model="contato.telefone" placeholder="Telefone" required></v-text-field>

                                        <button type="submit" @click="enviarFormulario"
                                            style="background-color: #3EAF05;color: #fff; font-family: poppins, sans-serif; font-size: 25px; letter-spacing: 2px;">
                                            AGENDE AGORA!
                                        </button>
                                    </form>
                                </div> -->

                <a
                  :href="end.whats"
                  class="align-center justify-center d-flex"
                  target="_blank"
                >
                  <v-btn
                    v-if="intBreakPoint < 2"
                    style="
                      padding: 20px;
                      background-color: #3eaf05;
                      color: #fff;
                      font-family: poppins, sans-serif;
                      font-size: 25px;
                      letter-spacing: 2px;
                      margin: 20px 0;
                      border-radius: 15px;
                    "
                  >
                    AGENDE AGORA!
                    <svg-icon
                      type="mdi"
                      :path="mdiWhatsapp"
                      color="white"
                      style="padding-left: 10px"
                    ></svg-icon>
                  </v-btn>
                </a>
              </div>
            </v-col>
          </v-row>

          <v-col
            v-if="intBreakPoint > 2"
            class="justify-center align-center d-flex"
            cols="12"
            md="12"
            style="margin-top: -50px; padding-top: 0"
          >
            <a :href="end.whats" target="_blank">
              <v-btn
                @click="abrirObrigado()"
                style="
                  background-color: #3eaf05;
                  color: #fff;
                  font-family: poppins, sans-serif;
                  font-size: 35px;
                  letter-spacing: 2px;
                  padding: 25px;
                  border-radius: 15px;
                "
              >
                AGENDE JÁ SUA CONSULTA!
                <svg-icon
                  type="mdi"
                  :path="mdiWhatsapp"
                  color="white"
                  style="padding-left: 10px"
                ></svg-icon>
              </v-btn>
            </a>
          </v-col>
        </video-background>
      </v-col>
    </v-row>

    <v-row
      :class="end.cidade == 'Sorocaba' ? 'imgBar2' : 'imgBar'"
      style="margin: 20px -12px"
    >
    </v-row>

    <v-row style="padding: 0 30px" class="bgcBody padMob">
      <v-col cols="12" md="12" class="alinhadoM">
        <h1
          class="title1Mob"
          style="
            color: #0098db;
            font-family: poppins, sans-serif;
            font-size: 70px;
            letter-spacing: 2px;
            line-height: 1.2;
          "
        >
          Quem somos
        </h1>
      </v-col>

      <v-col cols="12" md="6" class="alinhadoM">
        <v-card style="box-shadow: none; background-color: transparent">
          <v-img src="../assets/foto22.webp" width="450" contain></v-img>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" class="alinhadoM">
        <p
          style="
            margin-bottom: 0px !important;
            font-family: poppins, sans-serif;
            color: #000;
            font-size: 20px;
          "
        >
          Com uma estrutura ampla, moderna, confortável e mais de 20
          especialidades, a Pro Clinica é a melhor clínica popular em
          {{ end.cidade }}. <br /><br />
          Aqui você encontrará diversos serviços em um só lugar: atendimento
          clínico, pré e pós-operatório, realização de exames e condução de
          tratamentos específicos em centros de referência.
        </p>
      </v-col>

      <v-col cols="12" md="6" class="alinhadoM">
        <p
          style="
            margin-bottom: 0px !important;
            font-family: poppins, sans-serif;
            color: #000;
            font-size: 20px;
          "
        >
          Contamos com diversas especialidades médicas, odontológicas e exames
          variados. Além de uma variedade de exames, como: exames de sangue em
          geral, raio x, tomografia, ultrassonografia, e muito mais!
          <br />
          Com prazo máximo para agendamento de 15 dias. Trabalhamos de forma
          ágil e eficiente para que você tenha o suporte médico que precisa!
          <br />
          Nosso foco é se tornar referência como clinica médica e odontológica
          na cidade de {{ end.cidade }}.
          <br />
          Agende já a sua consulta!
        </p>
      </v-col>

      <v-col cols="12" md="6" class="alinhadoM">
        <v-card style="box-shadow: none; background-color: transparent">
          <v-img src="../assets/foto11.webp" contain></v-img>
        </v-card>
      </v-col>

      <v-col class="alinhadoM" cols="12" md="12">
        <a :href="end.whats" target="_blank">
          <v-btn
            class="btnMobile"
            style="
              background-color: #3eaf05;
              color: #fff;
              font-family: poppins, sans-serif;
              font-size: 35px;
              letter-spacing: 2px;
              padding: 25px;
              border-radius: 15px;
              line-height: 1;
            "
          >
            AGENDE JÁ <br v-if="intBreakPoint < 2" />
            SUA CONSULTA!
            <svg-icon
              type="mdi"
              :path="mdiWhatsapp"
              color="white"
              style="padding-left: 10px"
            ></svg-icon>
          </v-btn>
        </a>
      </v-col>
    </v-row>

    <v-row class="bgcEspecialidades padMob" style="padding: 30px 30px">
      <v-col cols="12" md="12">
        <h1
          class="title1Mob alinhadoM"
          style="
            color: #fff;
            font-family: poppins, sans-serif;
            font-size: 60px;
            letter-spacing: 2px;
            line-height: 1.2;
          "
        >
          Nossas Especialidades
        </h1>
      </v-col>

      <v-col class="alinhadoM" cols="12" md="3">
        <img src="../assets/medicIcon.webp" width="100" />
        <p
          style="
            margin-bottom: 0px !important;
            font-family: poppins, sans-serif;
            color: #fff;
            font-size: 30px;
          "
        >
          Especialidades
        </p>
        <router-link to="/medicina">
          <v-btn
            style="
              background-color: transparent !important;
              border-radius: 50px;
              color: #fff;
              box-shadow: none;
              border: 1px solid #fff !important;
            "
          >
            Saiba mais</v-btn
          >
        </router-link>
      </v-col>
      <v-col class="alinhadoM" cols="12" md="3">
        <img src="../assets/denteIcon.webp" width="90" />
        <p
          style="
            margin-bottom: 0px !important;
            font-family: poppins, sans-serif;
            color: #fff;
            font-size: 30px;
          "
        >
          Odontologia
        </p>
        <router-link to="/odonto">
          <v-btn
            style="
              background-color: transparent !important;
              border-radius: 50px;
              color: #fff;
              box-shadow: none;
              border: 1px solid #fff !important;
            "
          >
            Saiba mais</v-btn
          >
        </router-link>
      </v-col>
      <v-col class="alinhadoM" cols="12" md="3">
        <img src="../assets/estetoIcon.webp" width="100" />
        <p
          style="
            margin-bottom: 0px !important;
            font-family: poppins, sans-serif;
            color: #fff;
            font-size: 30px;
          "
        >
          Exames
        </p>
        <router-link to="/exame">
          <v-btn
            style="
              background-color: transparent !important;
              border-radius: 50px;
              color: #fff;
              box-shadow: none;
              border: 1px solid #fff !important;
            "
          >
            Saiba mais</v-btn
          >
        </router-link>
      </v-col>
      <v-col class="alinhadoM" cols="12" md="3">
        <img src="../assets/telescopIcon.webp" width="100" />
        <p
          style="
            margin-bottom: 0px !important;
            font-family: poppins, sans-serif;
            color: #fff;
            font-size: 30px;
          "
        >
          Laboratório
        </p>
        <router-link to="/exame">
          <v-btn
            style="
              background-color: transparent !important;
              border-radius: 50px;
              color: #fff;
              box-shadow: none;
              border: 1px solid #fff !important;
            "
          >
            Saiba mais</v-btn
          >
        </router-link>
      </v-col>
    </v-row>

    <!-- <v-row class="paddigMobile fundoFAQ padMob" style="padding: 40px 80px" justify="center">
            <v-col cols="12" md="12" class="alinhadoM">
                <h1 class=""
                    style="font-family: poppins, sans-serif; color: #0098DB; line-height: 1; font-size: 38px; padding: 10px 0px;">
                    fICOU COM ALGUMA DÚVIDA?
                </h1>
            </v-col>
            <v-col cols="12" md="12">
                <v-expansion-panels accordion style="background-color: transparent !important;">
                    <v-expansion-panel v-for="(quest, i) in perguntas" :key="i">
                        <v-expansion-panel-header style="font-family: poppins, sans-serif;">{{ quest.question }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content style="font-family: poppins, sans-serif;">
                            {{ quest.resposta }}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row> -->
    <v-row justify="center" style="padding: 10px 10px">
      <p
        style="
          margin-bottom: 0px !important;
          font-family: poppins, sans-serif;
          color: #000;
          font-size: 18px;
        "
      >
        {{ end.texto }}
      </p>
    </v-row>
    <v-row class="" justify="center" align="center">
      <a
        style="position: absolute; justify-content: center"
        :href="end.goggle"
        target="_blank"
      >
        <v-btn
          style="
            z-index: 99;
            align-items: center;
            background-color: #ef7c00;
            color: #fff;
            box-shadow: none;
            font-size: 25px;
            font-family: 'Poppins', sans-serif;
            text-transform: initial;
            padding: 25px;
          "
          >Como chegar</v-btn
        >
      </a>
      <v-col cols="12" md="12">
        <div
          class="alturaFooterGreen"
          style="
            background-color: #0684bc;
            opacity: 0.6;
            position: absolute;
            height: 330px;
            left: 0px;
            right: 0px;
            bottom: 0px;
          "
        ></div>
        <iframe
          :src="end.iframe"
          width="100%"
          height="300"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </v-col>
    </v-row>

    <v-dialog v-model="formMob">
      <v-card style="background-color: transparent">
        <div
          style="
            background-color: #0684bc;
            max-height: 400px;
            padding: 20px;
            border-radius: 15px;
            border: 2px solid #fff;
          "
          class="alinhadoM"
        >
          <h1
            style="
              color: #fff;
              font-family: poppins, sans-serif;
              font-size: 30px;
              letter-spacing: 2px;
              line-height: 1.2;
              margin-bottom: 15px;
            "
          >
            agende já a sua consulta
          </h1>
          <form
            :name="end.form"
            :id="end.form"
            style="width: 350px"
            class="formsMob"
          >
            <v-text-field
              background-color="#fff"
              outlined
              v-model="contato.nome"
              placeholder="Nome"
              required
            ></v-text-field>
            <v-text-field
              background-color="#fff"
              outlined
              v-model="contato.email"
              placeholder="E-mail"
              required
            ></v-text-field>
            <v-text-field
              background-color="#fff"
              v-mask="'(##)#####-####'"
              outlined
              v-model="contato.telefone"
              placeholder="Telefone"
              required
            ></v-text-field>
            <button
              type="submit"
              @click="enviarFormulario"
              style="
                background-color: #3eaf05;
                color: #fff;
                font-family: poppins, sans-serif;
                font-size: 25px;
                letter-spacing: 2px;
              "
            >
              AGENDE AGORA!
            </button>
          </form>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="obrigado"
      max-width="500"
      style="z-index: 99999 !important"
    >
      <v-card class="bgCard">
        <v-card-title
          style="
            font-weight: 500;
            font-size: 20px;
            font-family: poppins, sans-serif;
            color: #000;
            word-break: normal;
          "
        >
          Sua mensagem foi enviada!
        </v-card-title>
        <v-card-text
          style="
            font-weight: 500;
            font-family: poppins, sans-serif;
            color: #000;
            word-break: normal;
          "
        >
          Obrigado, entraremos em <br />
          contato em breve.</v-card-text
        >
        <v-card-actions>
          <v-btn
            style="left: 1px !important"
            color="#3EAF05"
            text
            @click="obrigado = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style scoped>
@media (min-device-width: 360px) and (max-device-width: 640px) {
  .padMob {
    padding: 10px 10px !important;
  }

  .heightMobile {
    height: 130vh !important;
  }

  .formsMob {
    width: 100% !important;
  }

  .sizeMobile {
    width: 100% !important;
    padding: 10px !important;
  }

  .mobileVideo {
    padding: 100px 10px !important;
  }

  .alturaMob {
    height: auto !important;
  }

  .title1Mob {
    font-size: 43px !important;
  }

  .title2Mob {
    font-size: 25px !important;
  }

  .flexMobile {
    flex-direction: column !important;
  }

  .btnMobile {
    font-size: 18px !important;
  }
}

.bgCard {
  background-image: url("../assets/sac.webp");
  background-repeat: no-repeat !important;
  background-size: cover;
  width: 500px;
  background-attachment: inherit;
  background-color: rgba(255, 255, 255, 0.596);
  background-position: center-top;
}

.bgPopup {
  background-image: url("../assets/popup.webp");
  background-repeat: no-repeat !important;
  background-size: cover;
  width: 500px;
  background-attachment: inherit;
  background-color: rgba(255, 255, 255, 0.596);
  background-position: center-top;
}

.bgcEspecialidades {
  background-image: url("../assets/bannerEspecialidades.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: inherit;
}

.bgcBody {
  background-image: url("../assets/fundaoBranco.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: inherit;
}

.imgBar2 {
  background-image: url("../assets/imagem2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  height: 250px;
}

.imgBar {
  background-image: url("../assets/imagem1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  height: 250px;
}
</style>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiWhatsapp } from "@mdi/js";

export default {
  name: "App",
  components: {
    SvgIcon,
  },
  data: () => ({
    mdiWhatsapp,
    obrigado: false,
    end: "",
    formMob: false,
    contato: { nome: "", email: "", telefone: "" },
    snackbar: false,
    text: "Seu contato foi enviado! Obrigado por sua mensagem, responderemos em breve.",
    // perguntas: [
    //     {
    //         question: 'Do que se trata o Cartão Beneficiar?',
    //         resposta: 'O Cartão Beneficiar é um cartão de benefícios com um valor acessível e, com ele, você e sua família terão acesso a consultas médicas, exames laboratoriais, dentistas, seguro, assistência/auxílio funeral familiar, descontos no comércio e muito mais'
    //     },
    //     {
    //         question: 'Quais serviços tenho disponíveis com o Cartão?',
    //         resposta: 'Consultas médicas diversas em redes credenciadas. Consultas odontológicas em redes credenciadas. Exames em redes credenciadas. Descontos em serviços credenciados: clínicas veterinárias, farmácias, pet shoppings e etc. Descontos no comércio em redes credenciadas: salão de beleza, escolas, ótica, estacionamentos, entre outros. 😱 💚'
    //     },
    //     {
    //         question: 'O valor é o mesmo para pessoas mais velhas?',
    //         resposta: 'Sim. Todos são atendidos com os mesmos benefícios, sem diferença de valores.'
    //     },
    //     {
    //         question: 'Os benefícios são para quais membros da família?',
    //         resposta: 'Os benefícios se aplicam para o titular do cartão, o cônjuge (marido ou esposa) e filhos até 21 anos.'
    //     },
    //     {
    //         question: 'É obrigatório a contratação da assistência/auxílio funeral?',
    //         resposta: 'Não. A contratação do seguro é opcional e não influencia na contratação dos demais benefícios.'
    //     },
    //     {
    //         question: 'O que contempla o seguro e assistência funeral?',
    //         resposta: 'Cobertura por morte acidental de R$ 3.500,00 (apenas para titulares); Seguro opcional com baixo custo para os demais dependentes que não se enquadrem como dependentes legais.'
    //     },
    //     {
    //         question: 'Como funciona a carência do Cartão?',
    //         resposta: 'Não tem carência. Ao adquirir o cartão Beneficiar, você já pode aproveitar todos os benefícios no mesmo dia e as cobranças são feitas no próprio local (clínica ou laboratórios).'
    //     },
    //     {
    //         question: 'Como funciona o cancelamento do Cartão?',
    //         resposta: 'O contrato de adesão tem validade de 18 meses. Ao término desse período, o cartão poderá ser rescindido a qualquer momento sem multa. O processo de cancelamento é bem simples e sem complicações, basta ir até a unidade mais próxima e realizar a solicitação.'
    //     }
    // ],
  }),

  mounted() {
    window.scrollTo({
      top: 50,
      left: 0,
      behavior: "smooth",
    });

    if (this.$route.params.obrigado == "obrigado") {
      this.obrigado = true;
    }

    let domain = window.location.host;
    if (domain.indexOf("proclinicasjc") > -1) {
      this.end = {
        iframe:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.282612113482!2d-45.8905087!3d-23.1963691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cc4a6c89c5d705%3A0x23c8f0ccf4f34156!2sR.%20Maj.%20Francisco%20de%20Paula%20Elias%2C%20217%20-%20Vila%20Adyana%2C%20S%C3%A3o%20Jos%C3%A9%20dos%20Campos%20-%20SP%2C%2012245-320!5e0!3m2!1spt-BR!2sbr!4v1660846333028!5m2!1spt-BR!2sbr",
        goggle:
          "https://www.google.com/maps/place/R.+Maj.+Francisco+de+Paula+Elias,+217+-+Vila+Adyana,+S%C3%A3o+Jos%C3%A9+dos+Campos+-+SP,+12245-320/@-23.1963642,-45.8926974,17z/data=!3m1!4b1!4m5!3m4!1s0x94cc4a6c89c5d705:0x23c8f0ccf4f34156!8m2!3d-23.1963691!4d-45.8905087",
        id: "85",
        form: "form_sjc",
        whats:
          "https://api.whatsapp.com/send?phone=551230421832&text=1%20-%20Ol%C3%A1!%20gostaria%20de%20marcar%20minha%20consulta.",
        texto:
          "R. Maj. Francisco de Paula Elias, 217 - Vila Adyana, São José dos Campos - SP, 12245-320",
        logo: require("../assets/logo.webp"),
        cidade: "São José dos Campos",
        idTag: "GTM-TNMM6Z9",
        idFunil: "62fbe6c222e8e1000fbf5f3b",
      };
      this.enableGoogleAnalytics();
    } else {
      this.end = {
        iframe:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.2703492992364!2d-47.475206899999996!3d-23.486769900000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5f533bb266e9b%3A0x50c319b8f85df47e!2sAv.%20Santos%20Dumont%2C%20200%20-%20Jardim%20Ana%20Maria%2C%20Sorocaba%20-%20SP%2C%2018065-290!5e0!3m2!1spt-BR!2sbr!4v1660851304299!5m2!1spt-BR!2sbr",
        goggle:
          "https://www.google.com/maps/place/Av.+Santos+Dumont,+200+-+Jardim+Ana+Maria,+Sorocaba+-+SP,+18065-290/@-23.4867699,-47.4752069,17z/data=!3m1!4b1!4m5!3m4!1s0x94c5f533bb266e9b:0x50c319b8f85df47e!8m2!3d-23.4867699!4d-47.4752069",
        whats:
          "https://api.whatsapp.com/send?phone=551530421188&text=1%20-%20Ol%C3%A1!%20gostaria%20de%20marcar%20minha%20consulta.",
        id: "86",
        form: "form_sorocaba",
        texto:
          "Av. Santos Dumont, 200 - Jardim Ana Maria, Sorocaba - SP, 18065-290",
        cidade: "Sorocaba",
        logo: require("../assets/logoSorocaba.webp"),
        idTag: "GTM-MSBSWCJ",
        idFunil: "6308cdf6cb3f67000f0eb48d",
      };
      this.enableGoogleAnalytics();
    }
  },

  watch: {
    "$route.params.obrigado": {
      handler: function (obrigado) {
        if (obrigado == "obrigado") {
          this.obrigado = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    enviarFormulario(event) {
      event.preventDefault();

      if (this.contato.nome === "") {
        alert("Por favor preencha todos os campos");
        (this.loading = false), (this.dialog = false);
        return;
      } else if (this.contato.email === "") {
        alert("Por favor preencha todos os campos");
        (this.loading = false), (this.dialog = false);
        return;
      } else if (this.contato.telefone === "") {
        alert("Por favor preencha todos os campos");
        (this.loading = false), (this.dialog = false);
        return;
      }

      fetch("https://gestao.abare.cloud/items/leads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id_formulario: this.end.id,
          lead: this.contato,
        }),
      })
        .then((response) => response.text())
        .then((data) => {
          window.console.log(data);
          // alert("contato enviado com sucesso");
          this.snackbar = false;
          this.enviaRdstation();
          // this.$router.replace('/obrigado')
          this.abrirObrigado();
        });
    },

    abrirObrigado() {
      this.$router.push("/landingPage/obrigado");
    },

    enviaRdstation() {
      fetch("https://abaremarketing.com/phpProclinica.php", {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(
          // 62fd4b0d20565f0011f5229c FUNIL SOROCABA
          //62fbe6c222e8e1000fbf5f3b FUNIL SJC
          {
            nome: this.contato.nome,
            id_funil: this.end.idFunil,
            email: this.contato.email,
            telefone: this.contato.telefone,
          }
        ),
      })
        .then((response) => response.text())
        .then((data) => {
          window.console.log(data);
          // alert("contato enviado com sucesso");
          // this.obrigado = true;
          // this.snackbar = false;
        });
    },
    async enableGoogleAnalytics() {
      // var googleAnalyticsId = await loadGtmId(); // GTM-XXXXXXX
      this.activateGtm(window, document, "script", "dataLayer", this.end.idTag);
    },
    activateGtm(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    },
  },

  computed: {
    isHome() {
      return this.$route.name == "inicial";
    },
    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 3;
        case "xl":
          return 4;
        default:
          return 0;
      }
    },
  },
};
</script>
