<template>
  <v-row class="comment-section">
    <v-col cols="12" class="comment-header">
      <h2>Deixe um comentário</h2>
      <p>
        O seu endereço de e-mail não será publicado. Campos obrigatórios são
        marcados com *
      </p>
      <v-form>
        <div class="id-container">
          <v-text-field class="input-field-comment" v-model="comentario.nome" placeholder="Nome*" variant="outlined"
            bg-color="#fff" color="#999" required></v-text-field>
          <v-text-field class="input-field-comment" v-model="comentario.email" type="e-mail" placeholder="E-mail*"
            variant="outlined" bg-color="#fff" color="#999" required></v-text-field>
          <v-text-field class="input-field-comment" v-model="comentario.site" placeholder="Site" variant="outlined"
            bg-color="#fff" color="#999" required></v-text-field>
        </div>
        <v-textarea class="input-field-comment" v-model="comentario.mensagem" placeholder="Mensagem*" variant="outlined"
          bg-color="#fff" color="#999" required></v-textarea>
        <div class="d-flex justify-center pb-5">
          <v-btn class="submit-comment-btn" @click="postComment()">Publicar comentário</v-btn>
        </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    comentario: {
      nome: "",
      email: "",
      site: "",
      mensagem: "",
      artigo: "",
    },
  }),
  methods: {
    postComment() {
      this.comentario.artigo = this.$route.params.id;
      if (!this.validateEmail(this.comentario.email)) {
        return alert("Insira um e-mail válido");
      }
      if (
        !this.comentario.nome ||
        !this.comentario.email ||
        !this.comentario.mensagem
      ) {
        alert("Preencha todos os campos obrigatórios");
      } else {
        axios.post(
          `https://blogs.abare.cloud/items/pro_clinica_comentarios/`,
          this.comentario
        );
        alert("Comentário enviado");
        this.comentario = {
          nome: "",
          email: "",
          site: "",
          mensagem: "",
          artigo: "",
        };
      }
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
  },
};
</script>

<style>
.comment-section {
  background-color: #f7f2f2;
  padding: 5% 10%;
}

.comment-header h2 {
  margin-top: 1rem;
  font-size: 1.5rem;
}

.comment-header p {
  margin: 1rem 0;
  font-size: 1rem;
}

.input-field-comment {
  padding: 1rem;

}

.submit-comment-btn {
  width: 30%;
  background-color: #2f5aae !important;
  color: #fff !important;
}

.id-container {
  display: flex;
}

@media (max-width: 600px) {
  .id-container {
    display: block;
  }

  .submit-comment-btn {
    width: 80%;
  }
}

@media (min-width: 1900px) {
  .comment-section {
    padding: 5% 18%;
  }
}
</style>
