<template>
	<v-container fluid>
		<v-row class="bgc4" align="center" :style="intBreakPoint >= 2 ? 'padding: 40px 60px' : 'padding: 1.5rem'">
			<v-col cols="12" md="6">
				<v-img :src="end.logo" style="width: 250px"></v-img>
				<h1 class="mt-5" style="
						line-height: 1.2;
						font-family: Montserrat;
						font-weight: 800;
						color: #0659a1;
					">
					CUIDAR DA SUA SAÚDE ÍNTIMA <br />
					É CUIDAR DO SEU BEM-ESTAR
				</h1>
				<p class="mt-5" style="font-family: Montserrat">
					Nossos ginecologistas estão prontos para te atender <br />
					com excelência, comprometimento e foco total
					<br v-if="intBreakPoint >= 2" />
					na sua saúde.
				</p>
				<h1 class="mt-5" style="
						font-size: 1.7rem !important;
						line-height: 1.2;
						font-family: Montserrat;
						font-weight: 800;
						color: #707070;
					">
					Pró-Clínica, sua Clínica Médica <br />
					em {{ end.cidade }}
				</h1>
			</v-col>
			<v-col cols="12" md="12">
				<v-row>
					<v-col cols="12" md="4" class="d-flex align-center flex-column">
						<button style="
								background-color: #45eb63;
								color: #fff;
								font-family: Montserrat;
								width: 250px;
								height: 5.5rem;
								padding: 0.5rem 1rem;
								border-radius: 2rem;
								font-weight: 800;
								text-align: ;
							" @click="abrirDialogWhats">
							<v-icon>mdi-whatsapp</v-icon> Quero agendar minha <br />
							<span style="text-transform: uppercase">avaliação grátis</span>
						</button>
					</v-col>
					<v-col cols="12" md="4" class="d-flex align-center flex-column">
						<v-img style="width: 250px" src="../assets/lpsEspecialidade/Grupo 1.png"></v-img>
					</v-col>
					<v-col cols="12" md="4" class="d-flex align-center flex-column">
						<v-img style="width: 250px" src="../assets/lpsEspecialidade/Grupo 3.png"></v-img>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<v-row class="bgc3">
			<v-col cols="12" md="12" class="d-flex align-center justify-center">
				<div :style="intBreakPoint >= 2
					? 'background-color: #fff; border-radius: 50px; padding: 5px 20px'
					: 'background-color: #fff; border-radius: 25px; padding: 5px 20px'
					">
					<h1 style="
							font-family: Montserrat;
							font-weight: 800;
							color: #064277;
							line-height: 1.2;
							font-size: 1.5rem;
						">
						EM DÚVIDA SE É O MOMENTO DE FAZER UM CHECK UP?
					</h1>
				</div>
			</v-col>
			<v-col cols="12" md="6" class="d-flex justify-center flex-column">
				<p style="font-family: Montserrat; color: #fff; line-height: 1.2">
					De acordo com o Ministério da Saúde, a consulta preventiva com o
					ginecologista deve ocorrer pelo menos
					<b> uma vez ao ano </b>, salvo algumas exceções. <br />
					<br />

					<b>
						Para pacientes com doenças ginecológicas ou disfunções hormonais </b>, recomenda-se duas ou mais consultas
					dentro de um ano.
					<br />
					<br />
					<b>
						Além da consulta preventiva, esses são os sintomas mais comuns da
						saúde feminina que requerem atenção médica:
					</b>
				</p>

				<div :style="intBreakPoint >= 2 ? 'width: 35vw' : 'width: 100%'" class="efeito-vidro">
					<v-list dense color="transparent">
						<v-list-item-group color="primary">
							<v-list-item style="color: #fff !important">
								<v-icon color="#fff">mdi-check</v-icon>
								<v-list-item-content>
									<v-list-item-title>Dor durante a relação sexual</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item style="color: #fff !important">
								<v-list-item-content>
									<v-list-item-title>Ciclo menstrual irregular e/ou doloroso</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item style="color: #fff !important">
								<v-list-item-content>
									<v-list-item-title>Dor pélvica crônica</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item style="color: #fff !important">
								<v-list-item-content>
									<v-list-item-title>Alterações na mama</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item style="color: #fff !important">
								<v-list-item-content>
									<v-list-item-title>Mudanças repentinas no peso <br v-if="intBreakPoint < 2" />
										(sem motivo aparente)
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item style="color: #fff !important; word-break: break-all">
								<v-list-item-content style="word-break: break-all">
									<v-list-item-title style="word-break: break-all; word-wrap: break-word">
										<p style="word-break: break-all; word-wrap: break-word">
											Sangramento vaginal anormal<br />

											(períodos menstruais intensos, irregulares,
											<br v-if="intBreakPoint < 2" />
											entre outros)
										</p>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</div>
			</v-col>
			<v-col cols="12" md="6" class="d-flex align-center justify-center">
				<v-img contain style="width: 70%; max-width: 70%" src="../assets/lpsEspecialidade/Gine 3.png"></v-img>
			</v-col>

			<v-col cols="12" md="12" class="d-flex align-center justify-center flex-column">
				<p style="font-family: Montserrat; color: #fff; text-align: center">
					Esses são apenas alguns exemplos de sintomas comuns que requerem
					atenção médica na saúde feminina. Se você está enfrentando um ou mais
					sintomas como esses, agende sua consulta com Ginecologista na
					Pró-Clínica. Nossa missão é cuidar da sua saúde com compromisso e
					seriedade.
					<br /><br />
					Exames: Colocar todos os exames para Ginecologista
				</p>

				<v-btn class="btnLp" @click="abrirDialogWhats">
					AGENDAR MINHA CONSULTA
				</v-btn>
			</v-col>
		</v-row>

		<v-row :style="intBreakPoint >= 2 ? 'padding: 20px 60px' : ''">
			<v-col cols="12" md="12">
				<p style="font-family: Montserrat; color: #707070; text-align: center">
					A gente sabe que muitas mulheres adiam o cuidado com a saúde íntima
					por falta de tempo, dinheiro ou porque as consultas são marcadas para
					dois meses no futuro.
					<br /><br />
					Por isso na Pró-Clínica você pode agendar a sua consulta com
					<b style="color: #0659a1"> Ginecologista em {{ end.cidade }} </b> em
					minutos através do Whatsapp com
					<b style="color: #0659a1"> preço justo e datas próximas.</b>
				</p>
			</v-col>
			<v-col cols="12" md="12" class="d-flex justify-center">
				<v-img :style="intBreakPoint >= 2
					? 'max-width: 65%; cursor: pointer'
					: 'cursor: pointer'
					" src="../assets/lpsEspecialidade/img3.png" @click="abrirDialogWhats"></v-img>
			</v-col>
		</v-row>

		<v-row style="background-color: #f5f5f5">
			<v-col cols="12" md="12" class="d-flex align-center justify-center">
				<v-img style="max-width: 3rem" src="../assets/abareLogoPreto.webp" contain></v-img>
				<v-img style="max-width: 8rem" :src="end.logoPreto" contain></v-img>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'Ginecologia',

	data() {
		return {};
	},
	methods: {
		abrirDialogWhats() {
			this.$root.$emit('show-whats');
		},
	},
	computed: {
		intBreakPoint() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 0;
				case 'sm':
					return 1;
				case 'md':
					return 2;
				case 'lg':
					return 3;
				case 'xl':
					return 4;
				default:
					return 0;
			}
		},

		end() {
			return this.$store.getters.unidade;
		},
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style scoped>
@media (max-width: 600px) {
	.bgc3 {
		background-image: url('../assets/lpsEspecialidade/Grupo 5.png') !important;
		/* height: 100vh; */
		background-size: cover !important;
		background-position: center;
		border-radius: 50px;
		padding: 0 !important;
	}

	.bgc4 {
		background-image: url('../assets/lpsEspecialidade/banner1.jpg') !important;
		height: 100% !important;
		background-size: cover !important;
		background-position: center;
	}
}

.btnLp {
	background-color: #fff;
	color: #064277;
	font-family: Montserrat;
	font-weight: 800;
	border-radius: 50px;
	letter-spacing: normal;
	font-size: 1.2rem;
}

.efeito-vidro {
	background: rgba(255, 255, 255, 0.35);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(13.5px);
	-webkit-backdrop-filter: blur(9.5px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
}

.bgc3 {
	background-image: url('../assets/lpsEspecialidade/Grupo 5.png') !important;
	/* height: 100vh; */
	background-size: cover !important;
	background-position: center;
	border-radius: 50px;
	padding: 20px 60px;
}

.bgc4 {
	background-image: url('../assets/lpsEspecialidade/banner1.jpg') !important;
	height: 100vh;
	background-size: cover !important;
	background-position: center;
}
</style>
