<template>
    <v-container fluid>
        <v-row class="justify-center align-center bgc1 heightMob padMob" style="padding: 0 0px;">
            <v-col cols="12" md="6" class="align-center justify-center d-flex">
                <h1 class="title1Mob alinhadoM" style="
              color: #fff;
              font-family: Montserrat, sans-serif;
              font-size: 50px;
              letter-spacing: 2px;
              line-height: 1.2;
              padding-top: 70px;
            ">
                    NOSSOS EXAMES
                </h1>
            </v-col>
            <v-col cols="12" md="6"></v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                <div class="tamanhoDiv"
                    style="background-color:#F6FAFF; padding:30px; max-width:800px; border-radius:30px; box-shadow: 10px 5px 5px #8D8D8D;">
                    <v-row>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <v-img contain style="max-width:250px;" src="../assets/examesNovos/laboratoriais.webp"></v-img>
                        </v-col>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <h1 class="title1Mob alinhadoM" style="
                                color: #5190BF;
                                font-family: Montserrat, sans-serif;
                                font-size: 45px;
                                letter-spacing: 2px;
                                line-height: 1;
                                ">
                                Exames Laboratoriais
                            </h1>
                        </v-col>
                        <v-row>
                            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                                <v-expand-transition>
                                    <div v-show="show1">
                                        <v-divider></v-divider>
                                        <v-col cols="12" md="12"
                                            class="align-center justify-center d-flex flex-column paddingCol">
                                            <v-card v-for="(itens, i) in laboratoriais" :key="i"
                                                class="align-center d-flex tamanhoCard" style="
                                    margin:5px 0px;
                                    max-height:100px;
                                    max-width:500px;
                                    width:500px;
                                    /* min-width:700px; */
                                    border-radius:20px;
                                    padding:20px">
                                                <v-row>
                                                    <!-- <v-col cols="4" md="4" class="align-center justify-center d-flex">
                                            <v-img contain style="max-width:50px;" :src="itens.icone"></v-img>
                                        </v-col> -->
                                                    <v-col cols="6" md="6" class="align-center  d-flex">
                                                        <p class="fontCardMb"
                                                            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 10px;">
                                                            {{ itens.nome }}
                                                        </p>
                                                    </v-col>
                                                    <v-col cols="6" md="6" class="align-center d-flex"
                                                        style="justify-content:right">
                                                        <v-btn class="mgLeftBotao" @click="abrirDialogWhats()" style="
                                            background-color:#FF4B4B;
                                            color:#fff;
                                            font-weight:800;
                                            margin-left:76px;
                                            text-transform: none;
                                            padding:5px;
                                            border-radius:5px">
                                                            Saiba mais</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </div>
                                </v-expand-transition>
                            </v-col>
                        </v-row>
                        <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column">
                            <v-btn @click="show1 = !show1" text style="
                            color:#2C6B9B; font-weight:800; font-family: Montserrat, sans-serif; font-size:20px">
                                {{ show1 ? 'Fechar ' : 'Veja mais ' }}
                                <svg-icon type="mdi" :path="show1 ? this.mdiArrowUp : this.mdiArrowDown"></svg-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>

            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                <div class="tamanhoDiv"
                    style="background-color:#F6FAFF; padding:30px; max-width:800px; border-radius:30px; box-shadow: 10px 5px 5px #8D8D8D;">
                    <v-row>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <v-img contain style="max-width:250px;" src="../assets/examesNovos/cardio.webp"></v-img>
                        </v-col>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <h1 class="title1Mob alinhadoM" style="
                                color: #5190BF;
                                font-family: Montserrat, sans-serif;
                                font-size: 45px;
                                letter-spacing: 2px;
                                line-height: 1;
                                ">
                                Exames Cardiológicos
                            </h1>
                        </v-col>
                        <v-row>
                            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                                <v-expand-transition>
                                    <div v-show="show2">
                                        <v-col cols="12" md="12"
                                            class="align-center justify-center d-flex flex-column paddingCol">
                                            <v-divider></v-divider>
                                            <v-card v-for="(itens, i) in cardiologicos" :key="i"
                                                class="align-center d-flex tamanhoCard" style="
                                            margin:5px 0px;
                                            max-height:100px;
                                            max-width:500px;
                                            width:500px;
                                            /* min-width:700px; */
                                            border-radius:20px;
                                            padding:20px">
                                                <v-row>
                                                    <!-- <v-col cols="4" md="4" class="align-center justify-center d-flex">
                                                    <v-img contain style="max-width:50px;" :src="itens.icone"></v-img>
                                                </v-col> -->
                                                    <v-col cols="6" md="6" class="align-center  d-flex">
                                                        <p class="fontCardMb"
                                                            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 10px;">
                                                            {{ itens.nome }}
                                                        </p>
                                                    </v-col>
                                                    <v-col cols="6" md="6" class="align-center d-flex"
                                                        style="justify-content:right">
                                                        <v-btn class="mgLeftBotao" @click="abrirDialogWhats()" style="
                                                    background-color:#FF4B4B;
                                                    color:#fff;
                                                    font-weight:800;
                                                    margin-left:76px;
                                                    text-transform: none;
                                                    padding:5px;
                                                    border-radius:5px">
                                                            Saiba mais</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </div>
                                </v-expand-transition>
                            </v-col>
                        </v-row>
                        <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column">
                            <v-btn @click="show2 = !show2" text
                                style="color:#2C6B9B; font-weight:800; font-family: Montserrat, sans-serif; font-size:20px">
                                {{ show2 ? 'Fechar ' : 'Veja mais ' }}
                                <svg-icon type="mdi" :path="show2 ? this.mdiArrowUp : this.mdiArrowDown"></svg-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>

            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                <div class="tamanhoDiv"
                    style="background-color:#F6FAFF; padding:30px; max-width:800px; border-radius:30px; box-shadow: 10px 5px 5px #8D8D8D;">
                    <v-row>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <v-img contain style="max-width:250px;" src="../assets/examesNovos/ofta.webp"></v-img>
                        </v-col>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <h1 class="title1Mob alinhadoM" style="
                                color: #5190BF;
                                font-family: Montserrat, sans-serif;
                                font-size: 45px;
                                letter-spacing: 2px;
                                line-height: 1;
                                ">
                                Exames Oftamológicos
                            </h1>
                        </v-col>
                        <v-row>
                            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                                <v-expand-transition>
                                    <div v-show="show3">
                                        <v-col cols="12" md="12"
                                            class="align-center justify-center d-flex flex-column paddingCol">
                                            <v-divider></v-divider>
                                            <div v-if="end.idName !== 'sorocaba'">
                                                <v-card v-for="(itens, i) in oftamologicos" :key="i"
                                                    class="align-center d-flex tamanhoCard" style="
                                    margin:5px 0px;
                                    max-height:100px;
                                    max-width:500px;
                                    width:500px;
                                    /* min-width:700px; */
                                    border-radius:20px;
                                    padding:20px">
                                                    <v-row>
                                                        <!-- <v-col cols="4" md="4" class="align-center justify-center d-flex">
                                            <v-img contain style="max-width:50px;" :src="itens.icone"></v-img>
                                        </v-col> -->
                                                        <v-col cols="6" md="6" class="align-center  d-flex">
                                                            <p class="fontCardMb"
                                                                style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 10px;">
                                                                {{ itens.nome }}
                                                            </p>
                                                        </v-col>
                                                        <v-col cols="6" md="6" class="align-center d-flex">
                                                            <v-btn class="mgLeftBotao" @click="abrirDialogWhats()" style="
                                            background-color:#FF4B4B;
                                            color:#fff;
                                            font-weight:800;
                                            margin-left:76px;
                                            text-transform: none;
                                            padding:5px;
                                            border-radius:5px">
                                                                Saiba mais</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </div>
                                            <div v-else>
                                                <v-card v-for="(itens, i) in oftamologicosSorocaba" :key="i"
                                                    class="align-center d-flex tamanhoCard" style="
                                    margin:5px 0px;
                                    max-height:100px;
                                    max-width:500px;
                                    width:500px;
                                    /* min-width:700px; */
                                    border-radius:20px;
                                    padding:20px">
                                                    <v-row>
                                                        <!-- <v-col cols="4" md="4" class="align-center justify-center d-flex">
                                            <v-img contain style="max-width:50px;" :src="itens.icone"></v-img>
                                        </v-col> -->
                                                        <v-col cols="6" md="6" class="align-center  d-flex">
                                                            <p class="fontCardMb"
                                                                style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 10px;">
                                                                {{ itens.nome }}
                                                            </p>
                                                        </v-col>
                                                        <v-col cols="6" md="6" class="align-center d-flex">
                                                            <v-btn class="mgLeftBotao" @click="abrirDialogWhats()" style="
                                            background-color:#FF4B4B;
                                            color:#fff;
                                            font-weight:800;
                                            margin-left:76px;
                                            text-transform: none;
                                            padding:5px;
                                            border-radius:5px">
                                                                Saiba mais</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </div>
                                        </v-col>
                                    </div>
                                </v-expand-transition>
                            </v-col>
                        </v-row>
                        <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column">
                            <v-btn @click="show3 = !show3" text
                                style="color:#2C6B9B; font-weight:800; font-family: Montserrat, sans-serif; font-size:20px">
                                {{ show3 ? 'Fechar ' : 'Veja mais ' }}
                                <svg-icon type="mdi" :path="show3 ? this.mdiArrowUp : this.mdiArrowDown"></svg-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>

            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                <div class="tamanhoDiv"
                    style="background-color:#F6FAFF; padding:30px; max-width:800px; border-radius:30px; box-shadow: 10px 5px 5px #8D8D8D;">
                    <v-row>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <v-img contain style="max-width:250px;" src="../assets/examesNovos/ultrassom.webp"></v-img>
                        </v-col>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <h1 class="title1Mob alinhadoM" style="
                                color: #5190BF;
                                font-family: Montserrat, sans-serif;
                                font-size: 45px;
                                letter-spacing: 2px;
                                line-height: 1;
                                ">
                                Ultrassom-Doppler
                            </h1>
                        </v-col>
                        <v-row>
                            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                                <v-expand-transition>
                                    <div v-show="show4">
                                        <v-col cols="12" md="12"
                                            class="align-center justify-center d-flex flex-column  paddingCol">
                                            <v-divider></v-divider>
                                            <div v-if="end.idName !== 'sorocaba'">
                                                <v-card v-for="(itens, i) in ultrassom" :key="i"
                                                    class="align-center d-flex tamanhoCard" style="
                                margin:5px 0px;
                                max-height:100px;
                                max-width:500px;
                                width:500px;
                                border-radius:20px;
                                padding:20px">
                                                    <v-row>
                                                        <!-- <v-col cols="4" md="4" class="align-center justify-center d-flex">
                                        <v-img contain style="max-width:50px;" :src="itens.icone"></v-img>
                                    </v-col> -->
                                                        <v-col cols="6" md="6" class="align-center  d-flex">
                                                            <p class="fontCardMb"
                                                                style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 10px;">
                                                                {{ itens.nome }}
                                                            </p>
                                                        </v-col>
                                                        <v-col cols="6" md="6" class="align-center d-flex">
                                                            <v-btn class="mgLeftBotao" @click="abrirDialogWhats()" style="
                                        background-color:#FF4B4B;
                                        color:#fff;
                                        font-weight:800;
                                        margin-left:76px;
                                        text-transform: none;
                                        padding:5px;
                                        border-radius:5px">
                                                                Saiba mais</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </div>
                                            <div v-else>
                                                <v-card v-for="(itens, i) in ultrassomSorocaba" :key="i"
                                                    class="align-center d-flex tamanhoCard" style="
                                margin:5px 0px;
                                max-height:100px;
                                max-width:500px;
                                width:500px;
                                border-radius:20px;
                                padding:20px">
                                                    <v-row>
                                                        <!-- <v-col cols="4" md="4" class="align-center justify-center d-flex">
                                        <v-img contain style="max-width:50px;" :src="itens.icone"></v-img>
                                    </v-col> -->
                                                        <v-col cols="6" md="6" class="align-center  d-flex">
                                                            <p class="fontCardMb"
                                                                style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 10px;">
                                                                {{ itens.nome }}
                                                            </p>
                                                        </v-col>
                                                        <v-col cols="6" md="6" class="align-center d-flex">
                                                            <v-btn class="mgLeftBotao" @click="abrirDialogWhats()" style="
                                        background-color:#FF4B4B;
                                        color:#fff;
                                        font-weight:800;
                                        margin-left:76px;
                                        text-transform: none;
                                        padding:5px;
                                        border-radius:5px">
                                                                Saiba mais</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </div>
                                        </v-col>
                                    </div>
                                </v-expand-transition>
                            </v-col>
                        </v-row>
                        <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column">
                            <v-btn @click="show4 = !show4" text
                                style="color:#2C6B9B; font-weight:800; font-family: Montserrat, sans-serif; font-size:20px">
                                {{ show4 ? 'Fechar ' : 'Veja mais ' }}
                                <svg-icon type="mdi" :path="show4 ? this.mdiArrowUp : this.mdiArrowDown"></svg-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>

            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                <div class="tamanhoDiv"
                    style="background-color:#F6FAFF; padding:30px; max-width:800px; border-radius:30px; box-shadow: 10px 5px 5px #8D8D8D;">
                    <v-row>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <v-img contain style="max-width:250px;" src="../assets/examesNovos/neurologicos.webp"></v-img>
                        </v-col>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <h1 class="title1Mob alinhadoM" style="
                                color: #5190BF;
                                font-family: Montserrat, sans-serif;
                                font-size: 45px;
                                letter-spacing: 2px;
                                line-height: 1;
                                ">
                                Exames Neurológicos
                            </h1>
                        </v-col>

                        <v-row>
                            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                                <v-expand-transition>
                                    <div v-show="show5">
                                        <v-col cols="12" md="12"
                                            class="align-center justify-center d-flex flex-column paddingCol   ">
                                            <v-divider></v-divider>
                                            <v-card v-for="(itens, i) in neurologicos" :key="i"
                                                class="align-center d-flex tamanhoCard" style="
                                margin:5px 0px;
                                max-height:100px;
                                max-width:500px;
                                width:500px;
                                /* min-width:700px; */
                                border-radius:20px;
                                padding:20px">
                                                <v-row>
                                                    <!-- <v-col cols="4" md="4" class="align-center justify-center d-flex">
                                        <v-img contain style="max-width:50px;" :src="itens.icone"></v-img>
                                    </v-col> -->
                                                    <v-col cols="6" md="6" class="align-center  d-flex">
                                                        <p class="fontCardMb"
                                                            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 10px;">
                                                            {{ itens.nome }}
                                                        </p>
                                                    </v-col>
                                                    <v-col cols="6" md="6" class="align-center d-flex">
                                                        <v-btn class="mgLeftBotao" @click="abrirDialogWhats()" style="
                                        background-color:#FF4B4B;
                                        color:#fff;
                                        font-weight:800;
                                        margin-left:76px;
                                        text-transform: none;
                                        padding:5px;
                                        border-radius:5px">
                                                            Saiba mais</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </div>
                                </v-expand-transition>
                            </v-col>
                        </v-row>
                        <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column">
                            <v-btn @click="show5 = !show5" text
                                style="color:#2C6B9B; font-weight:800; font-family: Montserrat, sans-serif; font-size:20px">
                                {{ show5 ? 'Fechar ' : 'Veja mais ' }}
                                <svg-icon type="mdi" :path="show5 ? this.mdiArrowUp : this.mdiArrowDown"></svg-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>

            <v-col cols="12" md="12" class="align-center justify-center d-flex" v-if="end.idName !== 'sorocaba'">
                <div class="tamanhoDiv"
                    style="background-color:#F6FAFF; padding:30px; max-width:800px; border-radius:30px; box-shadow: 10px 5px 5px #8D8D8D;">
                    <v-row>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <v-img contain style="max-width:250px;" src="../assets/examesNovos/audi.webp"></v-img>
                        </v-col>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <h1 class="title1Mob alinhadoM" style="
                                color: #5190BF;
                                font-family: Montserrat, sans-serif;
                                font-size: 45px;
                                letter-spacing: 2px;
                                line-height: 1;
                                ">
                                Exames Auditivos
                            </h1>
                        </v-col>

                        <v-row>
                            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                                <v-expand-transition>
                                    <div v-show="show6">
                                        <v-col cols="12" md="12"
                                            class="align-center justify-center d-flex flex-column  paddingCol">
                                            <v-divider></v-divider>
                                            <v-card v-for="(itens, i) in auditivos" :key="i"
                                                class="align-center d-flex tamanhoCard" style="
                                    margin:5px 0px;
                                    max-height:100px;
                                    max-width:500px;
                                    width:500px;
                                    /* min-width:700px; */
                                    border-radius:20px;
                                    padding:20px">
                                                <v-row>
                                                    <!-- <v-col cols="4" md="4" class="align-center justify-center d-flex">
                                            <v-img contain style="max-width:50px;" :src="itens.icone"></v-img>
                                        </v-col> -->
                                                    <v-col cols="6" md="6" class="align-center  d-flex">
                                                        <p class="tamanhoAuditivo"
                                                            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 10px;">
                                                            {{ itens.nome }}
                                                        </p>
                                                    </v-col>
                                                    <v-col cols="6" md="6" class="align-center d-flex">
                                                        <v-btn class="mgLeftBotao" @click="abrirDialogWhats()" style="
                                            background-color:#FF4B4B;
                                            color:#fff;
                                            font-weight:800;
                                            margin-left:126px;
                                            text-transform: none;
                                            padding:5px;
                                            border-radius:5px">
                                                            Saiba mais</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </div>
                                </v-expand-transition>
                            </v-col>
                        </v-row>
                        <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column">
                            <v-btn @click="show6 = !show6" text
                                style="color:#2C6B9B; font-weight:800; font-family: Montserrat, sans-serif; font-size:20px;">
                                {{ show6 ? 'Fechar ' : 'Veja mais ' }}
                                <svg-icon type="mdi" :path="show6 ? this.mdiArrowUp : this.mdiArrowDown"></svg-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>

            <v-col v-if="this.unidade === 'SÃO JOSÉ DOS CAMPOS'" cols="12" md="12"
                class="align-center justify-center d-flex">
                <div class="tamanhoDiv"
                    style="background-color:#F6FAFF; padding:30px; max-width:800px; border-radius:30px; box-shadow: 10px 5px 5px #8D8D8D; width:800px">
                    <v-row>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <v-img contain style="max-width:250px;" src="../assets/examesNovos/gastro.webp"></v-img>
                        </v-col>
                        <v-col cols="12" md="6" class="align-center  justify-center flex-column">
                            <h1 class="title1Mob alinhadoM" style="
                                color: #5190BF;
                                font-family: Montserrat, sans-serif;
                                font-size: 45px;
                                letter-spacing: 2px;
                                margin-bottom:20px;
                                line-height: 1;
                                ">
                                Exames Gastro
                            </h1>
                            <v-btn @click="() => {
                                this.$root.$emit('show-whats');
                            }" text
                                style="color:#fff; font-weight:500; font-family: Montserrat, sans-serif; background-color:#0965AB; border-radius:20px">
                                Agendar exame
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column">
                        </v-col>
                    </v-row>
                </div>
            </v-col>

            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                <div class="tamanhoDiv"
                    style="background-color:#F6FAFF; padding:30px; max-width:800px; border-radius:30px; box-shadow: 10px 5px 5px #8D8D8D;">
                    <v-row>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <v-img contain style="max-width:250px; border-radius: 1rem"
                                src="../assets/examesNovos/feminino.webp"></v-img>
                        </v-col>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <h1 class="title1Mob alinhadoM" style="
                                color: #5190BF;
                                font-family: Montserrat, sans-serif;
                                font-size: 45px;
                                letter-spacing: 2px;
                                line-height: 1;
                                ">
                                Exames Femininos
                            </h1>
                        </v-col>

                        <v-row>
                            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                                <v-expand-transition>
                                    <div v-show="show8">
                                        <v-col cols="12" md="12"
                                            class="align-center justify-center d-flex flex-column paddingCol">
                                            <v-divider></v-divider>
                                            <div v-if="end.idName !== 'sorocaba'">
                                                <v-card v-for="(itens, i) in feminino" :key="i"
                                                    class="align-center d-flex tamanhoCard" style="
                                    margin:5px 0px;
                                    max-height:100px;
                                    max-width:500px;
                                    width:500px;
                                    /* min-width:700px; */
                                    border-radius:20px;
                                    padding:20px">
                                                    <v-row>
                                                        <!-- <v-col cols="4" md="4" class="align-center justify-center d-flex">
                                            <v-img contain style="max-width:50px;" :src="itens.icone"></v-img>
                                        </v-col> -->
                                                        <v-col cols="6" md="6" class="align-center  d-flex">
                                                            <p class="fontCardMb"
                                                                style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 10px;">
                                                                {{ itens.nome }}
                                                            </p>
                                                        </v-col>
                                                        <v-col cols="6" md="6" class="align-center d-flex">
                                                            <v-btn class="mgLeftBotao" @click="abrirDialogWhats()" style="
                                            background-color:#FF4B4B;
                                            color:#fff;
                                            font-weight:800;
                                            margin-left:76px;
                                            text-transform: none;
                                            padding:5px;
                                            border-radius:5px">
                                                                Saiba mais</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </div>
                                            <div v-else>
                                                <v-card v-for="(itens, i) in femininoSorocaba" :key="i"
                                                    class="align-center d-flex tamanhoCard" style="
                                    margin:5px 0px;
                                    max-height:100px;
                                    max-width:500px;
                                    width:500px;
                                    /* min-width:700px; */
                                    border-radius:20px;
                                    padding:20px">
                                                    <v-row>
                                                        <!-- <v-col cols="4" md="4" class="align-center justify-center d-flex">
                                            <v-img contain style="max-width:50px;" :src="itens.icone"></v-img>
                                        </v-col> -->
                                                        <v-col cols="6" md="6" class="align-center  d-flex">
                                                            <p class="fontCardMb"
                                                                style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 10px;">
                                                                {{ itens.nome }}
                                                            </p>
                                                        </v-col>
                                                        <v-col cols="6" md="6" class="align-center d-flex">
                                                            <v-btn class="mgLeftBotao" @click="abrirDialogWhats()" style="
                                            background-color:#FF4B4B;
                                            color:#fff;
                                            font-weight:800;
                                            margin-left:76px;
                                            text-transform: none;
                                            padding:5px;
                                            border-radius:5px">
                                                                Saiba mais</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </div>
                                        </v-col>
                                    </div>
                                </v-expand-transition>
                            </v-col>
                        </v-row>
                        <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column">
                            <v-btn @click="show8 = !show8" text
                                style="color:#2C6B9B; font-weight:800; font-family: Montserrat, sans-serif; font-size:20px">
                                {{ show8 ? 'Fechar ' : 'Veja mais ' }}
                                <svg-icon type="mdi" :path="show8 ? this.mdiArrowUp : this.mdiArrowDown"></svg-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>

            <v-col v-if="this.unidade === 'SÃO JOSÉ DOS CAMPOS'" cols="12" md="12"
                class="align-center justify-center d-flex">
                <div class="tamanhoDiv"
                    style="background-color:#F6FAFF; padding:30px; max-width:800px; border-radius:30px; box-shadow: 10px 5px 5px #8D8D8D; width:800px;">
                    <v-row>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <v-img contain style="max-width:250px;" src="../assets/examesNovos/raiox.webp"></v-img>
                        </v-col>
                        <v-col cols="12" md="6" class="align-center  justify-center flex-column">
                            <h1 class="title1Mob " style="
                                color: #5190BF;
                                font-family: Montserrat, sans-serif;
                                font-size: 45px;
                                margin-bottom:20px;
                                letter-spacing: 2px;
                                line-height: 1;
                                ">
                                Raio-x
                            </h1>
                            <v-btn @click="() => {
                                this.$root.$emit('show-whats');
                            }" text
                                style="color:#fff; font-weight:500; font-family: Montserrat, sans-serif; background-color:#0965AB; border-radius:20px">
                                Agendar exame
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>

            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                <div class="tamanhoDiv"
                    style="background-color:#F6FAFF; padding:30px; max-width:800px; border-radius:30px; box-shadow: 10px 5px 5px #8D8D8D; width:800px;">
                    <v-row>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <v-img contain style="max-width:250px;" src="../assets/examesNovos/biopsia.webp"></v-img>
                        </v-col>
                        <v-col cols="12" md="6" class="align-center  justify-center flex-column">
                            <h1 class="title1Mob" style="
                                color: #5190BF;
                                font-family: Montserrat, sans-serif;
                                font-size: 45px;
                                margin-bottom:20px;
                                letter-spacing: 2px;
                                line-height: 1;
                                ">
                                Biopsia
                            </h1>
                            <v-btn @click="() => {
                                this.$root.$emit('show-whats');
                            }" text
                                style="color:#fff; font-weight:500; font-family: Montserrat, sans-serif; background-color:#0965AB; border-radius:20px">
                                Agendar exame
                            </v-btn>
                        </v-col>

                    </v-row>
                </div>
            </v-col>

            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                <div class="tamanhoDiv"
                    style="background-color:#F6FAFF; padding:30px; max-width:800px; border-radius:30px; box-shadow: 10px 5px 5px #8D8D8D;">
                    <v-row>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <v-img v-if="this.unidade === 'SÃO JOSÉ DOS CAMPOS'" contain style="max-width:250px;"
                                src="../assets/examesNovos/exameimg.webp"></v-img>
                            <v-img v-else contain style="max-width:250px;"
                                src="../assets/examesNovos/ultrassom2.webp"></v-img>
                        </v-col>
                        <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column">
                            <h1 class="title1Mob alinhadoM" style="
                                color: #5190BF;
                                font-family: Montserrat, sans-serif;
                                font-size: 45px;
                                letter-spacing: 2px;
                                line-height: 1;
                                ">
                                Exames por imagem
                            </h1>
                        </v-col>

                        <v-row>
                            <v-col cols="12" md="12" class="align-center justify-center d-flex">
                                <v-expand-transition>
                                    <div v-show="show11">
                                        <v-col cols="12" md="12"
                                            class="align-center justify-center d-flex flex-column paddingCol">
                                            <v-divider></v-divider>
                                            <v-card v-for="(itens, i) in porImg" :key="i"
                                                class="align-center d-flex tamanhoCard" style="
                                    margin:5px 0px;
                                    max-height:100px;
                                    max-width:500px;
                                    width:500px;
                                    /* min-width:700px; */
                                    border-radius:20px;
                                    padding:20px">
                                                <v-row>
                                                    <!-- <v-col cols="4" md="4" class="align-center justify-center d-flex">
                                            <v-img contain style="max-width:50px;" :src="itens.icone"></v-img>
                                        </v-col> -->
                                                    <v-col cols="6" md="6" class="align-center  d-flex">
                                                        <p class="fontCardMb"
                                                            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 10px;">
                                                            {{ itens.nome }}
                                                        </p>
                                                    </v-col>
                                                    <v-col cols="6" md="6" class="align-center d-flex">
                                                        <v-btn class="mgLeftBotao" @click="abrirDialogWhats()" style="
                                            background-color:#FF4B4B;
                                            color:#fff;
                                            font-weight:800;
                                            margin-left:76px;
                                            text-transform: none;
                                            padding:5px;
                                            border-radius:5px">
                                                            Saiba mais</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </div>
                                </v-expand-transition>
                            </v-col>
                        </v-row>
                        <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column">
                            <v-btn @click="show11 = !show11" text
                                style="color:#2C6B9B; font-weight:800; font-family: Montserrat, sans-serif; font-size:20px">
                                {{ show11 ? 'Fechar ' : 'Veja mais ' }}
                                <svg-icon type="mdi" :path="show11 ? this.mdiArrowUp : this.mdiArrowDown"></svg-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
@media (min-device-width: 360px) and (max-device-width: 640px) {
    .padMob {
        padding: 10px 10px !important;
    }

    .paddingCol {
        padding: 4px !important;
    }

    .mgLeftBotao {
        margin-left: 30px !important;
    }

    .tamanhoCard {
        width: 308px !important
    }

    .fontCardMb {
        font-size: 15px !important;
    }

    .tamanhoAuditivo {
        font-size: 11px !important
    }

    .tamanhoDiv {
        max-width: 343px !important;
    }

    .padHeghtCard {
        max-width: auto !important;
        width: auto !important;
        max-height: auto !important;
        height: auto !important;
        padding: 20px !important
    }

    .flexCard {
        flex-direction: column !important;
    }

    .pdlfBtn {
        padding-left: 90px !important;
    }

    .botaoMobile {
        font-size: 20px !important;
        margin-left: 0px !important;
        padding: 10px !important;
    }

    .bgc1 {
        background-image: url("../assets/exameMobile.webp") !important;
    }

    .title1Mob {
        font-size: 30px !important;
    }
}

.bgc1 {
    background-image: url("../assets/examesNovos/topo.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    height: 80vh;
}

.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: 0.8;
    position: absolute;
    width: 100%;
}

:deep(.v-dialog) {
    width: 800px;
    max-height: 900px !important;
}

.v-responsive__sizer {
    padding-bottom: 120.444%;
}

.fundao {
    background-image: url("../assets/fundaoBranco.webp");
    background-size: contain;
    background-repeat: repeat;
}
</style>
    
<script>
import { mdiArrowUp, mdiArrowDown } from '@mdi/js';
import SvgIcon from '@jamescoyle/vue-icon';
export default {
    end: '',
    name: "exame",
    metaInfo: {
        title: "Exames",
        meta: [
            {
                name: "Exames",
                content: "Agende seus exames laboratoriais de rotina, e exames de imagem - Preços Acessíveis",
            },
            // outras meta tags
        ],
    },
    components: {
        SvgIcon
    },

    data: () => ({
        mdiArrowDown,
        mdiArrowUp,
        unidade: '',
        show1: false,
        show2: false,
        show3: false,
        show4: false,
        show5: false,
        show6: false,
        show7: false,
        show8: false,
        show9: false,
        show10: false,
        show11: false,
        cardio: false,
        ofta: false,
        // ultrassom: false,
        neuro: false,
        // auditivo: false,
        femini: false,
        img: false,
        exame: false,
        laboratoriais: [
            {
                nome: 'Hemograma Completo',
                icone: require('../assets/dentist/canal.webp')
            },
            {
                nome: 'Colesterol total e frações',
                icone: require('../assets/dentist/restauracao.webp')
            },
            {
                nome: 'Glicose',
                icone: require('../assets/dentist/limpeza.webp')
            },
            {
                nome: 'TGO/TGP',
                icone: require('../assets/dentist/extracao.webp')
            },
            {
                nome: 'TGO/TGP',
                icone: require('../assets/dentist/limpeza.webp')
            },
            {
                nome: 'Insulina',
                icone: require('../assets/dentist/limpeza.webp')
            },
            {
                nome: 'HIV',
                icone: require('../assets/dentist/limpeza.webp')
            },
            {
                nome: 'Uréia',
                icone: require('../assets/dentist/limpeza.webp')
            },
            {
                nome: 'PSA',
                icone: require('../assets/dentist/limpeza.webp')
            },
            {
                nome: 'Beta HCG',
                icone: require('../assets/dentist/limpeza.webp')
            },
            {
                nome: 'Sífilis',
                icone: require('../assets/dentist/limpeza.webp')
            },
            {
                nome: 'Hepatite',
                icone: require('../assets/dentist/limpeza.webp')
            },
            {
                nome: 'RAST',
                icone: require('../assets/dentist/limpeza.webp')
            },
            {
                nome: 'Vitamina B, C, D',
                icone: require('../assets/dentist/limpeza.webp')
            },
        ],
        cardiologicos: [
            { nome: 'Mapa e Holter 24h', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Ecocardiograma', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Teste Ergimétrico', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Eletrocardiograma', icone: require('../assets/dentist/canal.webp') },
        ],
        oftamologicos: [
            { nome: 'Topografia de Córnea', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Retinografia', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Paquimetria Ultrassônica', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Tonometria', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Yang Laser', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Mapeamento Retina', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Retinografia com Redfree', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Gonioscopia', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Campimetria Computadorizada', icone: require('../assets/dentist/canal.webp') },
        ],
        oftamologicosSorocaba: [
            { nome: 'Topografia de Córnea', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Retinografia', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Paquimetria Ultrassônica', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Tonometria', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Yang Laser', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Mapeamento Retina', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Retinografia com Redfree', icone: require('../assets/dentist/canal.webp') },
            // { nome: 'Gonioscopia', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Campimetria Computadorizada', icone: require('../assets/dentist/canal.webp') },
        ],
        ultrassom: [
            { nome: 'Abdomem total com e sem Doppler', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Articulações', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Partes Moles', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Próstata', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Fígado', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Rins', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Vias urinárias', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Tireóide com e sem Doppler', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Obstétrico pélvico', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Transvaginal Morfológico', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Carótidas e vertebrais', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Mamas', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Venoso', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Arterial dos membros', icone: require('../assets/dentist/canal.webp') },
        ],
        ultrassomSorocaba: [
            { nome: 'Abdomem total com e sem Doppler', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Articulações', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Partes Moles', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Próstata', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Fígado', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Rins', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Vias urinárias', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Tireóide com e sem Doppler', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Obstétrico pélvico', icone: require('../assets/dentist/canal.webp') },
            // { nome: 'Transvaginal Morfológico', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Carótidas e vertebrais', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Mamas', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Venoso', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Arterial dos membros', icone: require('../assets/dentist/canal.webp') },
        ],
        neurologicos: [
            { nome: 'Eletroencefalograma com mapeamento', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Eletroneuromigrafia', icone: require('../assets/dentist/canal.webp') },
        ],
        auditivos: [
            { nome: 'Audiometria Vocal e Tonal', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Treinamento auditivo', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Vectoeletronistagmografia', icone: require('../assets/dentist/canal.webp') },
        ],
        feminino: [
            { nome: 'Papanicolau/ Preventivo', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Mamografia', icone: require('../assets/dentist/canal.webp') },
        ],
        femininoSorocaba: [
            { nome: 'Papanicolau/ Preventivo', icone: require('../assets/dentist/canal.webp') },
            // { nome: 'Mamografia', icone: require('../assets/dentist/canal.webp') },
        ],
        porImg: [
            { nome: 'Colonoscopia ', icone: require('../assets/dentist/canal.webp') },
            { nome: 'Endoscopia', icone: require('../assets/dentist/canal.webp') },
        ],
        items: ['Ultrassom', 'Hemograma completo', 'Coagulograma', 'Colesterol total e frações'],
        cards: [
            {
                titulo: "Exames cardiológicos",
                imagem: require("../assets/exameNovo/template.webp"),
            },
            {
                titulo: "Exames Oftamológicos",
                imagem: require("../assets/exameNovo/oftal.webp"),
            },
            {
                titulo: "Exames Laboratoriais",
                imagem: require("../assets/exameC.webp"),
            },
            {
                titulo: "Ultrassom Doppler",
                imagem: require("../assets/ultrassomC.webp"),
            },
            {
                titulo: "Exames Neurológicos",
                imagem: require("../assets/exameNovo/neuro.webp"),
            },
            {
                titulo: "Exames Auditivos",
                imagem: require("../assets/exameNovo/auditivo.webp"),
            },
            {
                titulo: "Gastro",
                imagem: require("../assets/exameNovo/gastro.webp"),
            },
            {
                titulo: "Feminino",
                imagem: require("../assets/exameNovo/femini.webp"),
            },
            {
                titulo: "Raio X",
                imagem: require("../assets/exameNovo/raiox.webp"),
            },
            {
                titulo: "Biopsia",
                imagem: require("../assets/exameNovo/biops.webp"),
            },
            {
                titulo: "Exames por Imagem",
                imagem: require("../assets/exameNovo/porimg.webp"),
            },
        ],
    }),

    computed: {
        intBreakPoint() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return 0;
                case "sm":
                    return 1;
                case "md":
                    return 2;
                case "lg":
                    return 3;
                case "xl":
                    return 4;
                default:
                    return 0;
            }
        },
        end() {
            return this.$store.getters.unidade;
        },
    },

    methods: {
        abrirDialogWhats() {
            this.$root.$emit("show-whats")
        },

        goto(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;
            window.scrollTo(0, top);
        },
    },

    mounted() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        this.unidade = this.$store.getters.unidade.cidade;
    },
}
</script>