<template>
  <div>
    <v-img class="wdImage" :height="height" :width="width" @click="drawDot" :content-class="name" :src="src"
      position="left">
    </v-img>
    <v-dialog v-model="dialog2">
      <v-card class="align-center justify-center flexCard d-flex">
        <svg-icon type="mdi" :path="mdiClose" style="height: 50px; width: 50px;"></svg-icon>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob"
            style="color: #4490a3; font-family: poppins, sans-serif; font-size: 45px; letter-spacing: 2px; line-height: 1.2; word-break: none;">
            {{ items[indexDialog].titulo }}
          </h1>
          <p style="margin-bottom: 0px !important; font-family: poppins, sans-serif; color: #000; font-size: 18px;"
            v-html="items[indexDialog].texto">

          </p>
          <v-btn class="botaoMobile titleMobImpla" @click="() => {
              this.$root.$emit('show-whats');
            }
            " style="border:2px solid #4490a3; color:#4490a3; background-color:transparent; font-family: poppins, sans-serif; font-weight:400; font-size:20px; 
                margin-top:30px; box-shadow: none; border-radius:50px">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img :src="items[indexDialog].imagem" width="250">
        </v-col>
      </v-card>
    </v-dialog>

  </div>
</template>
  
<style>
@media (min-device-width: 360px) and (max-device-width: 1024px) {
  .padMobile {
    padding: 40px 10px !important;
  }

  .flexCard {
    flex-direction: column !important;
  }

  .titleMobImpla {
    font-size: 15px !important
  }

  .title1Mob {
    font-size: 20px !important;
  }

  .dot {
    width: 30px !important;
    height: 30px !important;
    background-color: #3300FF77;
    border-radius: 300px;
    border: 2px solid #fff;
    position: absolute;
  }
}

.v-step {
  background-color: #ffffffbd;
  border-radius: 0px 20px 20px 20px;
  padding: 20px;
  font-family: poppins, sans-serif;
  box-shadow: 0px 3px 6px 1px rgb(0 0 0 / 47%);
  border: 1px solid #7e090973;
  font-weight: 500;
}

/* .v-dialog {
    width: auto !important;
    background-color: transparent !important;
    box-shadow: none !important;
  } */

.dot {
  width: 50px;
  height: 50px;
  background-color: #3300FF77;
  border-radius: 300px;
  border: 2px solid #fff;
  position: absolute;
}

.dot:hover {
  cursor: pointer;
}
</style>
  
<script>
import { mdiClose } from '@mdi/js';
import SvgIcon from '@jamescoyle/vue-icon';

export default {
  name: "ImgDots",
  components: {
    SvgIcon
  },
  props: {
    name: String,
    dots: Array,
    items: Array,
    src: String,
    height: String,
    width: String,
  },
  data: () => ({
    mdiClose: mdiClose,
    clickable: false,
    dialog2: false,
    loading: false,
    end: '',
    imgDialog: "",
    indexDialog: 0,
    steps: [
      {
        target: "#v-step-2",
        header: {
          title: "",
        },
        content: `Clique sobre os pontos para acessar a parte desejada no cardápio`,
      },
    ],
    myOptions: {
      useKeyboardNavigation: false,
      labels: {
        buttonSkip: "Skip tour",
        buttonPrevious: "Previous",
        buttonNext: "Next",
        buttonStop: "Fechar",
      },
    },
  }),
  mounted() {
    if (this.dots.length > 0) {
      this.dots.forEach((element, index) => {
        this.drawDot({
          index: index,
          layerX: element.x,
          layerY: element.y,
          img: element.img,
        });
      });
      // this.$tours["myTour"].start();
    }

  },

  computed: {

    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 3;
        case "xl":
          return 4;
        default:
          return 0;
      }
    },
  },

  methods: {

    drawDot(event) {
      if (event.isTrusted && !this.clickable) {
        return "";
      }
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      var height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      let mouseX = event.isTrusted
        ? (100 * event.layerX) / width
        : event.layerX;
      let mouseY = event.isTrusted
        ? (100 * event.layerY) / height
        : event.layerY;

      // console.log(mouseX + ' ' + mouseY);
      if (this.clickable) {
        this.dots.push({ x: mouseX, y: mouseY });
      }
      // onmouseover
      let div = document.createElement("div");
      div.id = "v-step-" + event.index;
      div.classList.add("dot");
      div.style.top = mouseY + "%";
      div.style.left = mouseX + "%";
      div.onclick = () => {
        //   this.imgDialog = event.img;
        this.indexDialog = event.index
        this.dialog2 = true;
      };
      document.getElementsByClassName(this.name)[0].appendChild(div);
    },

    base() {
      return 16;
    },
    ptr(px) {
      var x = this.base() || 16,
        rem = (1 / x) * px;

      return rem;
    },
  },
};
</script>
  