<template>
  <v-row class="blog-section">
    <v-col class="fixed-articles" v-for="(article, i) in firstArticles(5)" :key="i" :cols="i === 4 ? 12 : 6"
      :sm="i === 4 ? 8 : 4" :md="i in [1, 1] ? '3' : '6'">
      <v-card class="card-article" @click="pushArticle(article)">
        <v-img cover class="card-article-img" :src="`https://blogs.abare.cloud/assets/${article.imagem.filename_disk}`">
          <div class="card-film"></div>
        </v-img>
        <h2 class="card-article-title">
          {{ limitCharacters(article.titulo, 60) }}
        </h2>
      </v-card>
    </v-col>
    <v-col cols="12" class="last-articles-container">
      <h1 class="section-title">Últimos artigos</h1>
      <v-row class="last-articles-section">
        <v-col sm="9" md="8" class="article-list">
          <v-card class="article-list-card" v-for="(article, i) in articles" :key="i" @click="pushArticle(article)">
            <img class="article-list-img" v-if="article.imagem"
              :src="`https://blogs.abare.cloud/assets/${article.imagem.filename_disk}`" />
            <div class="article-list-container">
              <div class="article-content">
                <h2 class="article-list-title">{{ article.titulo }}</h2>
                <div class="cta-container d-flex align-center">
                  <a class="card-article-cta">Leia Mais&#187;</a>
                </div>
              </div>
              <div class="card-article-info">
                <p>{{ article.date_created }}</p>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3" offset-md="1" class="articles-categories-container">
          <div class="articles-categories">
            <h2 class="articles-categories-title">Categorias</h2>
            <h3 class="article-categorie" @click="filterArticles()">Todas</h3>
            <h3 class="article-categorie" v-for="(categorie, i) in this.categories" :key="i"
              @click="filterArticles(categorie)">
              {{ categorie }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from "dayjs";
import axios from "axios";

export default {
  metaInfo: {
    title: "Blog",
    meta: [
      {
        name: "Blog",
        content: "Dicas de saúde pra você",
      },
    ],
  },
  data: () => ({
    allArticles: [],
    articles: [],
    categories: [],
  }),
  methods: {
    pushArticle(article) {
      const title = article.titulo;
      const pattern = /[\p{P}\p{Z}]+/gu;
      const removePonctuation = title.replace(pattern, "-").toLowerCase();
      const path = removePonctuation
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      this.$router.push(`/blog/${article.id}/${path}`);
    },

    async getBlogs() {
      const { data } = await axios.get(
        `https://blogs.abare.cloud/items/pro_clinica_artigos?fields=*.*&filter[status][_eq]=published&filter[unidades][_contains]=${this.end.idName}`
      );
      console.log(data)
      this.articles = this.allArticles = data.data.sort((a, b) => {
        return new Date(b.date_created) - new Date(a.date_created);
      });
      for (let i = 0; i < this.articles.length; i++) {
        this.articles[i].date_created = dayjs(
          this.articles[i].date_created
        ).format("DD-MM-YYYY HH:mm:ss");
      }
      this.populateCategories();
    },

    firstArticles(limit) {
      return this.allArticles.slice(0, limit);
    },

    populateCategories() {
      let getCategories = [];
      this.articles.forEach((article) => {
        getCategories.push(article.categoria.nome_categoria);
      });
      this.categories = getCategories.filter(
        (value, index) => getCategories.indexOf(value) === index
      );
    },

    filterArticles(categorie) {
      this.articles = this.allArticles;
      if (categorie) {
        const filteredArticles = this.articles.filter((article) => {
          return article.categoria.nome_categoria === categorie;
        });
        return (this.articles = filteredArticles);
      }
    },

    limitCharacters(string, maxLength) {
      if (string.length <= maxLength) return string;
      const lastSpaceIndex = string.lastIndexOf(" ", maxLength);
      if (lastSpaceIndex === -1) return string.slice(0, maxLength) + "...";
      return string.slice(0, lastSpaceIndex) + "...";
    },
  },
  mounted() {
    this.getBlogs();
  },
  computed: {
    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 0;
        case 'sm':
          return 1;
        case 'md':
          return 2;
        case 'lg':
          return 3;
        case 'xl':
          return 4;
        default:
          return 0;
      }
    },

    end() {
      return this.$store.getters.unidade;
    },
  },
};
</script>

<style>
.blog-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15%;
  margin-top: 1em;
  padding-top: 2em !important;
  padding-bottom: 6em !important;
  background-image: url("../assets/fundofluxo.webp");
  background-repeat: repeat;
}

.card-article {
  height: 100% !important;
  max-height: 20vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 1px !important;
}

.card-film {
  height: 100% !important;
  width: 100%;
  background-color: #002c5083;
  opacity: 0.9;
}

.card-article-title {
  position: absolute;
  bottom: 10px;
  text-align: justify;
  padding: 0 2vw;
  font-family: poppins, sans-serif, sans-serif;
  line-height: 1.3;
  font-weight: 500;
  font-size: clamp(14px, 1vw, 23px);
  color: #fff;
  margin-top: 1em;
  text-shadow: 3px 4px 7px rgba(81, 67, 21, 0.8);
}

.card-article-img {
  max-width: 100%;
  aspect-ratio: 1.78;
  object-fit: cover;
  transition: 0.5s ease;
}

.card-article-img:hover {
  max-width: 120%;
  height: 100%;
  margin-left: -10%;
  transition: 0.5s ease;
}

.section-title {
  font-family: Montserrat, sans-serif, sans-serif;
  font-size: clamp(28px, 2.4vw, 36px);
  margin: 1em 0;
  color: #5190bf;
}

.article-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.article-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0 2%;
}

.article-list-card {
  width: 49%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
}

.article-list-img {
  max-width: 100%;
  aspect-ratio: 1.78;
  object-fit: cover;

}

.article-list-title {
  font-family: poppins, sans-serif, sans-serif;
  line-height: 1.3;
  font-weight: 500;
  font-size: clamp(18px, 1.3vw, 18px);
  margin: 1em 0 0.5em 0;
}

.card-article-cta {
  color: #5190bf !important;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 1em;
}

.card-article-cta:hover {
  text-decoration: underline;
}

.card-article-info {
  min-height: 3rem;
  display: flex;
  align-items: center;
  color: #8fbbdb;
  border-top: 1px outset #adadad;
}

.card-article-info p {
  margin-bottom: 0;
}

.articles-categories-container {
  padding: 0;
  padding-top: 12px;
}

.articles-categories {
  min-height: 230px;
  width: 100%;
  background-color: #bdcedc66;
  opacity: 0.9;
  border-radius: 3px;
  padding: 10px;
  position: sticky;
  top: 10px;
}

.articles-categories-title {
  font-family: Montserrat, sans-serif, sans-serif;
  font-weight: 500;
  font-size: clamp(22px, 1.7vw, 30px);
  margin-bottom: 1em;
  color: #5190bf;
  padding-left: 5px;
}

.article-categorie {
  color: #2c2a2a;
  font-size: clamp(18px, 1.3vw, 28px);
  font-weight: 400;
  line-height: 2;
  cursor: pointer;
}

.article-categorie:hover {
  text-decoration: underline;
}

@media (max-width: 960px) {
  .blog-section {
    padding: 0 2%;
  }

  .fixed-articles {
    padding: 8px;
  }

  .card-article-img {
    max-width: fit-content;
    position: static;
  }

  .card-article-title {
    text-align: left;
  }

  .last-articles-section {
    display: flex;
    flex-direction: column-reverse;
  }

  .article-list {
    justify-content: center;
  }

  .article-list-card {
    width: 95%;
    min-height: unset;
  }

  .article-list-img {
    object-fit: cover;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .blog-section {
    padding: 0 5%;
  }

  .last-articles-container {
    padding: 0;
  }

  .last-articles-section {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }
}

@media (min-width: 1800px) {
  .blog-section {
    padding: 0 20%;
  }

  .card-article-img {
    max-width: 120%;
  }

}
</style>
