<template>
	<v-container fluid>
		<v-row class="bgc4" align="center" :style="intBreakPoint >= 2 ? 'padding: 40px 60px' : 'padding: 1.5rem'">
			<v-col cols="12" md="6">
				<v-img :src="end.logoCinza" style="width: 250px"></v-img>
				<h1 class="mt-5" style="
						line-height: 1.2;
						font-family: Montserrat !important;
						font-weight: 800;
						text-transform: uppercase;
						color: #0659a1;
					" v-if="intBreakPoint >= 2">
					Seu coração cuida <br />
					do seu corpo todo, <br />
					Cuide bem dele
				</h1>
				<h1 class="mt-5" style="
						line-height: 1.2;
						font-family: Montserrat !important;
						font-weight: 800;
						text-transform: uppercase;
						font-size: 1.2rem;
						color: #0659a1;
					" v-else>
					Seu coração cuida <br />
					do seu corpo todo, <br />
					Cuide bem dele
				</h1>
				<h1 class="mt-5" style="
						font-size: 1.2rem !important;
						line-height: 1.2;
						font-family: Montserrat !important;
						font-weight: 800;
						color: #707070;
					">
					Pró-Clínica, sua Clínica de Cardiologia em {{ end.cidade }}
				</h1>
				<p class="mt-5"
					style="font-family: Montserrat !important; background-color: rgb(255, 255, 255, 0.6); width: fit-content; padding: 1rem; border-radius: 1rem;">
					Nossos cardiologistas estão prontos para te atender <br v-if="intBreakPoint >= 2" />
					com excelência, comprometimento e foco total na
					sua <br v-if="intBreakPoint >= 2" /> qualidade de vida.
				</p>

			</v-col>
			<v-col cols="12" md="12">
				<v-row>
					<v-col cols="12" md="4" class="d-flex align-center flex-column">
						<div class="d-flex align-center justify-center"
							:style="intBreakPoint >= 2 ? 'background-color: #45eb63; border-radius: 2rem; padding: 1rem 2rem; cursor: pointer; gap: 1rem;' : 'background-color: #45eb63; border-radius: 2rem; padding: 1rem; cursor: pointer; gap: 1rem;'"
							@click="abrirDialogWhats">
							<v-icon color="#fff" :size="intBreakPoint >= 2 ? '3rem' : '2rem'">mdi-whatsapp</v-icon>
							<button style="
								background-color: #45eb63;
								color: #fff;
								font-family: Montserrat !important;
								font-weight: 800;
								text-align: start;
								">
								Quero agendar minha
								<br v-if="intBreakPoint >= 2" />
								<span style="text-transform: uppercase">avaliação grátis</span>
							</button>
						</div>
					</v-col>
					<v-col cols="12" md="4" class="d-flex align-center flex-column">
						<v-img style="width: 250px" src="../assets/lpsEspecialidade/Grupo 3.png"></v-img>
					</v-col>
					<v-col cols="12" md="4" class="d-flex align-center flex-column">
						<v-img style="width: 250px" src="../assets/lpsEspecialidade/Grupo 1.png"></v-img>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<v-row class="bgc3" justify="center" align="center">
			<v-col cols="12" md="8" class="d-flex justify-center align-start flex-column">
				<h1 style="
							font-family: Montserrat !important;
							font-weight: 800;
							color: #fff;
							line-height: 1.2;
							font-size: 1.5rem;
							text-transform: uppercase;
						" :class="intBreakPoint < 2 ? 'pt-4' : ''">
					Em dúvida se é o momento <br />
					de consultar um cardiologista?
				</h1>
				<p class="mt-2" style="
						font-family: Montserrat !important;
						color: #fff;
						line-height: 1.2;
					">
					Existem várias situações ou sintomas em que é aconselhável agendar uma consulta com um cardiologista.
					<br><br>
					<b> Confira as principais: </b>
				</p>


				<v-row class="mt-5">
					<v-col cols="12" md="6">
						<div class="d-flex mt-2" style="gap: 1rem">
							<img src="../assets/lpsEspecialidade/oftalmo/icon.png" style="width: 20px; height: 20px">
							<p style=" font-family: Montserrat !important; color: #fff; line-height: 1.2; ">Dor no peito (dor, pressão,
								aperto ou
								desconforto no peito, especialmente se
								for acompanhada de falta de ar)
							</p>
						</div>

						<div class="d-flex mt-2" style="gap: 1rem">
							<img src="../assets/lpsEspecialidade/oftalmo/icon.png" style="width: 20px; height: 20px">
							<p style=" font-family: Montserrat !important; color: #fff; line-height: 1.2; ">Falta de ar inexplicável
								(especialmente
								durante a atividade física ou em repouso)
							</p>
						</div>

						<div class="d-flex mt-2" style="gap: 1rem">
							<img src="../assets/lpsEspecialidade/oftalmo/icon.png" style="width: 20px; height: 20px">
							<p style=" font-family: Montserrat !important; color: #fff; line-height: 1.2; ">Palpitações cardíacas
								(coração batendo
								rápido, irregularmente ou de forma anormal)</p>
						</div>
					</v-col>
					<v-col cols="12" md="6">
						<div class="d-flex mt-2" style="gap: 1rem">
							<img src="../assets/lpsEspecialidade/oftalmo/icon.png" style="width: 20px; height: 20px">
							<p style=" font-family: Montserrat !important; color: #fff; line-height: 1.2; ">Histórico familiar de
								doenças cardíacas
							</p>
						</div>
						<div class="d-flex mt-2" style="gap: 1rem">
							<img src="../assets/lpsEspecialidade/oftalmo/icon.png" style="width: 20px; height: 20px">
							<p style=" font-family: Montserrat !important; color: #fff; line-height: 1.2; ">Fatores de risco cardíaco
								(doenças
								cardíacas
								como hipertensão arterial, diabetes,
								colesterol alto, obesidade, tabagismo ou
								histórico de doença cardíaca prévia)</p>
						</div>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" md="4" class="d-flex align-center justify-center">
				<v-img contain style="width: 70%; max-width: 100%" src="../assets/lpsEspecialidade/cardio/doctor.png"></v-img>
			</v-col>

			<v-col cols="12" md="8" class="d-flex align-center justify-center flex-column">
				<p style="
						font-family: Montserrat !important;
						color: #fff;
						text-align: center;
					">
					Um cardiologista pode ajudar a monitorar sua saúde cardiovascular, fazer avaliações de risco e fornecer
					orientações sobre estilo de vida saudável e medidas preventivas.
				</p>
			</v-col>
		</v-row>

		<v-row :style="intBreakPoint >= 2 ? 'padding: 20px 60px' : ''">
			<v-col cols="12" md="6" class="d-flex flex-column align-center" style="gap: 1rem">
				<h1 style="
							font-family: Montserrat !important;
							font-weight: 800;
							color: #064277;
							line-height: 1.2;
							font-size: 1.5rem;
							text-transform: uppercase;
						">
					Se identificou com algum desses sintomas?
				</h1>

				<p style=" font-family: Montserrat !important; color: #064277; line-height: 1.2; ">
					É hora de agendar sua consulta com um cardiologista em <b> {{ end.cidade }} </b>.
				</p>
			</v-col>
			<v-col cols="12" md="6" class="d-flex flex-column align-center justify-end">
				<v-btn class="newbtnLp" style="padding: 40px" @click="abrirDialogWhats">CLIQUE AQUI E <br> AGENDE SUA
					CONSULTA</v-btn>
			</v-col>
			<v-col cols="12" md="12" class="d-flex justify-center">
				<small style="color: #707070; text-align: center;">*É fundamental lembrar que essas são apenas algumas situações
					ou sintomas que podem indicar a necessidade de consultar um cardiologista. Sempre consulte
					um profissional de saúde qualificado para avaliar e diagnosticar corretamente sua condição e fornecer o
					tratamento adequado.</small>
			</v-col>
		</v-row>

		<v-row class="bgConsulta pt-16 pb-16">
			<v-col cols="12" md="6">
				<v-img src="../assets/lpsEspecialidade/oftalmo/img1.png" contain style="cursor: pointer"
					@click="abrirDialogWhats"></v-img>
			</v-col>
			<v-col cols="12" md="6" class="d-flex flex-column align-center justify-center" style="color: #fff">
				<p :style="intBreakPoint >= 2 ? 'font-size: 1.5rem' : 'font-size: 1.2rem'">Não deixe de cuidar da saúde do seu
					coração por falta de
					tempo, custos altos ou
					consultas com agenda para daqui dois meses.
				</p>
				<p :style="intBreakPoint >= 2 ? 'font-size: 1.5rem' : 'font-size: 1.2rem'">Na Pró-Clínica você pode agendar a sua
					consulta com <b>Cardiologista em {{
						end.cidade
					}}</b> em minutos através do Whatsapp com
					<b>preço justo e datas próximas.</b>
				</p>
			</v-col>
		</v-row>

		<v-row :class="intBreakPoint >= 2 && 'px-16'" :style="intBreakPoint >= 2 && 'margin-top: 5rem'">
			<v-col cols="12" md="12"
				:class="intBreakPoint < 2 ? 'd-flex flex-column align-start justify-center pl-8 pr-8' : 'd-flex flex-column align-start justify-center'">
				<h1 class="textBlue">
					Veja o que um
					<span class="textBlue2">especialista</span>
					tem para dizer
				</h1>

				<div
					:class="intBreakPoint < 2 ? 'd-flex flex-column align-start justify-center pl-8 pr-8' : 'd-flex align-center justify-space-around'"
					style="width: 100%; gap: 2rem; margin-top: 2rem;">
					<div class="d-flex flex-column align-center justify-center" style="width: 100%;">
						<h3 class="textBlue">Sobre a frequência:</h3>
						<video width="100%" :height="intBreakPoint >= 2 ? '600px' : '400px'" controls controlslist="nodownload"
							class="my-8">
							<source src="https://abaremarketing.com/media/proClinica_cardio1.mp4" type="video/mp4">
							Your browser does not support the video tag.
						</video>
					</div>

					<div class="d-flex flex-column align-center justify-center" style="width: 100%;">
						<h3 class="textBlue">Sobre os sinais:</h3>
						<video width="100%" :height="intBreakPoint >= 2 ? '600px' : '400px'" controls controlslist="nodownload"
							class="my-8">
							<source src="https://abaremarketing.com/media/proClinica_cardio2.mp4" type="video/mp4">
							Your browser does not support the video tag.
						</video>
					</div>
				</div>

				<p>
					<span style="font-weight: 800;">Dr. Yanquel Bazan</span><br />
					Cardiologista<br />
					CRM: 137918
				</p>
			</v-col>
		</v-row>
		<v-row class="pa-4">
			<v-col cols="12" md="10" offset-md="1" class="d-flex align-center justify-center">
				<p style="text-align: center; font-size: 1.1rem" class="blueText">Além desses fatores, a frequência ideal de
					consultas rotineiras com
					um cardiologista
					pode variar dependendo
					da idade e do estado geral de saúde de uma pessoa que não tenha problemas cardíacos conhecidos. Aqui está
					uma orientação geral para a frequência das consultas, mas é importante ressaltar que essas recomendações podem
					variar de acordo com as diretrizes médicas específicas e a avaliação individual de um médico:</p>
			</v-col>
		</v-row>

		<v-row class="pa-4">
			<v-col cols="12" md="3" class="d-flex flex-column align-start justify-center">
				<h1 style="font-weight: 800; text-transform: uppercase;" class="blueText">ADULTOS JOVENS</h1>
				<p style="font-weight: 800; text-transform: uppercase;" class="blueText">(Idade entre 18 e 30 anos)</p>
			</v-col>
			<v-col cols="12" md="9" style="border-top: 1px solid #064277;" :class="intBreakPoint >= 2 ? 'mt-16' : ''">
				<p class="ml-4 mr-4 blueText">Para adultos jovens sem fatores de risco significativos para doenças cardíacas,
					é recomendado fazer uma consulta com um cardiologista a cada 2 a 4 anos.</p>
			</v-col>
		</v-row>

		<v-row class="pa-4">
			<v-col cols="12" md="3" class="d-flex flex-column align-start justify-center">
				<h1 style="font-weight: 800; text-transform: uppercase; line-height: 1;" class="blueText">Adultos de meia-idade
				</h1>
				<p style="font-weight: 800; text-transform: uppercase; font-size: 0.8rem;" class="blueText">(Idade entre 30 e 50
					anos)</p>
			</v-col>
			<v-col cols="12" md="9" style="border-top: 1px solid #064277;" :class="intBreakPoint >= 2 ? 'mt-16' : ''">
				<p class="ml-4 mr-4 blueText">A cada 1 a 2 anos, especialmente se houver fatores de risco cardiovascular, como
					histórico familiar de doenças cardíacas, hipertensão arterial, diabetes, colesterol alto,
					tabagismo e obesidade.</p>
			</v-col>
		</v-row>

		<v-row class="pa-4">
			<v-col cols="12" md="3" class="d-flex flex-column align-start justify-center">
				<h1 style="font-weight: 800; text-transform: uppercase; line-height: 1;" class="blueText">Adultos mais velhos
				</h1>
				<p style="font-weight: 800; text-transform: uppercase; font-size: 0.8rem;" class="blueText">(Idade acima de 50
					anos)</p>
			</v-col>
			<v-col cols="12" md="9" style="border-top: 1px solid #064277;" :class="intBreakPoint >= 2 ? 'mt-16' : ''">
				<p class="ml-4 mr-4 blueText">Anualmente ou a cada 6 meses, dependendo do estado de saúde e das recomendações
					médicas individuais.s</p>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" md="10" offset-md="1" class="pa-8 d-flex align-center justify-center">
				<small style="text-align: center">*Essas recomendações são gerais e podem variar para cada pessoa. Se você tiver
					dúvidas sobre a frequência
					ideal de consultas com um cardiologista com base em
					sua idade e histórico de saúde, é recomendado consultar um médico para obter orientações personalizadas.</small>
			</v-col>
		</v-row>

		<v-row style="background-color: #f5f5f5">
			<v-col cols="12" md="12" class="d-flex align-center justify-center">
				<a href="https://abaremarketing.com/" target="_blank">
					<v-img style="max-width: 3rem" src="../assets/abareLogoPreto.webp" contain></v-img>
				</a>
				<router-link to="/">
					<v-img style="max-width: 8rem" :src="end.logoPreto" contain></v-img>
				</router-link>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'Odontologia',

	data() {
		return {};
	},
	methods: {
		abrirDialogWhats() {
			this.$root.$emit('show-whats');
		},
	},
	computed: {
		intBreakPoint() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 0;
				case 'sm':
					return 1;
				case 'md':
					return 2;
				case 'lg':
					return 3;
				case 'xl':
					return 4;
				default:
					return 0;
			}
		},

		end() {
			return this.$store.getters.unidade;
		},
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style scoped>
@media (max-width: 600px) {
	.bgc3 {
		background-image: url('../assets/lpsEspecialidade/cardio/bgMb2.png') !important;
		/* height: 100vh; */
		background-size: cover !important;
		background-position: center;
		padding: 0 !important;
	}

	.bgc4 {
		background-image: url('../assets/lpsEspecialidade/cardio/bgHeaderMb.png') !important;
		width: 100vw !important;
		background-size: cover !important;
		background-position: center !important;
	}
}

.newbtnLp {
	background: rgb(130, 193, 249) !important;
	background: linear-gradient(273deg, rgba(130, 193, 249, 1) 0%, rgba(23, 124, 214, 1) 100%) !important;
	color: #fff !important;
	font-weight: 800 !important;
	font-family: Montserrat !important;
	letter-spacing: normal !important;
	font-size: 1.2rem !important;
}

.bgConsulta {
	background-image: url("../assets/lpsEspecialidade/oftalmo/banner3.png") !important;
	min-height: 100vh;
	background-size: cover !important;
	background-position: center;
}

.blueText {
	color: #064277;
}

.btnLp {
	background-color: #fff;
	color: #064277;
	font-family: Montserrat !important;
	font-weight: 800;
	border-radius: 50px;
	letter-spacing: normal;
	font-size: 1.2rem;
}

.efeito-vidro {
	background: rgba(255, 255, 255, 0.35);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(13.5px);
	-webkit-backdrop-filter: blur(9.5px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
}

.bgc3 {
	background-image: url('../assets/lpsEspecialidade/Grupo 5.png') !important;
	/* height: 100vh; */
	background-size: cover !important;
	background-position: center;
	padding: 20px 60px;
}

.bgc4 {
	background-image: url('../assets/lpsEspecialidade/cardio/bgBanner.png') !important;
	min-height: 100vh;
	background-size: cover !important;
	background-position: center;
}

.textBlue {
	color: #085A9E !important;
}

.textBlue2 {
	color: #8FCCFF;
}
</style>
