<template>
	<v-container fluid>
		<v-row class="bgc4" align="center" :style="intBreakPoint >= 2 ? 'padding: 40px 60px' : 'padding: 1.5rem'">
			<v-col cols="12" md="6">
				<v-img :src="end.logo" style="width: 250px"></v-img>
				<h1 class="mt-5" style="
						line-height: 1.2;
						font-family: Montserrat;
						font-weight: 800;
						color: #0659a1;
					">
					Cuidar do seu sorriso é <br />
					cuidar da sua felicidade
				</h1>
				<p class="mt-5" style="font-family: Montserrat">
					Nossos médicos dentistas estão prontos para te atender
					<br />
					com excelência, comprometimento e foco total na sua <br />
					qualidade de vida.
				</p>
				<h1 class="mt-5" style="
						font-size: 1.7rem !important;
						line-height: 1.2;
						font-family: Montserrat;
						font-weight: 800;
						color: #707070;
					">
					Pró-Clínica, sua Clínica Odontológica <br />
					em {{ end.cidade }}
				</h1>
			</v-col>
			<v-col cols="12" md="12">
				<v-row>
					<v-col cols="12" md="4" class="d-flex align-center flex-column">
						<button style="
								background-color: #45eb63;
								color: #fff;
								font-family: Montserrat;
								width: 250px;
								height: 5.5rem;
								padding: 0.5rem 1rem;
								border-radius: 2rem;
								font-weight: 800;
								text-align: ;
							" @click="abrirDialogWhats">
							<v-icon>mdi-whatsapp</v-icon> Quero agendar minha <br />
							<span style="text-transform: uppercase">avaliação grátis</span>
						</button>
					</v-col>
					<v-col cols="12" md="4" class="d-flex align-center flex-column">
						<v-img style="width: 250px" src="../assets/lpsEspecialidade/Grupo 1.png"></v-img>
					</v-col>
					<v-col cols="12" md="4" class="d-flex align-center flex-column">
						<v-img style="width: 250px" src="../assets/lpsEspecialidade/Grupo 3.png"></v-img>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<v-row class="bgc3">
			<v-col cols="12" md="12" class="d-flex align-center justify-center">
				<div :style="intBreakPoint >= 2
						? 'background-color: #fff; border-radius: 50px; padding: 5px 20px'
						: 'background-color: #fff; border-radius: 25px; padding: 5px 20px'
					">
					<h1 style="
							font-family: Montserrat;
							font-weight: 800;
							color: #064277;
							line-height: 1.2;
							font-size: 1.5rem;
						">
						Em dúvida se você deve marcar uma consulta com um dentista hoje?
					</h1>
				</div>
			</v-col>
			<v-col cols="12" md="6" class="d-flex justify-center flex-column">
				<p style="font-family: Montserrat; color: #fff; line-height: 1.2">
					Não se preocupe, agende uma <b> Avaliação grátis </b> por nossa conta!
					<br /><br />

					Existem várias situações ou sintomas em que é aconselhável agendar uma
					consulta com um dentista.

					<b> Confira as principais: </b>
				</p>

				<div :style="intBreakPoint >= 2 ? 'width: 35vw' : 'width: 100%'" class="efeito-vidro">
					<v-list dense color="transparent">
						<v-list-item-group color="primary">
							<v-list-item style="color: #fff !important">
								<v-icon color="#fff">mdi-check</v-icon>
								<v-list-item-content>
									<v-list-item-title style="font-family: Montserrat">Dor de dente persistente por dois
										<br v-if="intBreakPoint < 2" />
										ou mais dias
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item style="color: #fff !important">
								<v-list-item-content>
									<v-list-item-title style="font-family: Montserrat">Sensibilidade dental ou desgaste do
										<br v-if="intBreakPoint < 2" />
										esmalte dental
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item style="color: #fff !important">
								<v-list-item-content>
									<v-list-item-title style="font-family: Montserrat">Gengivas doloridas, inchadas, vermelhas<br
											v-if="intBreakPoint < 2" />
										ou sangrando
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item style="color: #fff !important">
								<v-list-item-content>
									<v-list-item-title style="font-family: Montserrat">Mau hálito persistente</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item style="color: #fff !important">
								<v-list-item-content>
									<v-list-item-title style="font-family: Montserrat">Lesões orais que não cicatrizam
										<br v-if="intBreakPoint < 2" />(como manchas, inchaços,
										<br v-if="intBreakPoint >= 2" />
										etc)
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item style="color: #fff !important">
								<v-list-item-content>
									<v-list-item-title style="font-family: Montserrat">
										Dentes quebrados ou restaurações
										<br v-if="intBreakPoint < 2" />
										danificadas
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item style="color: #fff !important">
								<v-list-item-content>
									<v-list-item-title style="font-family: Montserrat">
										Problemas de mordida incorreta,
										<br v-if="intBreakPoint < 2" />
										desalinhamento dos dentes<br />
										ou espaçamento anormal
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</div>
			</v-col>
			<v-col cols="12" md="6" class="d-flex align-center justify-center">
				<v-img contain style="width: 70%; max-width: 100%" src="../assets/lpsEspecialidade/odonto/Odonto 3.png"></v-img>
			</v-col>

			<v-col cols="12" md="12" class="d-flex align-center justify-center flex-column">
				<p style="font-family: Montserrat; color: #fff; text-align: center">
					Se identificou com um ou mais sintomas? É hora de agendar sua consulta
					com um dentista em
					<b> {{ end.cidade }}</b> <br /><br />
					Além desses fatores, é sempre uma boa ideia consultar um dentista
					regularmente para
					<b> exames de rotina e limpezas </b>, mesmo na ausência de sintomas
					aparentes. O dentista pode detectar problemas em estágios iniciais e
					fornecer orientações sobre cuidados preventivos para manter uma boa
					saúde bucal.
				</p>

				<v-btn class="btnLp" @click="abrirDialogWhats">
					AGENDAR MINHA CONSULTA
				</v-btn>
			</v-col>
		</v-row>

		<v-row :style="intBreakPoint >= 2 ? 'padding: 20px 60px' : ''">
			<v-col cols="12" md="12">
				<p style="font-family: Montserrat; color: #707070; text-align: center">
					Chega de adiar o cuidado com a sua saúde dental por falta de tempo,
					custos altos ou consultas com agenda para daqui dois meses.
					<br /><br />
					Na Pró-Clínica você pode agendar a sua consulta com
					<b style="color: #0659a1"> Dentista em {{ end.cidade }} </b>
					em minutos através do Whatsapp com
					<b style="color: #0659a1"> preço justo e datas próximas.</b>
				</p>
			</v-col>
			<v-col cols="12" md="12" class="d-flex justify-center">
				<v-img :style="intBreakPoint >= 2
						? 'max-width: 65%; cursor: pointer'
						: 'cursor: pointer'
					" src="../assets/lpsEspecialidade/dentistaAgendar.png" @click="abrirDialogWhats"></v-img>
			</v-col>
		</v-row>

		<v-row style="background-color: #f5f5f5">
			<v-col cols="12" md="12" class="d-flex align-center justify-center">
				<v-img style="max-width: 3rem" src="../assets/abareLogoPreto.webp" contain></v-img>
				<v-img style="max-width: 8rem" :src="end.logoPreto" contain></v-img>
			</v-col>
		</v-row>

		<!-- <v-dialog v-model="popUp" :width="intBreakPoint >= 2 ? '50vw' : '100vw'">
			<Popup @closeDialog="closeDialog" />
		</v-dialog> -->
	</v-container>
</template>

<script>

export default {
	name: 'Odontologia',
	components: {
		// Popup
	},

	data() {
		return {popUp:true};
	},
	
	methods: {
		closeDialog() {
			this.popUp = false;
		},
		abrirDialogWhats() {
			this.$root.$emit('show-whats');
		},
	},
	computed: {
		intBreakPoint() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 0;
				case 'sm':
					return 1;
				case 'md':
					return 2;
				case 'lg':
					return 3;
				case 'xl':
					return 4;
				default:
					return 0;
			}
		},

		end() {
			return this.$store.getters.unidade;
		},
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style scoped>
@media (max-width: 600px) {
	.bgc3 {
		background-image: url('../assets/lpsEspecialidade/Grupo 5.png') !important;
		/* height: 100vh; */
		background-size: cover !important;
		background-position: center;
		border-radius: 50px;
		padding: 0 !important;
	}

	.bgc4 {
		background-image: url('../assets/lpsEspecialidade/banner1.jpg') !important;
		height: 100% !important;
		background-size: cover !important;
		background-position: center;
	}
}

.btnLp {
	background-color: #fff;
	color: #064277;
	font-family: Montserrat;
	font-weight: 800;
	border-radius: 50px;
	letter-spacing: normal;
	font-size: 1.2rem;
}

.efeito-vidro {
	background: rgba(255, 255, 255, 0.35);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(13.5px);
	-webkit-backdrop-filter: blur(9.5px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
}

.bgc3 {
	background-image: url('../assets/lpsEspecialidade/Grupo 5.png') !important;
	/* height: 100vh; */
	background-size: cover !important;
	background-position: center;
	border-radius: 50px;
	padding: 20px 60px;
}

.bgc4 {
	background-image: url('../assets/lpsEspecialidade/odonto/banner2.jpg') !important;
	height: 100vh;
	background-size: cover !important;
	background-position: center;
}
</style>
