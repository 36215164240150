<template>
  <v-container fluid>
    <v-row
      class="bgPaciente"
      justify="center"
      align="center"
      
    >
      <v-col cols="12" md="6">
        <h1
          style="
            color: #fff;
            font-size: 38px !important;
            font-family: poppins, sans-serif;
            line-height: 1;
          "
        >
          Sua saúde é muito importante para nós <br><br>
          Entre em contato conosco!
        </h1>
      </v-col>

      <v-col cols="12" md="6" class="align-center padColMobile">
        <div
          class="d-flex sizeFormMob justify-center flex-column positionMobile align-center"
          style="
            background-color: #4490a3a8;
            border-radius: 30px;
            padding: 80px 50px;
            border: 1px solid #fff;
          "
        >
          <v-text-field
            class="textField"
            outlined
            v-model="contato.nome"
            placeholder="Nome"
            required
          >
          </v-text-field>
          <v-text-field
          class="textField"

            outlined
            v-model="contato.email"
            placeholder="E-mail"
            required
          >
          </v-text-field>
          <v-text-field
          class="textField"

            outlined
            v-mask="'(##) #####-####'"
            v-model="contato.telefone"
            placeholder="Telefone"
            required
          >
          </v-text-field>
          <v-text-field
          class="textField"

            outlined
            v-model="contato.assunto"
            placeholder="Assunto"
            required
          >
          </v-text-field>
          <v-textarea
          class="textField1"

            outlined
            v-model="contato.texto"
            placeholder="Mensagem"
            required
          >
          </v-textarea>
          <v-btn
            :disabled="loading"
            @click="enviarFormulario"
            class="mr-4"
            type="button"
            height="50px"
            style="
              background-color: #fff;
              color: #4490a3;
              font-family: poppins, sans-serif;
              font-weight: 800;
              font-size: 15px;
              border-radius: 30px;
              text-transform: inherit;
              margin-top: 25px;
              margin-right: 0px !important;
              width: 200px;
            "
          >
            Enviar
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  metaInfo: {
			title: "Contato",
			meta: [
				{
					name: "Contato",
					content: "Sua saúde é muito importante para nós entre em contato conosco",
				},
				// outras meta tags
			],
		},
  data: () => ({
   
    loading: false,
    contato: { nome: "", email: "", telefone: "", assunto: "", texto: "" },
    snackbar: false,
    timeout:4000,
    text: "Seu contato foi enviado! Obriado por sua mensagem, responderemos em breve.",
  }),

  methods: {
    enviarFormulario(e) { 
      e.preventDefault()
      let user = this.contato
      this.loading = true
      if ((user.nome === "")) {
        alert("Por favor preencha seu nome")
        this.loading = false
        return;
      }
      if ((user.email === "")) {
        alert("Por favor preencha seu email")
        this.loading = false
        return;
      }
      else if (user.telefone === "") {
        alert("Por favor preencha seu telefone")
        this.loading = false

        return;
      }
      this.$store.dispatch("enviarFormulario", user)
      this.$store.dispatch("enviaRdstation", user).then((dt)=> { 
        if(dt) { 
          this.snackbar = true
          this.loading = false
          this.contato = { nome: "", email: "", telefone: "", assunto: "", texto: "" }
        }
      })
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },

  computed: {
    computedEmail() {
      return `${this.contato.telefone
        .replace("-", "")
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")}@falso.${this.contato.nome.replace(" ", "-")}.com.br`;
    },
    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 3;
        case "xl":
          return 4;
        default:
          return 0;
      }
    },
  },
};
</script>

<style scoped>
@media (min-device-width: 340px) and (max-device-width: 820px) {
  .positionMobile {
    position: relative !important;
    right: 0 !important;
    top: initial !important;
    padding: 50px 30px !important;
    width: 360px !important;
  }
  .padColMobile{
    padding:0px !important
  }
}
.textField {
  border-radius: 20px;
  width:100%;
  height:56px;
  margin:5px 0px;
  background-color:#FFF;
}
.textField1 {
  border-radius: 20px;
  width:100%;
  height:150px;
  margin:5px 0px;
  background-color:#FFF;
}
.bgPaciente {
  background-image: url("../assets/contato.webp") !important;
  background-attachment: fixed !important;
  background-size: cover !important;
  background-position: center center;
  
}
</style>