<template>
	<v-container fluid>
		<v-row class="bgc4" align="center" :style="intBreakPoint >= 2 ? 'padding: 40px 60px' : 'padding: 1.5rem'
			">
			<v-col cols="12" md="6">
				<v-img :src="end.logoCinza" style="width: 250px"></v-img>
				<h1 class="mt-5"
					:style="intBreakPoint >= 2 ? 'line-height: 1.2; font-family: Montserrat !important; font-weight: 800; color: #0659a1; text-transform: uppercase; ' : 'line-height: 1.2; font-family: Montserrat !important; font-weight: 800; color: #0659a1; text-transform: uppercase; font-size: 1.2rem'">
					CUIDAR DA SUA VISÃO <br />
					É CUIDAR DE TUDO
				</h1>
				<p class="mt-5"
					style="font-family: Montserrat !important; background-color: rgb(255, 255, 255, 0.6); width: fit-content; padding: 1rem; border-radius: 1rem;">
					Nossos oftalmologistas estão prontos para te atender <br v-if="intBreakPoint >= 2" />
					com excelência, comprometimento e foco total na sua<br v-if="intBreakPoint >= 2" />
					qualidade de vida.
				</p>
				<h1 class="mt-5" style="
						font-size: 1.2rem !important;
						line-height: 1.2;
						font-family: Montserrat !important;
						font-weight: 800;
						color: #707070;
					">
					Pró-Clínica, sua Clínica Oftalmológica <br v-if="intBreakPoint >= 2" />
					em {{ end.cidade }}
				</h1>
			</v-col>
			<v-col cols="12" md="12">
				<v-row>
					<v-col cols="12" md="4" class="d-flex align-center flex-column">
						<div class="d-flex align-center justify-center"
							:style="intBreakPoint >= 2 ? 'background-color: #45eb63; border-radius: 2rem; padding: 1rem 2rem; cursor: pointer; gap: 1rem;' : 'background-color: #45eb63; border-radius: 2rem; padding: 1rem; cursor: pointer; gap: 1rem;'"
							@click="abrirDialogWhats">
							<v-icon color="#fff" :size="intBreakPoint >= 2 ? '3rem' : '2rem'">mdi-whatsapp</v-icon>
							<button style="
								background-color: #45eb63;
								color: #fff;
								font-family: Montserrat !important;
								font-weight: 800;
								text-align: start;
								">
								Quero agendar minha
								<br v-if="intBreakPoint >= 2" />
								<span style="text-transform: uppercase">avaliação grátis</span>
							</button>
						</div>
					</v-col>
					<v-col cols="12" md="4" class="d-flex align-center flex-column">
						<v-img style="width: 250px" src="../assets/lpsEspecialidade/Grupo 3.png"></v-img>
					</v-col>
					<v-col cols="12" md="4" class="d-flex align-center flex-column">
						<v-img style="width: 250px" src="../assets/lpsEspecialidade/Grupo 1.png"></v-img>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<v-row class="bgc3" justify="center" align="center">
			<v-col cols="12" md="8" class="d-flex justify-center align-start flex-column">
				<h1 style="
							font-family: Montserrat !important;
							font-weight: 800;
							color: #fff;
							line-height: 1.2;
							font-size: 1.5rem;
							text-transform: uppercase
						" :class="intBreakPoint < 2 ? 'pt-4' : ''">
					Em dúvida se é o momento <br />
					de consultar um oftalmo?
				</h1>
				<p class="mt-2" style="
						font-family: Montserrat !important;
						color: #fff;
						line-height: 1.2;
					">
					Existem várias situações ou sintomas em que é aconselhável agendar uma consulta com um oftalmologista.
					<br><br>
					<b> Confira as principais: </b>
				</p>


				<v-row class="mt-5">
					<v-col cols="12" md="6">
						<div class="d-flex mt-2" style="gap: 1rem">
							<img src="../assets/lpsEspecialidade/oftalmo/icon.png" style="width: 20px; height: 20px">
							<p style=" font-family: Montserrat !important; color: #fff; line-height: 1.2; font-weight: 700;">Visão
								embaçada ou
								turva ("vista
								cansada")</p>
						</div>

						<div class="d-flex mt-2" style="gap: 1rem">
							<img src="../assets/lpsEspecialidade/oftalmo/icon.png" style="width: 20px; height: 20px">
							<p style=" font-family: Montserrat !important; color: #fff; line-height: 1.2; font-weight: 700;">Dor ou
								desconforto nos
								olhos</p>
						</div>

						<div class="d-flex mt-2" style="gap: 1rem">
							<img src="../assets/lpsEspecialidade/oftalmo/icon.png" style="width: 20px; height: 20px">
							<p style=" font-family: Montserrat !important; color: #fff; line-height: 1.2; font-weight: 700;">Visão dupla
								ou
								qualquer sintoma de
								visão alterada</p>
						</div>
					</v-col>
					<v-col cols="12" md="6">
						<div class="d-flex mt-2" style="gap: 1rem">
							<img src="../assets/lpsEspecialidade/oftalmo/icon.png" style="width: 20px; height: 20px">
							<p style=" font-family: Montserrat !important; color: #fff; line-height: 1.2; font-weight: 700;">Presença de
								manchas ou
								flashes de luz
							</p>
						</div>
						<div class="d-flex mt-2" style="gap: 1rem">
							<img src="../assets/lpsEspecialidade/oftalmo/icon.png" style="width: 20px; height: 20px">
							<p style=" font-family: Montserrat !important; color: #fff; line-height: 1.2; font-weight: 700;">Perda
								repentina total
								ou parcial da
								visão</p>
						</div>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" md="4" class="d-flex align-center justify-center">
				<v-img contain style="width: 70%; max-width: 100%" src="../assets/lpsEspecialidade/oftalmo/woman.png"></v-img>
			</v-col>

			<v-col cols="12" md="8" class="d-flex align-center justify-center flex-column">
				<p style="
						font-family: Montserrat !important;
						color: #fff;
						text-align: center;
						font-weight: 600;
					">
					Um oftalmologista pode ajudar a monitorar sua saúde visual, fazer avaliações de risco e fornecer
					orientações sobre estilo de vida saudável e medidas preventivas.
				</p>
			</v-col>
		</v-row>

		<v-row :style="intBreakPoint >= 2 ? 'padding: 20px 60px' : ''">
			<v-col cols="12" md="6" class="d-flex flex-column align-center" style="gap: 1rem">
				<h1 style="
							font-family: Montserrat !important;
							font-weight: 800;
							color: #064277;
							line-height: 1.2;
							font-size: 1.5rem;
							text-transform: uppercase;
						">
					Se identificou com algum desses sintomas?
				</h1>

				<p style=" font-family: Montserrat !important; color: #064277; line-height: 1.2; ">
					É hora de agendar sua consulta com um oftalmologista em <b> {{ end.cidade }} </b>.
				</p>
			</v-col>
			<v-col cols="12" md="6" class="d-flex flex-column align-center justify-end">
				<v-btn class="newbtnLp" style="padding: 40px" @click="abrirDialogWhats">CLIQUE AQUI E <br> AGENDE SUA
					CONSULTA</v-btn>
			</v-col>
			<v-col cols="12" md="12" class="d-flex justify-center">
				<small style="color: #707070; text-align: center;">*É fundamental lembrar que essas são apenas algumas situações
					ou
					sintomas que podem indicar a necessidade de consultar um oftalmologista. Sempre consulte
					um profissional de saúde qualificado para avaliar e diagnosticar corretamente sua condição e fornecer o
					tratamento adequado.</small>
			</v-col>
		</v-row>

		<v-row class="bgConsulta pt-16 pb-16">
			<v-col cols="12" md="6">
				<v-img src="../assets/lpsEspecialidade/oftalmo/img1.png" contain style="cursor: pointer"
					@click="abrirDialogWhats"></v-img>
			</v-col>
			<v-col cols="12" md="6" class="d-flex flex-column align-center justify-center" style="color: #fff">
				<p :style="intBreakPoint >= 2 ? 'font-size: 1.5rem' : 'font-size: 1.2rem'">Chega de adiar o cuidado com a sua
					saúde dos seus olhos por falta de tempo, custos
					altos ou consultas com
					agenda para daqui dois meses.</p>
				<p :style="intBreakPoint >= 2 ? 'font-size: 1.5rem' : 'font-size: 1.2rem'">Na Pró-Clínica você pode agendar a sua
					consulta com <b>Oftalmologista em {{
						end.cidade
					}}</b> em minutos
					através do Whatsapp
					<b>com direito a AVALIAÇÃO GRÁTIS, e datas próximas.</b>
				</p>
			</v-col>
		</v-row>

		<v-row style="background-color: #f5f5f5">
			<v-col cols="12" md="12" class="d-flex align-center justify-center">
				<a href="https://abaremarketing.com/" target="_blank">
					<v-img style="max-width: 3rem" src="../assets/abareLogoPreto.webp" contain></v-img>
				</a>
				<router-link to="/">
					<v-img style="max-width: 8rem" :src="end.logoPreto" contain></v-img>
				</router-link>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: "Odontologia",

	data() {
		return {};
	},
	methods: {
		abrirDialogWhats() {
			this.$root.$emit("show-whats");
		},
	},
	computed: {
		intBreakPoint() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return 0;
				case "sm":
					return 1;
				case "md":
					return 2;
				case "lg":
					return 3;
				case "xl":
					return 4;
				default:
					return 0;
			}
		},

		end() {
			return this.$store.getters.unidade;
		},
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style scoped>
@media (max-width: 600px) {
	.bgc3 {
		background-image: url("../assets/lpsEspecialidade/Grupo 5.png") !important;
		/* height: 100vh; */
		background-size: cover !important;
		background-position: center;
		padding: 0 !important;
	}

	.bgc4 {
		background-image: url('../assets/lpsEspecialidade/oftalmo/bgHeaderMb.png') !important;
		width: 100vw !important;
		min-height: 100vh !important;
		background-size: cover !important;
		background-position: center;
	}
}

.bgConsulta {
	background-image: url("../assets/lpsEspecialidade/oftalmo/banner3.png") !important;
	min-height: 100vh;
	background-size: cover !important;
	background-position: center;
}

.newbtnLp {
	background: rgb(130, 193, 249) !important;
	background: linear-gradient(273deg, rgba(130, 193, 249, 1) 0%, rgba(23, 124, 214, 1) 100%) !important;
	color: #fff !important;
	font-weight: 800 !important;
	font-family: Montserrat !important;
	letter-spacing: normal !important;
	font-size: 1.2rem !important;
}

.btnLp {
	background-color: #fff;
	color: #064277;
	font-family: Montserrat !important;
	font-weight: 800;
	border-radius: 50px;
	letter-spacing: normal;
	font-size: 1.2rem;
}

.efeito-vidro {
	background: rgba(255, 255, 255, 0.35);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(13.5px);
	-webkit-backdrop-filter: blur(9.5px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
}

.bgc3 {
	background-image: url("../assets/lpsEspecialidade/oftalmo/banner2.png") !important;
	/* height: 100vh; */
	background-size: cover !important;
	background-position: center;
	padding: 20px 60px;
}

.bgc4 {
	background-image: url("../assets/lpsEspecialidade/oftalmo/banner1.jpg") !important;
	min-height: 100vh;
	background-size: cover !important;
	background-position: center;
}
</style>
