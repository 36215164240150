<template>
    <v-container fluid>
      <v-row class="bgc1 heightMob heroBg" style="padding: 24px 5% 12px 5%; min-height: 100vh">
        <v-col cols="12" md="6" class="d-flex flex-column consulta-cta-col">
          <div class="d-flex align-center justify-space-between"
            style="width: 100%; background-color: #6EB5E1; border-radius: 9px; border: 1px solid #000; min-height: 7.5vh; padding: 10px;">
            <svg-icon size="45" color="#154B75" type="mdi" :path="mdiCalendarMonthOutline"></svg-icon>
            <span style="font-family: Montserrat, sans-serif; font-weight: 600; line-height: 1.3; font-size: clamp(11px, 0.8vw, 18px);">MARQUE SUA
              CONSULTA<br>EM POUCOS
              PASSOS</span>
            <div class="header-cta-btn d-flex justify-space-around align-center" style="width: 55%;">
              <button @click="abrirDialogWhats()" class="d-flex align-center" style="background-color: #34AF23; font-size: clamp(13px, 0.9vw, 20px); font-family: Montserrat, sans-serif; font-weight: 500; color: #fff; padding: 4.5px 6px; box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12); border-radius: 30px;">
                <svg-icon type="mdi" :path="mdiWhatsapp" size="20" style="margin-right: 3px;"></svg-icon>
                Por Whatsapp</button>
              <a href='tel:+5512998765432' class="d-flex align-center" style="background-color: #154B75; color: #fff !important; font-size: clamp(13px, 0.9vw, 20px); font-family: Montserrat, sans-serif; font-weight: 500; padding: 4.5px 10px; box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12); margin-top: 3px;
                border-radius: 30px;">
                <svg-icon type="mdi" :path="mdiPhone" size="20" style="margin-right: 3px;"></svg-icon>
                Por Telefone</a>
            </div>
          </div>
  
          <div class="hero-cta-div" v-if="intBreakPoint >= 2">
            <h1
              style="color:#115486; font-weight:700; font-size: 1.8vw; line-height:1; font-family: Montserrat, sans-serif; margin-bottom:20px; width: 90%;">
              A MELHOR CLÍNICA MÉDICA E ODONTOLÓGICA EM {{ end.cidade }}
            </h1>
            <v-btn @click="abrirDialogWhats()" class="botaoMobile botaoSite" style="">
              Agende Sua Consulta
            </v-btn>
          </div>
        </v-col>
  
        <v-col cols="12" md="6" class="align-center d-flex flex-column" style="padding: 5% 0">
          <h1 style="color: #5190BF; font-family: Montserrat, sans-serif; font-weight: 900; font-size: clamp(14px, 1.8vw, 36px); padding: 12px;">Diversas <span
              style="color: #006EBF">ESPECIALIDADES</span> em um só lugar!</h1>
          <img class="hero-img" style="width: 90%; height: 100%;" src="../assets/img-hero.webp"
            v-if="end.idName !== 'sorocaba'" />
          <img class="hero-img" style="width: 90%; height: 100%;" src="../assets/img-hero-sorocaba.webp" v-else />
        </v-col>
  
        <v-col cols="12" class="d-flex flex-column justify-center mb-15" style="min-height: 50%">
          <h1 style="font-family: Montserrat, sans-serif; font-weight: 800; font-size: clamp(20px, 2vw, 39px); color:#5190BF ;text-align: center;">NOSSOS
            SERVIÇOS</h1>
  
            <div v-if="intBreakPoint >= 2" style="display: flex; justify-content: space-between; align-items: center; min-height: 50%; margin: auto;">
            <div class="servicoDiv heroServiceOne">
              <img src="../assets/heroservice1.webp">
              <h2>ATENDIMENTO MÉDICO</h2>
            </div>
            <div class="servicoDiv heroServiceTwo">
              <img src="../assets/heroservice2.webp">
              <h2>EXAMES POR IMAGEM</h2>
            </div>
            <div class="servicoDiv heroServiceThree">
              <img src="../assets/heroservice3.webp">
              <h2>EXAMES LABORATORIAIS</h2>
            </div>
            <div class="servicoDiv heroServiceFour">
              <img src="../assets/heroservice4.webp">
              <h2>ATENDIMENTO ODONTOLÓGICO</h2>
            </div>
          </div>
  
          <div v-else style="display: flex; justify-content: space-between; align-items: center; min-height: 50%; margin: auto;">
            <div style="width: 50%;">
              <div class="servicoDiv heroServiceOne">
                <img src="../assets/heroservice1.webp">
                <h2>ATENDIMENTO MÉDICO</h2>
              </div>
              <div class="servicoDiv heroServiceTwo">
                <img src="../assets/heroservice2.webp">
                <h2>EXAMES POR IMAGEM</h2>
              </div>
            </div>
            <div style="width: 50%;">
              <div class="servicoDiv heroServiceThree">
                <img src="../assets/heroservice3.webp">
                <h2>EXAMES LABORATORIAIS</h2>
              </div>
              <div class="servicoDiv heroServiceFour">
                <img src="../assets/heroservice4.webp">
                <h2>ATENDIMENTO ODONTOLÓGICO</h2>
              </div>
            </div>
          </div>
  
          <v-btn @click="abrirDialogWhats()" class="botaoMobile botaoSite" style="align-self: center;">
            Agende Sua Consulta
          </v-btn>
        </v-col>
      </v-row>
  
      <v-row v-if="end.cidade === 'SOROCABA'" class="bgVideo mgRowVideo" style="margin-top:0; padding:0">
        <v-col cols="12" md="5" class="align-center justify-center d-flex flex-column" style="min-height: 600px;" />
        <v-col cols="12" md="7" style="padding: 0">
          <div class="colCta align-start justify-center d-flex flex-column" style="height: 100%;">
            <h1 style="color:#fff; font-weight:600; font-size:35px; line-height:normal; font-family: poppins, sans-serif">
              Uma clínica completa para cuidar de <span style="color:#1F5171; font-weight: 900;">sua saúde e de sua
                família</span>
            </h1>
            <v-btn @click="abrirDialogWhats()" class="botaoMobile botaoSite" style="margin: 25px 0;">
              Agende Sua Consulta
            </v-btn>
          </div>
        </v-col>
      </v-row>
  
      <v-row v-else class="bgVideo mgRowVideo bgVideoSjc" style="margin-top:90px; padding:0">
        <v-col cols="12" md="5" class="align-center justify-center d-flex flex-column" style="min-height: 600px;" />
        <v-col cols="12" md="7" style="padding: 0">
          <div class="colCta align-start justify-center d-flex flex-column" style="height: 100%;">
            <h1 style="color:#fff; font-weight:600; line-height: normal; font-family: poppins, sans-serif">
              Uma clínica completa para cuidar de <span style="color:#1F5171; font-weight: 900;">sua saúde e de sua
                família</span>
            </h1>
            <v-btn @click="abrirDialogWhats()" class="botaoMobile botaoSite" style="margin: 25px 0;">
              Agende Sua Consulta
            </v-btn>
          </div>
        </v-col>
      </v-row>
  
      <v-row class="pt-10 pb-12">
        <v-col cols="12" md="8" class="align-center justify-center d-flex flex-column">
          <h1 style="color:#3B3B3B; font-weight:500; font-size:35px; line-height:1; font-family: Montserrat, sans-serif">
            Cuidado completo com a sua saúde por um <br v-if="intBreakPoint >= 2">
            <span style="color:#5190bf;">preço que cabe no seu bolso</span>
          </h1>
        </v-col>
  
        <v-col cols="12" md="12" class="services-cards align-center justify-center d-flex flexmb">
          <v-card class="mgCard"
            style="max-height:300px; height:300px; max-width:220px; width:220px; background-color: #E0EDF6; border-radius:20px; padding:15px; margin:0px 10px">
            <v-img contain style="max-width:100px;margin-bottom:-80px; margin-top:-80px"
              src="../assets/dentiscard.webp"></v-img>
            <p style="color:#3B3B3B; font-family:Montserrat, sans-serif; font-size:12px; ">
              <span style="color:#0965AB">Dentista</span><br>
              <span style="color:#0965AB">+ 12</span> procedimentos
            </p>
            <p style="color:#3B3B3B; font-family:Montserrat, sans-serif; font-size:12px; font-weight:800; margin-top:40px">
              Avaliação <br>
              <span style="font-weight:800; font-size:35px">Grátis</span>
            </p>
          </v-card>
  
          <v-card class="mgCard"
            style="max-height:300px; height:300px; max-width:220px; width:220px; background-color: #E0EDF6; border-radius:20px; padding:15px; margin:0px 10px">
            <v-img contain style="max-width:100px; margin-bottom:-80px; margin-top:-80px" src="../assets/20esp.webp"></v-img>
            <p style="color:#3B3B3B; font-family:Montserrat, sans-serif; font-size:12px;">
              São <span style="color:#0965AB">+ de 20</span> especialidades médicas
            </p>
            <p style="color:#3B3B3B; font-family:Montserrat, sans-serif; font-size:12px; margin-top:40px">
              A partir de: <br>
              <span style="font-weight:800; font-size:35px">R$70</span>
            </p>
          </v-card>
  
          <v-card class="mgCard"
            style="max-height:300px; height:300px; max-width:220px; width:220px; background-color: #E0EDF6; border-radius:20px; padding:15px; margin:0px 10px">
            <v-img contain style="max-width:100px;margin-bottom:-80px; margin-top:-80px"
              src="../assets/checkup.webp"></v-img>
            <p style="color:#3B3B3B; font-family:Montserrat, sans-serif; font-size:12px;">
              <span style="color:#0965AB">Check-ups</span> <br>
              Avaliação médica de rotina + exames
            </p>
            <p style="color:#3B3B3B; font-family:Montserrat, sans-serif; font-size:12px;">
              A partir de: <br>
              <span style="font-weight:800; font-size:35px">R$80</span>
            </p>
          </v-card>
  
          <v-card class="mgCard"
            style="max-height:300px; height:300px; max-width:220px; width:220px; background-color: #E0EDF6; border-radius:20px; padding:15px; margin:0px 10px">
            <v-img contain style="max-width:100px;  margin-bottom:-80px; margin-top:-80px"
              src="../assets/50exames.webp"></v-img>
            <p style="color:#3B3B3B; font-family:Montserrat, sans-serif; font-size:12px;">
              <span style="color:#0965AB">Mais de 50</span> Exames Laboratoriais e imagem
            </p>
          </v-card>
  
          <v-card class="bgCard mgCard"
            style="max-height:300px; height:300px; max-width:220px; width:220px; border-radius:20px; padding:15px; margin:0px 10px">
            <v-img contain style="max-width:100px; margin-bottom:-80px; margin-top:-80px"
              src="../assets/calendario.webp"></v-img>
            <p style="color:#fff; font-family:Montserrat, sans-serif; font-size:12px; font-weight:8000; margin-bottom:0px">
              Agendar Consulta
            </p>
            <v-btn class="" text @click="abrirDialogWhats()" style="color:#fff; font-family:Montserrat, sans-serif; font-size: clamp(16px, 2vw, 24px); font-weight: bold; text-transform: none; text-decoration: underline;
            padding: 0px 0px; width: 100%; overflow: ;">
              Clique aqui!
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
  
      <v-row class="rowDoto " style="padding-bottom:0px">
        <v-col cols="12" md="6" xl="7" class="align-center justify-center d-flex flex-column"
          style="padding: 20px 20px 0 80px">
          <div>
            <h1
              style="color:#8BC9F8; font-weight:800; font-size:35px; line-height:1; font-family: Montserrat, sans-serif; padding-bottom:20px">
              ATENDIMENTO COM ATENÇÃO E CUIDADO EM UMA ESTRUTURA MODERNA E CONFORTÁVEL
            </h1>
            <p style="font-size:16px; font-width:500; font-family: Montserrat, sans-serif; color:#fff;">
              Somos mais do que uma clínica popular, aqui você encontra atendimento humanizado, personalizado,
              atenção e empatia.<br>
              Nós trabalhamos para cuidar da sua saúde, em uma estrutura ampla, moderna e confortável oferecemos a
              você serviços médicos, odontológicos e laboratoriais.
            </p>
            <p style="font-size:16px; font-width:500; font-family: Montserrat, sans-serif; color:#fff;">
              Na Pró Clínica a nossa missão é tornar a sua experiência o mais confortável possível em todas as etapas do
              atendimento, desde a Recepção até o Pós Consulta.
            </p>
            <p style="font-size:16px; font-width:500; font-family: Montserrat, sans-serif; color:#fff;">
              Trabalhamos para cuidar da sua saúde, em uma estrutura ampla e moderna, oferecemos serviços médicos,
              odontológicos e laboratoriais.
            </p>
            <p style="font-size:16px; font-width:800; font-family: Montserrat, sans-serif; color:#fff;">
              Sem filas, sem longas datas de espera, sem mensalidade e o melhor por um preço que cabe no seu bolso.
            </p>
            <p style="font-size:16px; font-width:800; font-family: Montserrat, sans-serif; color:#fff;">
              Tudo o que você precisa para ter o cuidado completo com a sua saúde, você encontra aqui.
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="6" xl="5" class="align-center justify-center d-flex flex-column" style="padding:0px;">
          <v-img class="wdImgMb" src="../assets/medicaecrianca.webp"></v-img>
        </v-col>
      </v-row>
  
    </v-container>
  </template>
    
  <script>
  import SvgIcon from '@jamescoyle/vue-icon';
  import { mdiCalendarMonthOutline, mdiWhatsapp, mdiPhone } from '@mdi/js';
  export default {
    metaInfo() {
      return {
        title: "Pró clínica: Saúde da família",
        meta: [
          {
            name: " Pró clínica: Saúde da família",
            content: `É mais que uma clínica popular, é a  melhor clínica médica e odontológica de ${this.$store.getters.unidade.cidade}. Venha cuidar da saúde da família aqui, consultas médicas a partir de R$70`,
          },
          {
            name: "google-site-verification",
            content: this.$store.getters.unidade.Siteverification,
          },
        ],
      }
    },
    components: {
      SvgIcon
    },
    data: () => ({
      mdiCalendarMonthOutline,
      mdiWhatsapp,
      mdiPhone,
      backgroundImg: '',
      teste: true,
      carrossada: [],
      contato: {
        nome: "",
        email: "",
        telefone: "",
        especialidade: "",
        unidade: "",
      },
      text: "Seu contato foi enviado! Obrigado por sua mensagem, responderemos em breve.",
      timeout: 2000,
      items: [
        "Cardiologia",
        "Clínico Geral",
        "Dermatologista",
        "Gastroenterologista",
        "Geriatra",
        "Ginecologista",
        "Neurologista",
        "Otorrinolaringologista",
        "Pediatra",
        "Urologista",
        "Vascular",
        "Ortopedista",
        "Oftamologista",
        "Clínico geral dentista",
        "Ortodontista",
        "Implantodontista",
        "Periodontista",
        "Ultrassom",
        "Hemograma completo",
        "Coagulograma",
        "Colesterol total e frações",
      ],
      items2: ["São José dos Campos", "Sorocaba"],
    }),
  
    // mounted() {
    //   let element = [require('../assets/carrosselSoro/1.webp'), require('../assets/carrosselSoro/2.webp'), require('../assets/carrosselSoro/3.webp'), require('../assets/carrosselSoro/4.webp'),require('../assets/carrosselSoro/5.webp'),require('../assets/carrosselSoro/6.webp'),require('../assets/carrosselSoro/7.webp')];
    //     if(this.end.cidade == 'Sorocaba'){
    //         this.carrossada = element
    //   }
    //     else if(this.end.cidade == 'São José dos Campos'){
    //     element = [require('../assets/carrosselSjc/1.webp'), require('../assets/carrosselSjc/2.webp'), require('../assets/carrosselSjc/3.webp'),require('../assets/carrosselSjc/4.webp'),require('../assets/carrosselSjc/5.webp')];
    //     }
    //     this.carrossada = element
  
    //   window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: "smooth",
    //   });
    // },
    methods: {
      abrirDialogWhats() {
        this.$root.$emit("show-whats")
      },
    },
  
    computed: {
      intBreakPoint() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 0;
          case "sm":
            return 1;
          case "md":
            return 2;
          case "lg":
            return 3;
          case "xl":
            return 4;
          default:
            return 0;
        }
      },
  
      end() {
        return this.$store.getters.unidade;
      },
    },
  };
  </script>
    
  <style>
  @media (min-device-width: 340px) and (max-device-width: 820px) {
    .mgRowVideo {
      padding: 20px 0px !important;
    }
  
    .mgRow {
      margin: -22px !important;
    }
  
    .wdInfo {
      max-width: 354px !important
    }
  
    .pdlfBtn {
      padding-left: 90px !important;
    }
  
    .flexmb {
      flex-direction: column !important;
    }
  
    .justifyContentCenter {
      justify-content: center !important;
    }
  
    .ultimoCard {
      margin-right: 10px !important;
      margin-top: 5px !important;
    }
  
    .mgCard {
      margin: 5px 0px !important;
      /* margin-right:10px !important */
    }
  
    .botaoMobile {
      font-size: 16px !important;
      margin-left: 0px !important;
      padding: 30px !important;
    }
  
    .wdGifMobile {
      max-width: 300px !important;
    }
  
    .wdImgMb {
      max-width: 356px !important;
    }
  
    .heightMobile {
      height: 800px !important;
    }
  
    .pdTopCol {
      padding-top: 0px !important;
    }
  
    .mgLeftCard {
      margin-left: 0px !important;
    }
  
    .positionMobile {
      position: relative !important;
      right: 0 !important;
      top: initial !important;
      padding: 50px 30px !important;
      width: 360px !important;
    }
  
    .textoTitulo {
      font-size: 40px !important;
      padding-top: 60px !important;
    }
  
    .padTexto {
      padding-left: 10px !important;
    }
  
    .textoCorpo {
      font-size: 25px !important;
      padding: 30px 30px 0px 30px !important;
    }
  
    .flexMobile {
      height: 40vh !important;
      /* flex-direction: column !important; */
    }
  
    .especialidade {
      height: 100% !important;
    }
  
    .widthCard {
      max-width: auto !important;
    }
  
    .wdEsp {
      width: 85vw !important;
    }
  
    .padCol {
      padding: 0px !important;
    }
  
    .padImg1 {
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  
    .padImg2 {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  
    .wdImgMobile {
      min-width: 350px !important;
    }
  }
  
  .mask1 {
    -webkit-mask-image: url("../assets/mascra.webp");
    mask-image: url("../assets/mascra.webp");
    -webkit-mask-size: 100% !important;
    mask-size: 100% !important;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
  
  .bgEspeciali {
    background-image: url("../assets/corredor.webp") !important;
    height: auto;
    background-attachment: inherit !important;
    background-size: cover !important;
    background-position: center;
  }
  
  .bgVideo {
    background-image: url("../assets/fachadaSorocaba.webp") !important;
    background-size: 50% 100%;
    min-height: 65vh;
    background-position: left center;
  }
  
  .bgVideoSjc {
    background-image: url("../assets/fotoFachadaSjc.webp") !important;
  
  }
  
  .botaoSite {
    background-color: #0965AB !important;
    color: #fff !important;
    border-radius: 30px;
    font-size: 17px;
    font-weight: 600;
    font-family: Montserrat, sans-serif;
    height: 50px;
    text-transform: none;
  }
  
  
  .bgFachadaSjc {
    background-image: url("../assets/fachada.webp") !important;
    height: 50vh;
    background-attachment: fixed !important;
    background-size: cover !important;
    background-position: center;
  }
  
  .bgFachadaSorocaba {
    background-image: url("../assets/fachadaSorocaba.webp");
    height: 50vh;
    background-attachment: fixed !important;
    background-size: cover !important;
    background-position: center;
  }
  
  .rowDoto {
    background-image: url("../assets/rowdotor.webp");
    height: auto;
    background-attachment: inherit !important;
    background-size: cover !important;
    background-position: center;
  }
  
  .fundoFluxo {
    background-image: url("../assets/fundofluxo.webp");
    height: auto;
    background-attachment: inherit !important;
    background-size: cover !important;
    background-position: center;
  }
  
  .bgForm[data-v-5c26cd26] {
    background-image: url("../assets/agendamento.webp") !important;
    height: 100% !important;
    background-attachment: inherit !important;
    background-size: cover !important;
    opacity: 85% !important;
  }
  
  .bgForm {
    background-image: url("../assets/agendamento.webp") !important;
    height: 100% !important;
    background-attachment: inherit !important;
    background-size: cover !important;
    opacity: 0.85 !important;
  }
  
  .bgCard {
    background: linear-gradient(177deg, rgba(27, 108, 171, 1) 5%, rgba(21, 75, 117, 1) 67%);
  }
  
  .colCta {
    background-image: url('../assets/fundovideonovo.webp');
    padding: 50px 15% 50px 30%;
    height: 100%;
    background-size: cover;
    background-position: center right;
  }
  
  .heroBg {
    background-image: url('../assets/bg-hero.webp');
    background-position: left;
    background-size: 100% unset;
    margin-top: 0;
  }
  
  .servicoDiv {
    width: 22%;
    background-color: #cfefff;
    min-height: 100px;
    margin: 50px 0;
    border-radius: 50px;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .servicoDiv img {
    position: absolute;
    left: -7%;
    top: -5%;
    height: 120%;
  }
  
  .servicoDiv h2 {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 1.1vw;
    padding: 0 3% 0 8vw;
  }
  
  .hero-cta-div {
    padding-top: 30%;
  }
  
  @media(max-width: 600px) {
    .heroBg {
      padding: 12px 0 !important;
    }
  
    .consulta-cta-col i {
      padding-right: 2px;
    }
    .header-cta-btn {
      flex-direction: column !important;
      margin: 0 5px;
    }
  
    .header-cta-btn button {
      margin: 3px 0;
      align-self: center;
  }
    .servicoDiv {
      min-height: 50px;
    }
    .servicoDiv img {
      height: 105%;
    }
    .servicoDiv h2 {
    font-size: 1.9vw !important;
    }
  
    .bgVideo {
      background-size: 100% 50% !important;
    }
    .bgVideo div {
      min-height: 200px !important;
    }
  
    .services-cards {
      flex-direction: column !important;
    }
  
    .mgCard {
      margin-bottom: 10px !important;
    }
  
    .rowDoto>div {
      padding: 12px !important;
    }
  
    .colCta {
      padding: 24px;
      background-position: bottom right;
    }
    .colCta h1 {
      font-size: 26px !important;
    }
  }
  
  @media(max-width: 960px) {
    .hero-cta-div {
      padding-top: 10%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
    }
  
    .heroBg {
      margin-top: -12px;
    }
    .hero-img {
      width: 100% !important;
    }
  
    .servicoDiv {
      width: 90%;
      margin: 30px 0;
    }
    .servicoDiv h2 {
      font-size: 2vw;
      margin-left: 18%;
    }
  
    .bgVideo {
      background-size: 100% 70%;
      background-position: top;
    }
  
    .colCta {
      background-size: 120% 150%;
    }
  }
  
  @media (min-width: 600px) and (max-width: 960px) {
    .consulta-cta-col {
      padding: 0 50px;
    }
  
    .mgCard {
      width: 18% !important;
      padding: 6px !important;
      margin: 0 5px !important;
    }
  
    .services-cards {
      flex-direction: row !important;
      justify-content: space-between !important ;
    }
  }
  
  @media(min-width: 1900px) {
    .servicoDiv h2 {
      padding-left: 6vw;
    }
  }
  </style>
    