<template>
  <v-container fluid>
    <v-row class="justify-center align-center">
      <v-col cols="12" md="5" class="d-flex flex-column consulta-cta-col">
        <div class="header-cta-banner d-flex align-center justify-space-between"
          style="width: 100%; background-color: #6EB5E1; border-radius: 9px; border: 1px solid #000; min-height: 7.5vh; padding: 10px;">
          <svg-icon type="mdi" :path="mdiCalendarMonthOutline" size="45" color="#154b75"></svg-icon>
          <span style="font-family: Montserrat, sans-serif; font-weight: 600; line-height: 1.3; font-size: clamp(11px, 0.8vw, 18px);">MARQUE SUA
            CONSULTA<br>EM POUCOS
            PASSOS</span>
          <div class="header-cta-btn d-flex  justify-space-around" style="width: 55%;">
            <button @click="abrirDialogWhats()" class="d-flex align-center" style="background-color: #34AF23; font-size: clamp(13px, 0.9vw, 20px); font-family: Montserrat, sans-serif; font-weight: 500; color: #fff; padding: 4.5px 6px; box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12); border-radius: 30px;">
              <svg-icon type="mdi" :path="mdiWhatsapp" size="20" style="margin-right: 3px;"></svg-icon>
              Por Whatsapp</button>
              <a href='tel:+5512998765432' class="d-flex align-center" style="background-color: #154B75; color: #fff !important; font-size: clamp(13px, 0.9vw, 20px); font-family: Montserrat, sans-serif; font-weight: 500; padding: 4.5px 10px; box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12); margin-top: 3px;
              border-radius: 30px;">
              <svg-icon type="mdi" :path="mdiPhone" size="20" style="margin-right: 3px;"></svg-icon>
              Por Telefone</a>
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="align-center d-flex flex-column" style="padding: 5% 0">
        <h1 style="color: #5190BF; font-family: Montserrat, sans-serif; font-weight: 900; font-size: clamp(16px, 3vw, 26px); padding: 0 2%; text-align: center;">Diversas <span
            style="color: #006EBF">ESPECIALIDADES</span> em um só lugar!</h1>
        <img v-if="intBreakPoint >= 2" style="width: 50%;" src="../assets/img-odonto.webp" />
        <img v-else style="width: 98%;" src="../assets/img-odonto.webp" />
      </v-col>
      <v-col cols="12" md="6"></v-col>
    </v-row>

    <v-row style="margin-top:50px; margin-bottom:50px;">
      <v-col cols="12" md="12" class="align-center justify-center d-flex">
        <h1 style="color:#5190BF; font-weight:800; font-size:35px; line-height:1; font-family: Montserrat, sans-serif">
          ESPECIALISTAS
        </h1>
      </v-col>

      <v-col v-if="intBreakPoint > 1" cols="12" md="12" class="align-center justify-center d-flex flexCard">
        <v-img class="marginImg" @click="(odontoPedi = true)" contain
          style="max-width:200px; margin-right: 10px; cursor:pointer" src="../assets/dentist/odontopediatria.webp">
        </v-img>
        <v-img class="marginImg" @click="(ortondo = true)" contain style="max-width:200px; cursor:pointer"
          src="../assets/dentist/ortodontista.webp">
        </v-img>
        <v-img class="marginImg" @click="(implanto = true)" contain
          style="max-width:200px; margin: 0px 10px; cursor:pointer" src="../assets/dentist/implanto.webp">
        </v-img>
        <v-img class="marginImg" @click="(endo = true)" contain style="max-width:200px; cursor:pointer"
          src="../assets/dentist/endodontista.webp">
        </v-img>
        <v-img class="marginImg" @click="(clinico = true)" contain
          style="max-width:200px; margin-left: 10px; cursor:pointer" src="../assets/dentist/clinico.webp">
        </v-img>
      </v-col>

      <v-col v-if="intBreakPoint < 1" cols="12" md="12" class="align-center flex-column justify-center d-flex flexCard">
        <v-img class="marginImg" @click="(odontoPedi = true)" contain
          style="margin-bottom: 10px; max-height: 200px; max-width:200px; margin-right: 10px; cursor:pointer" src="../assets/dentist/odontopediatria.webp">
        </v-img>
        <v-img class="marginImg" @click="(ortondo = true)" contain style="margin-bottom: 10px; max-height: 200px; max-width:200px; cursor:pointer"
          src="../assets/dentist/ortodontista.webp">
        </v-img>
        <v-img class="marginImg" @click="(implanto = true)" contain
          style="margin-bottom: 10px; max-height: 200px; max-width:200px; cursor:pointer" src="../assets/dentist/implanto.webp">
        </v-img>
        <v-img class="marginImg" @click="(endo = true)" contain style="margin-bottom: 10px; max-height: 200px; max-width:200px; cursor:pointer"
          src="../assets/dentist/endodontista.webp">
        </v-img>
        <v-img class="marginImg" @click="(clinico = true)" contain
          style="margin-bottom: 10px; max-height: 200px; max-width:200px; margin-left: 10px; cursor:pointer" src="../assets/dentist/clinico.webp">
        </v-img>
      </v-col>

      <v-col v-if="intBreakPoint === 1" cols="12" md="12" class="align-center justify-center d-flex flexCard">
        <v-img class="marginImg" @click="(odontoPedi = true)" contain
          style="max-width: 19%; margin-right: 10px; cursor:pointer" src="../assets/dentist/odontopediatria.webp">
        </v-img>
        <v-img class="marginImg" @click="(ortondo = true)" contain style="max-width: 19%; cursor:pointer"
          src="../assets/dentist/ortodontista.webp">
        </v-img>
        <v-img class="marginImg" @click="(implanto = true)" contain
          style="max-width: 19%; margin: 0px 10px; cursor:pointer" src="../assets/dentist/implanto.webp">
        </v-img>
        <v-img class="marginImg" @click="(endo = true)" contain style="max-width: 19%; cursor:pointer"
          src="../assets/dentist/endodontista.webp">
        </v-img>
        <v-img class="marginImg" @click="(clinico = true)" contain
          style="max-width: 19%; margin-left: 10px; cursor:pointer" src="../assets/dentist/clinico.webp">
        </v-img>
      </v-col>
    </v-row>
      <v-row v-if="intBreakPoint >= 2">
      <v-col cols="12" md="12" class="align-center justify-center d-flex ">
        <h1 style="color:#4490a3; font-weight:800; font-size:40px; font-family: poppins, sans-serif">
          Procedimentos:
        </h1>
      </v-col>
      <v-col cols="12" md="6" class="procedimentos-col align-end justify-center d-flex flex-column align-self-start pr-10">
        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            max-width:400px;
            width:380px;
            border-radius:20px;
            padding:20px">
          <v-img contain style="max-width:50px;" src="../assets/dentist/canal.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 30px;">
            Canal
          </p>
          <v-btn @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            margin-left:76px;
            text-transform: none;
            padding:5px;
            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>

        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            max-width:400px;
            width:380px;
            border-radius:20px;
            padding:20px">
          <v-img contain style="max-width:50px;" src="../assets/dentist/restauracao.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 30px;">
            Restauração
          </p>
          <v-btn @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            text-transform: none;
            padding:5px;
            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>

        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            width:380px;
            max-width:400px;
            border-radius:20px;
            padding:20px">
          <v-img contain style="max-width:50px;" src="../assets/dentist/limpeza.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 30px;">
            Limpeza
          </p>
          <v-btn @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            text-transform: none;
            margin-left:50px;

            padding:5px;
            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>

        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            max-width:400px;
            width:380px;
            border-radius:20px;
            padding:20px">
          <v-img contain style="max-width:50px;" src="../assets/dentist/extracao.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 30px;">
            extracaoo
          </p>
          <v-btn class="" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            text-transform: none;
            margin-left:47px;

            padding:5px;
            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" class="align-start justify-center d-flex flex-column align-self-start pl-10">
        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            width:380px;
            max-width:400px;
            border-radius:20px;
            padding:20px">
          <v-img contain style="max-width:50px;" src="../assets/dentist/implante.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 30px;">
            Implante
          </p>
          <v-btn class="mgImplante" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            text-transform: none;
            margin-left:42px;
            padding:5px;
            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>
        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            max-width:400px;
            width:380px;
            border-radius:20px;
            padding:20px">
          <v-img contain style="max-width:50px;" src="../assets/dentist/clareamento.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 30px;">
            Clareamento
          </p>
          <v-btn @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            text-transform: none;
            padding:5px;
            /* margin-left:20px; */

            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>

        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            width:380px;
            max-width:400px;
            border-radius:20px;
            padding:20px">
          <v-img contain style="max-width:50px;" src="../assets/dentist/aparelho.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 30px;">
            Aparelhos
          </p>
          <v-btn @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            text-transform: none;
            margin-left:36px;

            padding:5px;
            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>
        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            width:380px;
            max-width:400px;
            border-radius:20px;
            padding:20px 10px">
          <v-img contain style="max-width:50px;" src="../assets/dentist/protese.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:20px; margin-bottom:0px; cursor: pointer; margin:0px 10px;">
            Próteses Fixas e Móveis
          </p>
          <v-btn class="mgProtese" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            text-transform: none;
            margin-left:48px;

            padding:5px;
            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" md="12" class="align-center justify-center d-flex ">
        <h1 style="color:#4490a3; font-weight:800; font-size:40px; font-family: poppins, sans-serif">
          Procedimentos:
        </h1>
      </v-col>
      <v-col cols="12" md="6" class="procedimentos-col align-center justify-center d-flex flex-column align-self-start" style="padding-bottom: 0">
        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            max-width:400px;
            width:380px;
            border-radius:20px;
            padding:20px">
          <v-img contain style="max-width:50px;" src="../assets/dentist/canal.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 30px;">
            Canal
          </p>
          <v-btn @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            margin-left:76px;
            text-transform: none;
            padding:5px;
            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>

        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            max-width:400px;
            width:380px;
            border-radius:20px;
            padding:20px">
          <v-img contain style="max-width:50px;" src="../assets/dentist/restauracao.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 30px;">
            Restauração
          </p>
          <v-btn @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            text-transform: none;
            padding:5px;
            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>

        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            width:380px;
            max-width:400px;
            border-radius:20px;
            padding:20px">
          <v-img contain style="max-width:50px;" src="../assets/dentist/limpeza.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 30px;">
            Limpeza
          </p>
          <v-btn @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            text-transform: none;
            margin-left:50px;

            padding:5px;
            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>

        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            max-width:400px;
            width:380px;
            border-radius:20px;
            padding:20px">
          <v-img contain style="max-width:50px;" src="../assets/dentist/extracao.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 30px;">
            extracaoo
          </p>
          <v-btn class="" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            text-transform: none;
            margin-left:47px;

            padding:5px;
            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" class="align-center justify-center d-flex flex-column align-self-start" style="padding-top: 0">
        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            width:380px;
            max-width:400px;
            border-radius:20px;
            padding:20px;">
          <v-img contain style="max-width:50px;" src="../assets/dentist/implante.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 30px;">
            Implante
          </p>
          <v-btn class="mgImplante" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            text-transform: none;
            margin-left:42px;
            padding:5px;
            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>
        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            max-width:400px;
            width:380px;
            border-radius:20px;
            padding:20px">
          <v-img contain style="max-width:50px;" src="../assets/dentist/clareamento.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 30px;">
            Clareamento
          </p>
          <v-btn @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            text-transform: none;
            padding:5px;
            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>

        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            width:380px;
            max-width:400px;
            border-radius:20px;
            padding:20px">
          <v-img contain style="max-width:50px;" src="../assets/dentist/aparelho.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:22px; margin-bottom:0px; cursor: pointer; margin:0px 30px;">
            Aparelhos
          </p>
          <v-btn @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            text-transform: none;
            margin-left:36px;

            padding:5px;
            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>
        <v-card class="align-center d-flex wdCard" style="
            margin:5px 0px;
            max-height:100px;
            width:380px;
            max-width:400px;
            border-radius:20px;
            padding:20px 10px">
          <v-img contain style="max-width:50px;" src="../assets/dentist/protese.webp"></v-img>
          <p class="mgP"
            style="color:#000; font-family:Poppins, sans-serif; font-size:20px; margin-bottom:0px; cursor: pointer; margin:0px 10px;">
            Próteses Fixas e Móveis
          </p>
          <v-btn class="mgProtese" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
            background-color:#154B75;
            color:#fff;
            font-weight:800;
            text-transform: none;
            margin-left:48px;

            padding:5px;
            border-radius:5px">
            Saiba mais</v-btn>
        </v-card>
      </v-col>
    </v-row>




    <div v-if="intBreakPoint >=2">
      <v-dialog v-model="clinico">
      <v-card class="align-center justify-center d-flex flexCard">
        <button @click="clinico = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1;
                ">
            Clínico Geral
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            Especialidade em que o dentista realiza o diagnóstico e tratamento
            relacionados à saúde bucal. Este profissional atua desenvolvendo
            trabalhos de prevenção como limpeza e orientação a saúde bucal,
            além de restaurações.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>

        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/odonto.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ortondo">
      <v-card class="align-center justify-center d-flex flexCard">
        <button @click="ortondo = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Ortodontista
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            É a especialidade da Odontologia relacionada ao estudo, prevenção
            e tratamento dos problemas de crescimento, desenvolvimento e
            amadurecimento da face, dos arcos dentários e da oclusão, ou seja,
            disfunções dento-faciais. O tratamento é feito com aparelhos
            ortodônticos e ortopédicos que permitem alinhar e nivelar os
            dentes e promover crescimento ósseo para corrigir deficiências
            dento-faciais.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/ortondo.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="implanto">
      <v-card class="align-center justify-center d-flex flexCard">
        <button @click="implanto = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="titleMobImpla" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Implantodontista
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            O implante dentário é um procedimento realizado em regiões
            edentulas (ausência de dente), consiste na fixação de um pino de
            titânio na região para servir de apoio na colocação de uma prótese
            fixa.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/implant.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="periodo">
      <v-card class="align-center justify-center d-flex flexCard">
        <button @click="periodo = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Periodontista
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            Periodontia é a especialidade odontológica que trata dos tecidos
            do periodonto, ou seja, aqueles que dão sustentação para os
            dentes. Seus procedimentos objetivam prevenir, diagnosticar e
            tratar problemas e doenças que atingem a gengiva, o ligamento
            periodontal e o osso alveolar. O especialista dessa área é o
            periodontista.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/periodontista.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="endo">
      <v-card class="align-center justify-center d-flex flexCard">
        <button @click="endo = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Endodontista
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            As áreas de competência do endodontista são: tratamentos conservadores da vitalidade pulpar;
            tratamento cirúrgico nos tecidos e na cavidade pulpar; procedimentos endodônticos cirúrgicos;
            tratamento de dentes permanentes jovens com rizogênese incompleta;
            tratamento dos traumatismos dentários e recuperação da cor dental.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/periodontista.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="odontoPedi">
      <v-card class="align-center justify-center d-flex flexCard">
        <button @click="odontoPedi = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Odontopediatra
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            A principal função do odontopediatra é cuidar da saúde bucal do bebê, da criança e do adolescente.
            O odontopediatra não irá somente realizar possíveis tratamentos que venham a ser necessários,
            mas principalmente trabalhará na educação para uma boa saúde bucal.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/periodontista.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>
    </div>

    <div v-if="intBreakPoint <=2">
    <v-dialog v-model="clinico">
      <v-card class="align-center flex-column justify-center d-flex flexCard">
        <button @click="clinico = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1;
                ">
            Clínico Geral
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            Especialidade em que o dentista realiza o diagnóstico e tratamento
            relacionados à saúde bucal. Este profissional atua desenvolvendo
            trabalhos de prevenção como limpeza e orientação a saúde bucal,
            além de restaurações.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>

        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/odonto.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ortondo">
      <v-card class="align-center flex-column justify-center d-flex flexCard">
        <button @click="ortondo = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Ortodontista
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            É a especialidade da Odontologia relacionada ao estudo, prevenção
            e tratamento dos problemas de crescimento, desenvolvimento e
            amadurecimento da face, dos arcos dentários e da oclusão, ou seja,
            disfunções dento-faciais. O tratamento é feito com aparelhos
            ortodônticos e ortopédicos que permitem alinhar e nivelar os
            dentes e promover crescimento ósseo para corrigir deficiências
            dento-faciais.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/ortondo.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="implanto">
      <v-card class="align-center flex-column justify-center d-flex flexCard">
        <button @click="implanto = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="titleMobImpla" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Implantodontista
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            O implante dentário é um procedimento realizado em regiões
            edentulas (ausência de dente), consiste na fixação de um pino de
            titânio na região para servir de apoio na colocação de uma prótese
            fixa.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/implant.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="periodo">
      <v-card class="align-center flex-column justify-center d-flex flexCard">
        <button @click="periodo = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Periodontista
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            Periodontia é a especialidade odontológica que trata dos tecidos
            do periodonto, ou seja, aqueles que dão sustentação para os
            dentes. Seus procedimentos objetivam prevenir, diagnosticar e
            tratar problemas e doenças que atingem a gengiva, o ligamento
            periodontal e o osso alveolar. O especialista dessa área é o
            periodontista.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/periodontista.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="endo">
      <v-card class="align-center flex-column justify-center d-flex flexCard">
        <button @click="endo = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Endodontista
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            As áreas de competência do endodontista são: tratamentos conservadores da vitalidade pulpar;
            tratamento cirúrgico nos tecidos e na cavidade pulpar; procedimentos endodônticos cirúrgicos;
            tratamento de dentes permanentes jovens com rizogênese incompleta;
            tratamento dos traumatismos dentários e recuperação da cor dental.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/periodontista.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="odontoPedi">
      <v-card class="align-center flex-column justify-center d-flex flexCard">
        <button @click="odontoPedi = false" >
          <svg-icon type='mdi' :path="mdiClose" style="position: absolute; right: 25px; top: 20px"></svg-icon>
        </button>
        <v-col cols="12" md="8" class="">
          <h1 class="title1Mob" style="
                  color: #4490a3;
                  font-family: poppins, sans-serif;
                  font-size: 45px;
                  letter-spacing: 2px;
                  line-height: 1.2;
                ">
            Odontopediatra
          </h1>
          <p style="
                  margin-bottom: 0px !important;
                  font-family: poppins, sans-serif;
                  color: #000;
                  font-size: 18px;
                ">
            A principal função do odontopediatra é cuidar da saúde bucal do bebê, da criança e do adolescente.
            O odontopediatra não irá somente realizar possíveis tratamentos que venham a ser necessários,
            mas principalmente trabalhará na educação para uma boa saúde bucal.
          </p>
          <v-btn class="botaoMobile" @click="() => {
              this.$root.$emit('show-whats');
            }" style="
                  border: 2px solid #4490a3;
                  color: #4490a3;
                  background-color: transparent;
                  font-family: poppins, sans-serif;
                  font-weight: 400;
                  font-size: 20px;
                  margin-top: 30px;
                  box-shadow: none;
                  border-radius: 50px;
                ">Faça um agendamento!</v-btn>
        </v-col>
        <v-col cols="12" md="4" class="align-center justify-center d-flex">
          <img src="../assets/periodontista.webp" width="250" />
        </v-col>
      </v-card>
    </v-dialog>
    </div>

  </v-container>
</template>
  
<style scoped>
@media (min-device-width: 360px) and (max-device-width: 640px) {
  .padMob {
    padding: 10px 0px !important;
  }

  .mgImplante {
    margin-left: 60px !important;
  }

  .mgProtese {
    margin-left: 0px !important;
  }

  .mgP {
    margin: 0px 10px !important;
  }

  .wdCard {
    width: 350px !important;
  }

  .marginImg {
    margin: 5px 0px !important;
  }

  :deep(.v-image) {
    height: 0% !important;
  }

  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: 0.8;
    position: absolute;
    width: 100%;
  }

  .titleMobImpla {
    font-size: 22px !important;
  }

  .flexCard {
    flex-direction: column !important;
  }

  .pdlfBtn {
    padding-left: 90px !important;
  }

  .botaoMobile {
    font-size: 18px !important;
    margin-left: 0px !important;
    padding: 10px !important;
  }

  .title1Mob {
    font-size: 30px !important;
  }

  .padLft {
    padding-left: 0px !important
  }

  .bgc1 {
    height: 50vh !important;
    background-position: unset !important;
  }

  @media (max-width: 600px) {
  .consulta-cta-col i {
    padding-right: 2px;
  } 
  .header-cta-banner {
    width: 100% !important;
    align-self: center !important;
  }
}
@media (min-width: 600px) and (max-width: 960px) {
  .consulta-cta-col {
    padding: 21px 104px;
  }
  .header-cta-banner {
    width: 75% !important;
    align-self: center !important;
  }

  .marginImg {
    max-width: 150px !important;
  }
}

@media (max-width: 600px) {
  .header-cta-btn {
    flex-direction: column !important;
    margin: 0 5px;
  }
}
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}

.v-responsive__sizer {
  padding-bottom: 120.444%;
}

.bgc1 {
  background-image: url("../assets/dentist/wallpaperDentista.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: inherit;
  background-position: center;
  height: 80vh;
}

.fundao {
  background-image: url("../assets/fundaoBranco.webp");
  background-size: contain;
  background-repeat: repeat;
}

.header-cta-banner {
    margin-top: 1em !important;
  }
</style>
  
<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiCalendarMonthOutline, mdiWhatsapp, mdiPhone, mdiClose } from '@mdi/js';


export default {
  name: "odonto",
  metaInfo: {
    title: "Odonto",
    meta: [
      {
        name: "Odonto",
        content: "+12 procedimentos Odontológicos com preços acessíveis - Agende sua avaliação sem custo",
      },
      // outras meta tags
    ],
  },
  components: {
    SvgIcon
  },
  data: () => ({
    mdiCalendarMonthOutline, 
    mdiWhatsapp, 
    mdiPhone, 
    mdiClose,
    clinico: false,
    odontoPedi: false,
    endo: false,
    ortondo: false,
    implanto: false,
    periodo: false,
    loading: false,
    end: "",
    dialog: true,

    timeout: 2000,
    items: [
      "Clínico geral",
      "Ortodontista",
      "Implantodontista",
      "Periodontista",
    ],
    items2: ["São José dos Campos", "Sorocaba"],

    cards: [
      {
        titulo: "Clínico Geral",
        texto: "Adquira sua cota sem entrada e sem juros.",
        imagem: require("../assets/clinico.webp"),
        popup: false,
      },
      {
        titulo: "Ortodontista",
        texto: "Contemplações mensais a partir da Loteria Federal.",
        imagem: require("../assets/aparelho.webp"),
        popup: false,
      },
      {
        titulo: "Implantodontista",
        texto: "Oferte lances e pegue seu carro ainda mais rápido .",
        imagem: require("../assets/implanto.webp"),
        popup: false,
      },
      {
        titulo: "Endodontista",
        texto: "Seu carro da forma mais econômica e inteligente do mercado.",
        imagem: require("../assets/periodo.webp"),
        popup: false,
      },
      {
        titulo: "Odontopediatria",
        texto: "Seu carro da forma mais econômica e inteligente do mercado.",
        imagem: require("../assets/novasImg/odontopedi.webp"),
        popup: false,
      },
    ],
  }),

  computed: {
    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 3;
        case "xl":
          return 4;
        default:
          return 0;
      }
    },
  },

  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    abrir(nome) {
      var card = this.$refs[nome];
      var abrir = card.offsetTop;

      window.scrollTo(0, abrir);
    },
    abrirDialogWhats() {
      this.$root.$emit("show-whats")
    },
  },

  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
};
</script>
  